import { FocusEvent, ChangeEvent, KeyboardEvent, useCallback, useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosError } from "axios";

import { TceppsTestData, isTestingApp } from "../../utils/TestLoadData";

import { tceppsActions } from "../../redux-store/reducer/tcepps_slice";
import { tokenCreatedAction, microformInitializedAction, unmountAction, contextLoadedAction } from "../../redux-store/reducer/paymentSlice";
import { LogService, LOGERRORLEVEL } from "../../service/logService";
import PaymentService from "../../service/paymentService";
import { decryptParamType, getYearsFromToday, isCybersourceTimedout, getTimedoutDummyToken } from "../../utils/calendarUtil";

import classesSnr from "./MyCanonSnr.module.css";
import { extractClientLibrary, extractDomain, parseToken } from "../../utils/utils";
import { componentVarObject, ComponentVarProps } from "../../utils/ComponentConstant";

var Buffer = require("buffer/").Buffer; // note: the trailing slash is important!

let developmentEnv = false;
var creditCardMicroNumber: any;
var cvvMicroSecurityCode: any;

const margin20Style = {
  margin: "20px 0",
} as React.CSSProperties;
const creditCardStyle = {
  fontSize: "16px",
  fontFamily: "Proxima Nova",
  paddingLeft: "20px",
  marginRight: "20px",
  height: "22px",
  color: "fieldtext",
  letterSpacing: "normal",
  wordSpacing: "normal",
  lineHeight: "normal",
  textIndent: "0px",
  textShadow: "none",
  display: "inline-block",
  cursor: "text",
  // backgroundColor: "#f8f8f8",
  margin: "0em",
  borderWidth: "0px",
  width: "100%",
  borderStyle: "inset",
  // borderColor: "-internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133))",
  backgroundColor: "rgba(255, 255, 255, 0)",
  borderImage: "initial",
} as React.CSSProperties;
const flexStyles = {
  input: {
    cursor: "text",
    color: "#171719",
  },
  "::placeholder": { color: "lightslategrey" },
  ":focus": { color: "black" },
  ":disabled": { cursor: "not-allowed" },
  valid: { color: "#171719" },
  invalid: { color: "#a94442" },
  // ":hover": {
  //   "font-style": "italic",
  // },
} as React.CSSProperties;

const toDay = new Date();
const currentMonthYear = toDay.toLocaleString("en-US", { year: "numeric" }) + toDay.toLocaleString("en-US", { month: "2-digit" });
const currentYear = toDay.toLocaleString("en-US", { year: "numeric" });

const EMPTYCARDMESSAGE = "Card# field cannot be empty.";
const EMPTYDISLAIMERMESSAGE = "Please provide your consent for the disclaimer.";
const FORMINITIZING = "Browser initializing. Please wait and retry.";
const INVALIDAMEXCARDMESSAGE = "Please enter a valid Amex credit card #.";
const INVALIDCARDMESSAGE = "Please enter a valid credit card number.";
const INVALIDCVVMESSAGE = "Please enter a valid CVV Number.";
const INVALIDDATEMESSAGE = "Credit card has expired.";
const INVALIDDISCOVERCARDMESSAGE = "Please enter a valid Discover card #.";
const INVALIDMASTERCARDMESSAGE = "Please enter a valid Mastercard #.";
const INVALIDVISACARDMESSAGE = "Please enter a valid Visa credit card #.";

const DISCLAIMERFIRSTTEXT = "By clicking on the SUBMIT button, you are approving the estimate as shown.";
const DISCLAIMERSECTEXT =
  "You may see the $1 hold on your credit card statement. This is a temporary hold for Canon U.S.A to validate your credit card. Once the product is received we will confirm the repair and either charge the approved amount or send a re-estimate for approval.";

const INVALIDAXIOSMESSAGE = "AxiosError. ";

export function MyCanonSnrComponent(this: any) {
  const dispatch = useDispatch();
  const tceppsState = useSelector((state: any) => state.tcepps);
  const paymentInfo = useSelector((state: any) => state.paymentInfo);

  const [expirationMonth, setExpirationMonth] = useState("");
  const [expirationYear, setExpirationYear] = useState("");
  const [errmessage, setErrmessage] = useState("");
  const [amount, setAmount] = useState("");
  const [disclaimerCheck, setDisclaimerCheck] = useState(false);
  const [selectedCreditCard, setSelectedCreditCard] = useState("");

  const [creditCardNumberDisabled, setCreditCardNumberDisabled] = useState(false);
  const [securityCodeDisabled, setSecurityCodeDisabled] = useState(false);
  const [expirationMonthDisabled, setExpirationMonthDisabled] = useState(false);
  const [expirationYearDisabled, setExpirationYearDisabled] = useState(false);
  const [errmessageDisabled, setErrmessageDisabled] = useState(true);
  const [disclaimerCheckDisabled, setDisclaimerCheckDisabled] = useState(false);

  const creditCardNumberRef = useRef<HTMLDivElement>(null);
  const securityCodeRef = useRef<HTMLDivElement>(null);
  const expirationMonthRef = useRef<HTMLSelectElement>(null);
  const expirationYearRef = useRef<HTMLSelectElement>(null);
  const errmessageRef = useRef<HTMLTextAreaElement>(null);
  const amountRef = useRef<HTMLLabelElement>(null);
  const disclaimerCheckRef = useRef<HTMLInputElement>(null);
  const disclaimerFirstRef = useRef<string>(DISCLAIMERFIRSTTEXT);
  const disclaimerSecondRef = useRef<string>(DISCLAIMERSECTEXT);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const logErrorRef = useRef<string>("MYCANONSNR Not initialized");

  const componentObjRef = useRef<ComponentVarProps>(componentVarObject);
  const captureContextRef = useRef<string>("");
  const microformTokenRef = useRef<any>("");

  const [creditCardNumberBorderClass, setCreditCardNumberBorderClass] = useState("");
  const [creditCardNumberInvalidClass, setCreditCardNumberInvalidClass] = useState("");
  const [securityCodeBorderClass, setSecurityCodeBorderClass] = useState("");
  const [securityCodeInvalidClass, setSecurityCodeInvalidClass] = useState("");
  const [expirationMonthInvalidClass, setExpirationMonthInvalidClass] = useState("");
  const [disclaimerClass, setDisclaimerClass] = useState(`${classesSnr.DisclaimFooterUnChecked}`);

  const [submitButtonEnable, setSubmitButtonEnable] = useState(true);
  const [submitVisible, setSubmitVisible] = useState(false);
  const [submitBackground, setSubmitBackground] = useState("");

  const [creditcardValid, setCreditcardValid] = useState(false);
  const [cvvValid, setCvvValid] = useState(false);

  const [decryptParams, setDecryptParams] = useState([] as decryptParamType);
  const [proxyUrl, setProxyUrl] = useState("");
  const [captureContextCompleted, setCaptureContextCompleted] = useState(false);

  const { appKey } = useParams();

  function setCreditCardType(newValue: string) {
    switch (newValue) {
      case "VI":
      case "visa":
        componentObjRef.current.glblCreditCardType = "VI";
        break;
      case "MC":
      case "mastercard":
        componentObjRef.current.glblCreditCardType = "MC";
        break;
      case "AX":
      case "amex":
        componentObjRef.current.glblCreditCardType = "AX";
        break;
      case "DI":
      case "discover":
        componentObjRef.current.glblCreditCardType = "DI";
        break;
      default:
        componentObjRef.current.glblCreditCardType = "";
        break;
    }
  }

  const checkCreditCard = useCallback(() => {
    let errorMessage = "";

    if (decryptParams && Object.keys(decryptParams).length > 0 && submitButtonEnable) {
      let setFocusSet = false;
      let cardNumberError = false;
      let userMonth = expirationYearRef.current?.value + "" + expirationMonthRef.current?.value;

      if (componentObjRef.current.cardMessage !== "" || !creditcardValid) {
        errorMessage = INVALIDCARDMESSAGE;
        switch (componentObjRef.current.glblCreditCardType) {
          case "VI":
            errorMessage = INVALIDVISACARDMESSAGE;
            break;
          case "MC":
            errorMessage = INVALIDMASTERCARDMESSAGE;
            break;
          case "AX":
            errorMessage = INVALIDAMEXCARDMESSAGE;
            break;
          case "DI":
            errorMessage = INVALIDDISCOVERCARDMESSAGE;
            break;
        }
        if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
          creditCardMicroNumber.focus();
          cardNumberError = true;
          setFocusSet = true;
        }
      }
      if (componentObjRef.current.cvvMessage !== "" || !cvvValid) {
        errorMessage += (errorMessage.length > 0 ? " " : "") + INVALIDCVVMESSAGE;
        setSecurityCodeInvalidClass(`${classesSnr.Search__SearchInput_focus}`);
        if (!setFocusSet) {
          if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
            cvvMicroSecurityCode.focus();
          }
        }
        setFocusSet = true;
      } else {
        setSecurityCodeInvalidClass("");
      }
      if (userMonth < currentMonthYear) {
        errorMessage += (errorMessage.length > 0 ? " " : "") + INVALIDDATEMESSAGE;
        setExpirationMonthInvalidClass(`${classesSnr.Red}`);
        if (!setFocusSet) expirationMonthRef.current?.focus();
        setFocusSet = true;
      } else {
        setExpirationMonthInvalidClass("");
      }
      if (componentObjRef.current.otherErrMsg !== "") {
        errorMessage += componentObjRef.current.otherErrMsg;
        cardNumberError = true;
        if (!setFocusSet) {
          if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
            creditCardMicroNumber.focus();
          }
        }
        setFocusSet = true;
      }

      if (cardNumberError) {
        setCreditCardNumberInvalidClass(`${classesSnr.Search__SearchInput_focus}`);
      } else {
        setCreditCardNumberInvalidClass("");
      }

      if (!disclaimerCheckRef.current?.checked) {
        errorMessage += (errorMessage.length > 0 ? " " : "") + EMPTYDISLAIMERMESSAGE;
        if (!setFocusSet) disclaimerCheckRef.current?.focus();
        setFocusSet = true;
        // disclaimerCheckRef.current?.parentElement?.classList.add(`${classesSnr.checkBoxFocused}`);
        ((document.querySelector("#disclaimerCheck") as HTMLInputElement).parentElement as HTMLInputElement).style.backgroundColor =
          "#FFD6D7";
      } else {
        // disclaimerCheckRef.current.parentElement?.classList.remove(`${classesSnr.checkBoxFocused}`);
        ((document.querySelector("#disclaimerCheck") as HTMLInputElement).parentElement as HTMLInputElement).style.backgroundColor =
          "white";
      }
    } else {
      errorMessage = FORMINITIZING;
    }

    if (errorMessage === "") {
      setErrmessage("");
      // isBackgroundRed = false;
      return true;
    } else if (componentObjRef.current.submitCount > 0) {
      setErrmessage(errorMessage);
      // isBackgroundRed = true;
      return false;
    }
  }, [decryptParams, creditcardValid, cvvValid, submitButtonEnable]);

  const toggleFields = useCallback((addProfile: string) => {
    const disabled = addProfile === "true" ? false : true;

    setCreditCardNumberDisabled(disabled);
    setExpirationMonthDisabled(disabled);
    setExpirationYearDisabled(disabled);
    setSecurityCodeDisabled(disabled);
    setDisclaimerCheckDisabled(disabled);
  }, []);

  const getLastFourCcAccountNum = (micoFormtoken: string) => {
    const tokenArray = micoFormtoken.split(".");
    let bufferObj = Buffer.from(tokenArray[1], "base64");

    //Encode the Buffer as a utf8 string
    let decodedString = bufferObj.toString("utf8");
    let jsonObject = JSON.parse(decodedString);
    let jti = jsonObject.jti;
    let lastFourDigits = jsonObject.content.paymentInformation.card.number.maskedValue.replaceAll("X", "");
    return [jti, lastFourDigits];
  };

  const invokeNewOrder = useCallback(
    async (micoFormtoken: string) => {
      if (componentObjRef.current.processingForm) {
        let expYearMonth = "";
        if (
          expirationMonthRef.current?.value &&
          expirationMonthRef.current?.value.length > 0 &&
          expirationYearRef.current?.value &&
          expirationYearRef.current?.value.length > 0
        ) {
          expYearMonth = expirationYearRef.current?.value + expirationMonthRef.current?.value;
        }

        const [jti, lastFourDigits] = getLastFourCcAccountNum(micoFormtoken);

        let proxyUrl = decryptParams["tcepps.proxyUrl"];
        let firstName = decryptParams["firstName"];
        let middleName = decryptParams["middleName"];
        let lastName = decryptParams["lastName"];
        let customerName =
          (firstName ? firstName.trim() : " ") + " " + (middleName ? middleName.trim() : " ") + " " + (lastName ? lastName.trim() : " ");
        customerName = customerName.replaceAll("  ", " ");
        customerName = customerName.replaceAll("  ", " ");
        let address1 = decryptParams["address1"];
        let address2 = decryptParams["address2"];
        let city = decryptParams["city"];
        let state = decryptParams["state"];
        let zip = decryptParams["zip"];

        // let amount = decryptParams["amount;
        let amount = "100";
        let email = decryptParams["email"];
        if (!(email && email.length > 0)) {
          // email = DEFUSEREMAIL;
          email = decryptParams["customerEmail"];
        }
        if (email && email.length > 0) {
          email = email.trim();
        }
        let countryCd = decryptParams["country"];
        if (!(countryCd && countryCd.length > 0)) {
          countryCd = "US";
        }
        if (!address1) {
          address1 = address2;
          address2 = "";
        }

        let orbitalConnectionUsername = decryptParams["pno.orbitalConnectionUsername"];
        let orbitalConnectionPassword = decryptParams["pno.orbitalConnectionPassword"];
        let switchSoloIssueNum = decryptParams["pno.switchSoloIssueNum"];
        let switchSoloCardStartDate = decryptParams["pno.switchSoloCardStartDate"];
        let authenticationECIInd = decryptParams["pno.authenticationECIInd"];
        if (!orbitalConnectionUsername) {
          orbitalConnectionUsername = decryptParams["orbitalConnectionUsername"];
        }
        if (!orbitalConnectionPassword) {
          orbitalConnectionPassword = decryptParams["orbitalConnectionPassword"];
        }
        if (!switchSoloIssueNum) {
          switchSoloIssueNum = decryptParams["switchSoloIssueNum"];
        }
        if (!switchSoloCardStartDate) {
          switchSoloCardStartDate = decryptParams["switchSoloCardStartDate"];
        }
        if (!authenticationECIInd) {
          authenticationECIInd = decryptParams["authenticationECIInd"];
        }

        if (developmentEnv) {
          let address = address1;
          if (address2 && address2.length > 0) {
            address = address + " " + address2;
          }
          if (address) {
            if (address.toLowerCase().indexOf("testdonothonorfailure") >= 0) {
              amount = "40100";
            }
            if (address.toLowerCase().indexOf("testaddressfailure") >= 0) {
              amount = "283600";
            }
            if (address.toLowerCase().indexOf("testcvvfailure") >= 0) {
              amount = "700500";
              // cvv = "111";
            }
            if (address.toLowerCase().indexOf("testapifailure") >= 0) {
              email = "abcd@";
            }
            if (address.toLowerCase().indexOf("testinsufficientfund") >= 0) {
              amount = "250900"; //2509 2521
            }
            if (address.toLowerCase().indexOf("testinsufficientfund2") >= 0) {
              amount = "252100"; //2509 2521
            }
          }
        }

        const mycanonsnrObj = {
          "pno.ccAccountNum": "TT=" + jti,
          "pno.ccExp": expYearMonth,
          "pno.ccCardVerifyNum": "",
          "pno.avsName": customerName ? customerName.toUpperCase().trim() : "",
          "pno.avsAddress1": address1 ? address1.toUpperCase().trim() : "",
          "pno.avsAddress2": address2 ? address2.toUpperCase().trim() : "",
          "pno.avsCity": city ? city.toUpperCase().trim() : "",
          "pno.avsState": state ? state.toUpperCase().trim() : "",
          "pno.avsZip": zip ? zip.toUpperCase().trim() : "",
          "pno.amount": amount,
          "pno.orderID": decryptParams["orderNumber"],
          "tcepps.cardBrand": componentObjRef.current.glblCreditCardType,
          "tcepps.lastFourDigits": lastFourDigits,
          "pno.avsCountryCode": countryCd ? countryCd.toUpperCase().trim() : countryCd,
          "pno.customerEmail": email,
          "pno.orbitalConnectionUsername": orbitalConnectionUsername ? orbitalConnectionUsername : "",
          "pno.orbitalConnectionPassword": orbitalConnectionPassword ? orbitalConnectionPassword : "",
          "pno.switchSoloIssueNum": switchSoloIssueNum ? switchSoloIssueNum : "",
          "pno.switchSoloCardStartDate": switchSoloCardStartDate ? switchSoloCardStartDate : "",
          "pno.authenticationECIInd": authenticationECIInd ? authenticationECIInd : "",
          "pno.captureContext": captureContextRef.current,
          "pno.microformToken": microformTokenRef.current,
          "tcepps.proxyUrl": proxyUrl,
          "tcepps.serviceOperation": "newOrder",
          "tcepps.app": tceppsState.app.toUpperCase().trim(),
          "tcepps.token": tceppsState.token,
          "tcepps.sign": tceppsState.sign,
        };
        toggleFields("false");
        PaymentService.submitMycanonsnr(mycanonsnrObj)
          .then((resp) => {
            window.open(resp.data, "_self");
            // console.log(mycanonsnrObj);
          })
          .catch((err: Error | AxiosError) => {
            // console.log(err);
            if (axios.isAxiosError(err)) {
              // Access to config, request, and response
              setErrmessage(INVALIDAXIOSMESSAGE + "call to submitMycanonSnr failed. " + err.message + ". Please check console..");
            } else {
              // Just a stock error
              setErrmessage("Error in submitMycanonSnr. " + err.message + ". Please check console..");
            }
            // isBackgroundRed = true;
            componentObjRef.current.processingForm = false;
            setSubmitBackground("");
            LogService.logMessage(
              logErrorRef.current,
              LOGERRORLEVEL,
              "Call to submit form submitMycanonsnr failed:" + (err ? JSON.stringify(err.message) : "null")
            );
          });
      }
    },
    // [toggleFields, tceppsState.app, tceppsState.encryptedRequestKey, tceppsState.encryptedRequest, tceppsState.token, tceppsState.sign]
    [toggleFields, tceppsState.app, tceppsState.token, tceppsState.sign, decryptParams]
  );

  const createCybersourceToken = useCallback(async () => {
    if (isCybersourceTimedout(toDay)) {
      (document.getElementById("errmessage") as HTMLInputElement).value = "Processing....";
      setErrmessage("Processing....");
      microformTokenRef.current = getTimedoutDummyToken();
      const { token, jti, lastFourDigits } = getTimedoutDummyToken();
      dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
    } else {
      componentObjRef.current.cardMessage = "";
      componentObjRef.current.cvvMessage = "";

      const options = {
        expirationMonth: expirationMonthRef.current?.value,
        expirationYear: expirationYearRef.current?.value,
      };

      // async function createMicroformToken(options: any) {
      await paymentInfo.microform.createToken(options, (err: Error, token: any) => {
        if (err) {
          // console.log(err);
          componentObjRef.current.otherErrMsg = "";
          componentObjRef.current.cardMessage = "";
          componentObjRef.current.cvvMessage = "";
          let foundCardMessage = true;
          // handle error
          // @ts-ignore
          switch (err.reason) {
            case "CREATE_TOKEN_NO_FIELDS_LOADED":
              componentObjRef.current.otherErrMsg = "Token creation error, no fields have been loaded." + err.message;
              break;
            case "CREATE_TOKEN_TIMEOUT":
              componentObjRef.current.otherErrMsg = "createToken call was unable to proceed." + err.message;
              break;
            case "CREATE_TOKEN_XHR_ERROR":
              componentObjRef.current.otherErrMsg = "Network error when attempting to create a token." + err.message;
              break;
            case "CREATE_TOKEN_NO_FIELDS":
              componentObjRef.current.otherErrMsg = "Data fields are unavailable for collection." + err.message;
              break;
            case "CREATE_TOKEN_VALIDATION_PARAMS":
              componentObjRef.current.otherErrMsg = "Issue with parameters supplied to createToken." + err.message;
              break;
            case "CREATE_TOKEN_VALIDATION_FIELDS":
              // @ts-ignore
              if (err.details && err.details.length > 0) {
                // @ts-ignore
                if (err.details.length > 1) {
                  componentObjRef.current.cardMessage = INVALIDCARDMESSAGE;
                  componentObjRef.current.cvvMessage = INVALIDCVVMESSAGE;
                  // @ts-ignore
                } else if (err.details[0].location === "number") {
                  componentObjRef.current.cardMessage = INVALIDCARDMESSAGE;
                  // @ts-ignore
                } else if (err.details[0].location === "securityCode") {
                  componentObjRef.current.cvvMessage = INVALIDCVVMESSAGE;
                  foundCardMessage = false;
                } else {
                  componentObjRef.current.otherErrMsg = err.message;
                }
              } else {
                componentObjRef.current.otherErrMsg = err.message;
              }
              break;
            case "CREATE_TOKEN_VALIDATION_SERVERSIDE":
              componentObjRef.current.otherErrMsg = "Server-side validation rejects the createToken request." + err.message;
              break;
            case "CREATE_TOKEN_UNABLE_TO_START":
              componentObjRef.current.otherErrMsg = "Loaded field was able to handle the createToken request." + err.message;
              break;
            default:
              componentObjRef.current.otherErrMsg = "Unknown error:" + err.message;
              break;
          }
          if (!foundCardMessage) {
            componentObjRef.current.cardMessage = "";
          }
          checkCreditCard();
          componentObjRef.current.processingForm = false;
        } else {
          (document.getElementById("errmessage") as HTMLInputElement).value = "Processing....";
          setErrmessage("Processing....");
          microformTokenRef.current = token;
          const { jti, lastFourDigits } = parseToken(token);
          dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
        }
      });
    }
  }, [paymentInfo.microform, dispatch, checkCreditCard]);

  // useEffect when new token is generated submit form
  useEffect(() => {
    if (componentObjRef.current.processingForm && paymentInfo.token) {
      if (checkCreditCard()) {
        setErrmessage("Processing....");
        (document.getElementById("errmessage") as HTMLInputElement).value = "Processing....";
        setSubmitBackground(`${classesSnr.Button__text} ${classesSnr.Button__loading__Payment}`);
        invokeNewOrder(paymentInfo.token);
      }
    }
  }, [paymentInfo.token, checkCreditCard, invokeNewOrder]);

  //useEffect when input parameters are decrypted.
  useEffect(() => {
    if (decryptParams && Object.keys(decryptParams).length > 0 && submitButtonEnable) {
      setSubmitVisible(true);
    }
  }, [decryptParams, submitButtonEnable]);

  // When Submit button is clicked
  const clickHandler = (event: any) => {
    event.preventDefault();
    componentObjRef.current.submitCount = componentObjRef.current.submitCount + 1;
    if (submitVisible && !componentObjRef.current.processingForm && checkCreditCard()) {
      // submitCount = submitCount + 1;
      componentObjRef.current.processingForm = true;
      createCybersourceToken();
    }
  };

  const windowMessageHandler = useCallback((event: MessageEvent) => {
    // console.log(event.data);
    // console.log(event.source);
    if (
      event.data &&
      (event.data as any).data &&
      ((event.data as any).data as any).focusShift &&
      (event.data as any).event === "blur" &&
      event.currentTarget &&
      (event.currentTarget as any).name &&
      (event.currentTarget as any).name === "creditCardFormIFrame"
    ) {
      if (((event.data as any).data as any).focusShift === "next") {
        if (componentObjRef.current.microFromFocusField === "creditCardNumber") {
          componentObjRef.current.microFromFocusField = "";
          if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
            cvvMicroSecurityCode.focus();
          }
        } else {
          componentObjRef.current.microFromFocusField = "";
          expirationMonthRef.current?.focus();
        }
      } else if (((event.data as any).data as any).focusShift === "previous") {
        if (componentObjRef.current.microFromFocusField === "securityCode") {
          componentObjRef.current.microFromFocusField = "";
          if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
            creditCardMicroNumber.focus({ focusVisible: true });
          }
        } else {
          componentObjRef.current.microFromFocusField = "";
          disclaimerCheckRef.current?.focus();
        }
      }
    } else if (componentObjRef.current && event.data && (event.data as string).indexOf("/*cybs-telgram*/") >= 0) {
      const jsonObject = JSON.parse(event.data.replace("/*cybs-telgram*/", ""));
      if (
        jsonObject.data &&
        jsonObject.data.focusShift &&
        jsonObject.event &&
        jsonObject.event === "event_blur" &&
        event.currentTarget &&
        (event.currentTarget as any).name &&
        ((event.currentTarget as any).name === "Mainframe" || (event.currentTarget as any).name === "creditCardFormIFrame")
      ) {
        if (jsonObject.data.focusShift === "next") {
          if (componentObjRef.current.microFromFocusField === "creditCardNumber") {
            componentObjRef.current.microFromFocusField = "";
            if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
              cvvMicroSecurityCode.focus();
            }
          } else {
            componentObjRef.current.microFromFocusField = "";
            expirationMonthRef.current?.focus();
          }
        } else if (jsonObject.data.focusShift === "previous") {
          if (componentObjRef.current.microFromFocusField === "securityCode") {
            componentObjRef.current.microFromFocusField = "";
            if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
              creditCardMicroNumber.focus();
            }
          } else {
            componentObjRef.current.microFromFocusField = "";
            disclaimerCheckRef.current?.focus();
          }
        }
      }
    }
  }, []);

  // decrypt the input parameters
  const decryptInputParameterAsync = async (app: string, encryptedRequestKey: string, encryptedRequest: string) => {
    let decryptParamsResponse: any;

    try {
      const response = await PaymentService.getDecryptRequest(app, encryptedRequestKey, encryptedRequest);
      decryptParamsResponse = response.data;
    } catch (err: any) {
      // console.log(err);
      if (axios.isAxiosError(err)) {
        // Access to config, request, and response
        setErrmessage(INVALIDAXIOSMESSAGE + "call to getDecryptRequest failed. " + err.message + ". Please check console..");
      } else {
        // Just a stock error
        setErrmessage("Error in getDecryptRequest. " + err.message + ". Please check console..");
      }
      // isBackgroundRed = true;
      LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "PaymentService.getDecryptRequest call failed. " + err.message);
    }

    if (decryptParamsResponse && Object.keys(decryptParamsResponse).length > 0) {
      // check for proxy
      let iFrameUrl = document.referrer;
      if (iFrameUrl === undefined || iFrameUrl.trim().length === 0) {
        setProxyUrl(decryptParamsResponse["tcepps.proxyUrl"]);
      }

      setDecryptParams(decryptParamsResponse);
      let amt = decryptParamsResponse.amount;
      try {
        let decAmt = (Number.parseFloat(amt) / 100).toFixed(2);
        amt = "$" + decAmt;
        setAmount(amt);
      } catch (exception) {
        setAmount(amt);
      }

      // check if its called from testing app
      if (isTestingApp(decryptParamsResponse["app.TESTINGAPP"])) {
        developmentEnv = true;
        setExpirationMonth(TceppsTestData.ToBeAppTestData.MyCanonSnr["ppa.test.expirationMonth"]);
        setExpirationYear(TceppsTestData.ToBeAppTestData.MyCanonSnr["ppa.test.expirationYear"]);
      }

      logErrorRef.current = "MYCANONSNR Order# " + decryptParamsResponse["orderNumber"];
      return true;
    } else {
      setErrmessage("Customer details not available. Please close and retry.");
      LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "Customer details not available. Please close and retry.");
      return false;
    }
  };

  // useEffect for windows message
  useEffect(() => {
    // console.log("useEffect addEventListener...3");
    if (paymentInfo.isSuccessful) {
      window.addEventListener("message", windowMessageHandler, false);
    }

    return () => {
      window.removeEventListener("message", windowMessageHandler);
    };
  }, [windowMessageHandler, paymentInfo.isSuccessful]);

  // useEffect if screen/page input parameters change. Dispatch state change
  useEffect(() => {
    // console.log("useEffect initialload...4");

    setSubmitBackground("");

    const appKey0 = Buffer.from(appKey ? appKey : "", "base64");
    const appKey1 = appKey0.toString("utf8");
    const mydata = JSON.parse(appKey1);

    let iFrameUrl = document.referrer;
    if (iFrameUrl && iFrameUrl.trim().length > 0) {
      setProxyUrl(iFrameUrl);
    }

    let requestParameter = {
      app: "",
      token: "",
      sign: "",
      serviceOperation: "",
      encryptedRequest: "",
      encryptedRequestKey: "",
      enableNewCrCardFlag: "",
      addProfile: "",
      cpgmuiApiUrl: "",
      cpgmuiEnv: "",
    };

    const getPortNumber = (inputUrl: string) => {
      const windowsUrl = window.location.href;
      const searchParams = windowsUrl.indexOf("//") + 2;
      const firstSlashIndex = windowsUrl.indexOf("/", searchParams);
      const colonIndex = windowsUrl.indexOf(":", searchParams);
      if (firstSlashIndex > 0 && colonIndex > 0) {
        return windowsUrl.substring(colonIndex + 1, firstSlashIndex);
      } else {
        return "";
      }
    };

    //find port if it exits
    const urlPortNumber = getPortNumber(window.location.href);

    Object.keys(mydata).forEach((key) => {
      switch (key) {
        case "tcepps.app":
          requestParameter.app = mydata[key];
          break;
        case "tcepps.token":
          requestParameter.token = mydata[key];
          break;
        case "tcepps.sign":
          requestParameter.sign = mydata[key];
          break;
        case "tcepps.serviceOperation":
          requestParameter.serviceOperation = mydata[key];
          break;
        case "tcepps.encryptedRequest":
          requestParameter.encryptedRequest = mydata[key];
          break;
        case "tcepps.encryptedRequestKey":
          requestParameter.encryptedRequestKey = mydata[key];
          break;
        case "tcepps.enableNewCrCardFlag":
          requestParameter.enableNewCrCardFlag = mydata[key];
          break;
        case "tcepps.addProfile":
          requestParameter.addProfile = mydata[key];
          break;
        case "cpgmuiApiUrl":
          requestParameter.cpgmuiApiUrl = mydata[key] + "/TCEPPS/jsp/tceppsHtmlFormInterface.jsp?tceppsApp=";
          if (urlPortNumber !== "") {
            let backendPortNumber = getPortNumber(requestParameter.cpgmuiApiUrl);
            if (backendPortNumber !== urlPortNumber) {
              requestParameter.cpgmuiApiUrl = requestParameter.cpgmuiApiUrl.replace(backendPortNumber, urlPortNumber);
            }
          }
          break;
        case "cpgmuiEnv":
          requestParameter.cpgmuiEnv = mydata[key];
          if (requestParameter.cpgmuiEnv.toLowerCase() === "development" || requestParameter.cpgmuiEnv.toLowerCase() === "qa") {
            developmentEnv = true;
          }
          break;
      }
    });
    requestParameter.cpgmuiApiUrl += requestParameter.app;

    dispatch(
      tceppsActions.initializeTceppsAction({
        app: requestParameter.app,
        token: requestParameter.token,
        sign: requestParameter.sign,
        serviceOperation: requestParameter.serviceOperation,
        encryptedRequest: requestParameter.encryptedRequest,
        encryptedRequestKey: requestParameter.encryptedRequestKey,
        enableNewCrCardFlag: requestParameter.enableNewCrCardFlag,
        cpgmuiApiUrl: requestParameter.cpgmuiApiUrl,
        cpgmuiEnv: requestParameter.cpgmuiEnv,
      })
    );

    try {
      getYearsFromToday(11, document.getElementById("expirationYear") as HTMLSelectElement, false);
    } catch (error) {}

    setExpirationMonth("01");
    setExpirationYear(currentYear);
    setCreditCardType("");
    setSelectedCreditCard("");

    decryptInputParameterAsync(
      requestParameter.app.toUpperCase().trim(),
      requestParameter.encryptedRequestKey,
      requestParameter.encryptedRequest
    );
    setErrmessageDisabled(true);
    setDisclaimerClass(`${classesSnr.DisclaimFooterUnChecked}`);
  }, [appKey, dispatch]);

  // useEffect when form get loaded. setup microform
  useEffect(() => {
    // console.log("useEffect microform...5");

    function selectGivenCardType(cardName: string, cardValid: boolean, cardEmpty: boolean) {
      let varCreditCardType = "";
      let detectedCard = false;
      if (cardName.length > 0) {
        switch (cardName) {
          case "VI":
          case "visa":
            varCreditCardType = "visa";
            detectedCard = true;
            break;
          case "MC":
          case "mastercard":
            varCreditCardType = "mastercard";
            detectedCard = true;
            break;
          case "AX":
          case "amex":
            varCreditCardType = "amex";
            detectedCard = true;
            break;
          case "DI":
          case "discover":
            varCreditCardType = "discover";
            detectedCard = true;
            break;
        }
      }

      if (detectedCard) {
        setCreditCardType(varCreditCardType);
        setSelectedCreditCard(varCreditCardType);
      } else {
        setCreditCardType("");
        setSelectedCreditCard("");
      }

      let errMessage = EMPTYCARDMESSAGE;
      if (!detectedCard) {
        errMessage = INVALIDCARDMESSAGE;
      } else if (cardEmpty) {
        errMessage = EMPTYCARDMESSAGE;
      } else if (!cardValid) {
        errMessage = INVALIDCARDMESSAGE;
        switch (componentObjRef.current.glblCreditCardType) {
          case "VI":
            errMessage = INVALIDVISACARDMESSAGE;
            break;
          case "MC":
            errMessage = INVALIDMASTERCARDMESSAGE;
            break;
          case "AX":
            errMessage = INVALIDAMEXCARDMESSAGE;
            break;
          case "DI":
            errMessage = INVALIDDISCOVERCARDMESSAGE;
            break;
        }
      } else if (cardValid) {
        errMessage = "";
      }

      if (!cardValid) {
        setCreditCardNumberInvalidClass(`${classesSnr.Search__SearchInput_focus}`);
      } else {
        setCreditCardNumberInvalidClass("");
      }
      setCreditcardValid(cardValid && detectedCard);
      componentObjRef.current.cardMessage = errMessage;
      if (componentObjRef.current.submitCount > 0) {
        setErrmessage(componentObjRef.current.cardMessage);
      }
    }

    const loadCybersourceJS = (captureContext: any, clientLibrary: string, clientLibraryIntegrity: string, callback: (cc: any) => any) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      // script.src = config.cybersource.microformJs;
      script.src = clientLibrary;
      script.integrity = clientLibraryIntegrity;
      script.crossOrigin = "anonymous";
      document.body.appendChild(script);
      script.onload = () => {
        callback(captureContext);
      };
    };

    const initializeMicroform = async (captureContext: any) => {
      captureContextRef.current = captureContext;
      // @ts-ignore
      const flex = await new window.Flex(captureContext);
      const microform = await flex.microform({ styles: flexStyles });
      creditCardMicroNumber = await microform.createField("number", { placeholder: "Card Number *" });
      cvvMicroSecurityCode = await microform.createField("securityCode", { placeholder: "CVV *" });
      await creditCardMicroNumber.load("#creditCardNumber");
      await cvvMicroSecurityCode.load("#securityCode");
      dispatch(microformInitializedAction(microform));
      setCaptureContextCompleted(true);

      if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
        //add listener
        creditCardMicroNumber.on("change", function (data: any) {
          if (data.card && data.card.length > 0) {
            selectGivenCardType(data.card[0].name, data.valid, data.empty);
          } else {
            selectGivenCardType("", data.valid, data.empty);
          }
        });
        creditCardMicroNumber.on("load", function (data: any) {
          creditCardMicroNumber.focus();
          setSubmitButtonEnable(true);
        });
        creditCardMicroNumber.on("focus", function (data: any) {
          componentObjRef.current.microFromFocusField = "creditCardNumber";
          setCreditCardNumberBorderClass(`${classesSnr.Search__SearchInput_focus}`);
        });
        creditCardMicroNumber.on("blur", function (data: any) {
          setCreditCardNumberBorderClass("");
          if (creditcardValid && cvvValid && !disclaimerCheckRef.current?.checked) {
            ((document.querySelector("#disclaimerCheck") as HTMLInputElement).parentElement as HTMLInputElement).style.backgroundColor =
              "#FFD6D7";
            setErrmessage(EMPTYDISLAIMERMESSAGE);
            disclaimerCheckRef.current?.focus();
          }
        });
      }
      if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
        cvvMicroSecurityCode.on("focus", function (data: any) {
          componentObjRef.current.microFromFocusField = "securityCode";
          setSecurityCodeBorderClass(`${classesSnr.Search__SearchInput_focus}`);
        });
        cvvMicroSecurityCode.on("blur", function (data: any) {
          setSecurityCodeBorderClass("");
          if (creditcardValid && cvvValid && !disclaimerCheckRef.current?.checked) {
            ((document.querySelector("#disclaimerCheck") as HTMLInputElement).parentElement as HTMLInputElement).style.backgroundColor =
              "#FFD6D7";
            setErrmessage(EMPTYDISLAIMERMESSAGE);
            disclaimerCheckRef.current?.focus();
          }
        });
        cvvMicroSecurityCode.on("change", function (data: any) {
          if (data.empty || !data.valid) {
            setCvvValid(false);
            if (componentObjRef.current.submitCount > 0) {
              setErrmessage(INVALIDCVVMESSAGE);
              setSecurityCodeInvalidClass(`${classesSnr.Search__SearchInput_focus}`);
            }
          } else if (data.valid) {
            setCvvValid(true);
            if (componentObjRef.current.submitCount > 0) {
              setErrmessage("");
              setSecurityCodeInvalidClass("");
            }
          }
          if (!disclaimerCheckRef.current?.checked) {
            setDisclaimerClass(`${classesSnr.DisclaimFooterUnChecked}`);
            ((document.querySelector("#disclaimerCheck") as HTMLInputElement).parentElement as HTMLInputElement).style.backgroundColor =
              "#FFD6D7";
            // }
          } else {
            setDisclaimerClass(`${classesSnr.DisclaimFooterChecked}`);
            ((document.querySelector("#disclaimerCheck") as HTMLInputElement).parentElement as HTMLInputElement).style.backgroundColor =
              "white";
          }
        });
      }
    };

    if (proxyUrl && proxyUrl.trim().length > 0 && !captureContextCompleted) {
      // declare the data fetching function
      PaymentService.getCaptureContext(extractDomain(proxyUrl))
        .then((resp) => {
          // const captureContext = resp.data.data;
          const captureContext = resp.data.captureContext;
          const { clientLibrary, clientLibraryIntegrity } = extractClientLibrary(resp.data.json);
          dispatch(contextLoadedAction(captureContext));
          // @ts-ignore
          if (!window.Flex) {
            loadCybersourceJS(captureContext, clientLibrary, clientLibraryIntegrity, initializeMicroform);
          } else {
            initializeMicroform(captureContext);
          }
          return () => dispatch(unmountAction);
        })
        .catch((err: Error | AxiosError) => {
          // console.log(err);
          if (axios.isAxiosError(err)) {
            setErrmessage(INVALIDAXIOSMESSAGE + "call to initialize microForm failed. " + err.message + ". Please check console..");
          } else {
            setErrmessage("Initialize microForm failed. " + err.message + ". Please check console..");
          }
          // isBackgroundRed = true;
          LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "Initialize microForm failed. " + err.message);
        });
    }
  }, [dispatch, proxyUrl, captureContextCompleted, creditcardValid, cvvValid]);

  const resizeTextArea = () => {
    errmessageRef.current!.style.height = "auto";
    errmessageRef.current!.style.height = errmessageRef.current!.scrollHeight + "px";
  };

  useEffect(resizeTextArea, [errmessageRef, errmessage]);

  const onChangeHandler = (identifier: string, event: ChangeEvent & { target: HTMLInputElement | HTMLSelectElement }) => {
    let checkSelectedDate = false;
    let fieldChangeMessage = "";
    switch (identifier) {
      case "expirationMonth":
        setExpirationMonth(event.target.value);
        checkSelectedDate = true;
        break;
      case "expirationYear":
        setExpirationYear(event.target.value);
        checkSelectedDate = true;
        break;
      case "disclaimerCheck":
        setDisclaimerCheck(disclaimerCheckRef.current?.checked as any);
        if (!disclaimerCheckRef.current?.checked) {
          fieldChangeMessage = EMPTYDISLAIMERMESSAGE;
          setDisclaimerClass(`${classesSnr.DisclaimFooterUnChecked}`);
          // if (submitCount > 0) {
          // event.target.parentElement?.classList.add(`${classesSnr.checkBoxFocused}`);
          ((document.querySelector("#disclaimerCheck") as HTMLInputElement).parentElement as HTMLInputElement).style.backgroundColor =
            "#FFD6D7";
          // }
        } else {
          setDisclaimerClass(`${classesSnr.DisclaimFooterChecked}`);
          // event.target.parentElement?.classList.remove(`${classesSnr.checkBoxFocused}`);
          ((document.querySelector("#disclaimerCheck") as HTMLInputElement).parentElement as HTMLInputElement).style.backgroundColor =
            "white";
        }
        break;
    }
    if (checkSelectedDate) {
      let userMonth = expirationYearRef.current?.value + "" + expirationMonthRef.current?.value;
      if (componentObjRef.current.submitCount > 0 && userMonth < currentMonthYear) {
        setErrmessage(INVALIDDATEMESSAGE);
        setExpirationMonthInvalidClass(`${classesSnr.Red}`);
      } else if (componentObjRef.current.submitCount > 0) {
        setErrmessage("");
        setExpirationMonthInvalidClass("");
      }
    } else {
      if (fieldChangeMessage.length > 0) {
        setErrmessage(fieldChangeMessage);
      } else {
        setErrmessage("");
      }
    }
  };

  const onBlurHandler = (identifier: string, event: FocusEvent & { target: HTMLInputElement | HTMLSelectElement }) => {
    switch (identifier) {
      case "expirationMonth":
        setExpirationMonth(event.target.value);
        break;
      case "expirationYear":
        setExpirationYear(event.target.value);
        break;
      case "disclaimerCheck":
        ((document.querySelector("#disclaimerCheck") as HTMLInputElement).parentElement as HTMLInputElement).style.borderBottom = "white";
        break;
    }
  };

  const onFocusHandler = (identifier: string, event: FocusEvent & { target: HTMLInputElement | HTMLSelectElement }) => {
    switch (identifier) {
      case "expirationMonth":
        isDisclaimerCheck();
        break;
      case "disclaimerCheck":
        if (isDisclaimerCheck()) {
          ((document.querySelector("#disclaimerCheck") as HTMLInputElement).parentElement as HTMLInputElement).style.borderBottom =
            "thin dashed #e12128";
        }
        break;
    }
  };

  const isDisclaimerCheck = () => {
    if (creditcardValid && cvvValid && !disclaimerCheckRef.current?.checked) {
      ((document.querySelector("#disclaimerCheck") as HTMLInputElement).parentElement as HTMLInputElement).style.backgroundColor =
        "#FFD6D7";
      setErrmessage(EMPTYDISLAIMERMESSAGE);
      disclaimerCheckRef.current?.focus();
      return false;
    }
    return true;
  };

  const onKeyDownHandler = (identifier: string, event: KeyboardEvent<HTMLSelectElement | HTMLButtonElement>) => {
    if (event.key === "Tab") {
      if (identifier === "expirationMonth" && event.shiftKey) {
        if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
          cvvMicroSecurityCode.focus();
        }
      } else if (identifier === "submit" && !event.shiftKey) {
        if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
          creditCardMicroNumber.focus();
        }
      }
    }
  };

  return (
    <>
      <form id="tcepps" name="tcepps" action={tceppsState.cpgmuiApiUrl} target="_self">
        <input id="app" name="app" type="hidden" value={tceppsState.app} />
        <input id="token" name="token" type="hidden" value={tceppsState.token} />
        <input id="sign" name="sign" type="hidden" value={tceppsState.sign} />
        <input id="serviceOperation" name="serviceOperation" type="hidden" value="" />
        <input id="encryptedRequest" name="encryptedRequest" type="hidden" value={tceppsState.encryptedRequest} />
        <input id="encryptedRequestKey" name="encryptedRequestKey" type="hidden" value={tceppsState.encryptedRequestKey} />
        <input id="lastFourCcAccountNum" name="lastFourCcAccountNum" type="hidden" value="" />
        <input id="proxyUrl" name="proxyUrl" type="hidden" value="" />
      </form>
      <form id="pno" name="pno">
        <input id="ccAccountNum" name="ccAccountNum" type="hidden" value="" />
        <input id="ccExp" name="ccExp" type="hidden" value="" />
        <input id="ccCardVerifyNum" name="ccCardVerifyNum" type="hidden" value="" />
        <input id="avsName" name="avsName" type="hidden" value="" />
        <input id="avsAddress1" name="avsAddress1" type="hidden" value="" />
        <input id="avsAddress2" name="avsAddress2" type="hidden" value="" />
        <input id="avsCity" name="avsCity" type="hidden" value="" />
        <input id="avsState" name="avsState" type="hidden" value="" />
        <input id="avsZip" name="avsZip" type="hidden" value="" />
        <input id="avsCountryCode" name="avsCountryCode" type="hidden" value="" />
        <input id="amount" name="amount" type="hidden" value="" />
        <input id="orderID" name="orderID" type="hidden" value="" />
        <input id="cardBrand" name="cardBrand" type="hidden" value="" />
        <input id="customerEmail" name="customerEmail" type="hidden" value="" />
        <input id="customerPhone" name="customerPhone" type="hidden" value="" />
      </form>
      <div className={`${classesSnr.mycanonsnr} ${classesSnr.top_level}`}>
        <div className={`${classesSnr.mycanonsnr} ${classesSnr.custom_overlay}`}>
          <div className={`${classesSnr.mycanonsnr} ${classesSnr.Popup__card}`}>
            <div className="">
              <form id="creditCard" className={`${classesSnr.mycanonsnr} ${classesSnr.Form__Form}`}>
                <div id="info-field" className="">
                  <div style={margin20Style}>
                    <div className={`${classesSnr.mycanonsnr} ${classesSnr.Label__group}`}></div>
                  </div>
                  <div id="info-field" className="">
                    <div style={margin20Style}>
                      <div className={`${classesSnr.mycanonsnr} ${classesSnr.Label__group}`}></div>
                    </div>
                    <div id="cnumdiv">
                      <div className={`${classesSnr.mycanonsnr} ${classesSnr.Row__Row} ${classesSnr.Row__padding_sm}`}>
                        <div
                          className={`${classesSnr.mycanonsnr} ${classesSnr.Col__Col} ${classesSnr.Col__sm_12} ${classesSnr.Col__md_8} ${classesSnr.Col__padding_sm}`}
                        >
                          <div>
                            <div className={`${classesSnr.mycanonsnr} ${classesSnr.Label__group}`}>
                              <div
                                className={`${classesSnr.mycanonsnr} ${classesSnr.Input__Input} ${classesSnr.Search__SearchInput} ${creditCardNumberBorderClass} ${creditCardNumberInvalidClass}`}
                              >
                                <div
                                  id="creditCardNumber"
                                  style={creditCardStyle}
                                  tabIndex={1}
                                  ref={creditCardNumberRef}
                                  aria-disabled={creditCardNumberDisabled}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={`${classesSnr.mycanonsnr} ${classesSnr.Col__Col} ${classesSnr.Col__sm_12} ${classesSnr.Col__md_4} ${classesSnr.Col__padding_sm}`}
                        >
                          <div className="" id="a">
                            <div className={`${classesSnr.mycanonsnr} ${classesSnr.Label__group}`}>
                              <div className={`${classesSnr.mycanonsnr} ${classesSnr.Search__SearchContainer}`}>
                                <div
                                  className={`${classesSnr.mycanonsnr} ${classesSnr.Input__Input} ${classesSnr.Search__SearchInput} ${securityCodeBorderClass} ${securityCodeInvalidClass}`}
                                >
                                  <div
                                    id="securityCode"
                                    style={creditCardStyle}
                                    tabIndex={2}
                                    ref={securityCodeRef}
                                    aria-disabled={securityCodeDisabled}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="">
                        <div
                          className={`${classesSnr.mycanonsnr} ${classesSnr.Row__Row} ${classesSnr.Row__padding_sm} ${classesSnr.Row__Row_Left}`}
                        >
                          <div className={`${classesSnr.mycanonsnr} ${classesSnr.CardButton__Group_sm}`}>
                            <div
                              className={`${classesSnr.mycanonsnr} ${classesSnr.CardButton__Group_sm} ${classesSnr.CardButton__inner_sm}`}
                            >
                              <div className={`${classesSnr.mycanonsnr} ${classesSnr.Link__Link} ${classesSnr.CardButton__CardButton_sm}`}>
                                <input
                                  type="radio"
                                  value="visa"
                                  name="cardtype"
                                  id="visa"
                                  tabIndex={-1}
                                  disabled={true}
                                  checked={selectedCreditCard === "visa" || selectedCreditCard === "VI"}
                                  onClick={() => {
                                    return false;
                                  }}
                                />
                                <label htmlFor="visa"></label>
                                <img src={require("./img/vi.png")} alt="Visa" />
                              </div>
                              <div className={`${classesSnr.mycanonsnr} ${classesSnr.Link__Link} ${classesSnr.CardButton__CardButton_sm}`}>
                                <input
                                  type="radio"
                                  value="mastercard"
                                  name="cardtype"
                                  id="mastercard"
                                  tabIndex={-1}
                                  disabled={true}
                                  checked={selectedCreditCard === "mastercard" || selectedCreditCard === "MC"}
                                  onClick={() => {
                                    return false;
                                  }}
                                />
                                <label htmlFor="mastercard"></label>
                                <img src={require("./img/mc.png")} alt="Mastercard" />
                              </div>
                              <div className={`${classesSnr.mycanonsnr} ${classesSnr.Link__Link} ${classesSnr.CardButton__CardButton_sm}`}>
                                <input
                                  type="radio"
                                  value="amex"
                                  name="cardtype"
                                  id="amex"
                                  tabIndex={-1}
                                  disabled={true}
                                  checked={selectedCreditCard === "amex" || selectedCreditCard === "AX"}
                                  onClick={() => {
                                    return false;
                                  }}
                                />
                                <label htmlFor="amex"></label>
                                <img src={require("./img/ae.png")} alt="American Express" />
                              </div>
                              <div className={`${classesSnr.mycanonsnr} ${classesSnr.Link__Link} ${classesSnr.CardButton__CardButton_sm}`}>
                                <input
                                  type="radio"
                                  value="discover"
                                  name="cardtype"
                                  id="discover"
                                  tabIndex={-1}
                                  disabled={true}
                                  checked={selectedCreditCard === "discover" || selectedCreditCard === "DI"}
                                  onClick={() => {
                                    return false;
                                  }}
                                />
                                <label htmlFor="discover"></label>
                                <img src={require("./img/di.png")} alt="Discover" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`${classesSnr.mycanonsnr} ${classesSnr.Row__Row} ${classesSnr.Row__padding_sm}`}>
                        <div
                          className={`${classesSnr.mycanonsnr} ${classesSnr.Col__Col} ${classesSnr.Col__sm_12} ${classesSnr.Col__md_6} ${classesSnr.Col__padding_sm}`}
                        >
                          <div className="" id="b">
                            <div className={`${classesSnr.mycanonsnr} ${classesSnr.Label__group} ${classesSnr.select__parent}`}>
                              <select
                                id="expirationMonth"
                                name="expirationMonth"
                                tabIndex={3}
                                value={expirationMonth}
                                disabled={expirationMonthDisabled}
                                ref={expirationMonthRef}
                                onChange={(event) => onChangeHandler("expirationMonth", event)}
                                onKeyDown={(event) => onKeyDownHandler("expirationMonth", event)}
                                onBlur={(event) => onBlurHandler("expirationMonth", event)}
                                onFocus={(event) => onFocusHandler("expirationMonth", event)}
                                className={`${classesSnr.mycanonsnr} ${classesSnr.Input__Input} ${expirationMonthInvalidClass}`}
                              >
                                <option value="01">01 - January</option>
                                <option value="02">02 - February</option>
                                <option value="03">03 - March</option>
                                <option value="04">04 - April</option>
                                <option value="05">05 - May</option>
                                <option value="06">06 - June</option>
                                <option value="07">07 - July</option>
                                <option value="08">08 - August</option>
                                <option value="09">09 - September</option>
                                <option value="10">10 - October</option>
                                <option value="11">11 - November</option>
                                <option value="12">12 - December</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${classesSnr.mycanonsnr} ${classesSnr.Col__Col} ${classesSnr.Col__sm_12} ${classesSnr.Col__md_6} ${classesSnr.Col__padding_sm}`}
                        >
                          <div className="" id="c">
                            <div className={`${classesSnr.mycanonsnr} ${classesSnr.Label__group} ${classesSnr.select__parent}`}>
                              <select
                                id="expirationYear"
                                name="expirationYear"
                                tabIndex={4}
                                value={expirationYear}
                                disabled={expirationYearDisabled}
                                ref={expirationYearRef}
                                onChange={(event) => onChangeHandler("expirationYear", event)}
                                onBlur={(event) => onBlurHandler("expirationYear", event)}
                                className={`${classesSnr.mycanonsnr} ${classesSnr.Input__Input}`}
                              ></select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`${classesSnr.mycanonsnr} ${classesSnr.Row__Row_Left} ${classesSnr.Row__padding_sm}`}>
                    <div className={`${classesSnr.mycanonsnr} ${classesSnr.Label__group} ${classesSnr.Row__Row_Left}`}>
                      <div className={`${classesSnr.mycanonsnr} ${classesSnr.Input__Input_msg} ${classesSnr.Search__SearchInput}`}>
                        <textarea
                          id="errmessage"
                          name="errmessage"
                          tabIndex={-1}
                          style={{ color: "red", border: "0", width: "95%", resize: "none", overflowY: "hidden", background: "white" }}
                          value={errmessage}
                          ref={errmessageRef}
                          rows={1}
                          cols={180}
                          className={`${classesSnr.mycanonsnr} ${classesSnr.errmessage}`}
                          disabled={errmessageDisabled}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="" style={{ width: "100%" }}>
                    <div className={`${classesSnr.mycanonsnr} ${classesSnr.Form__Footer}`}>
                      <table className={`${classesSnr.mycanonsnr} ${classesSnr.ui_panelgrid} ${classesSnr.ui_widget}`} role="grid">
                        <tbody>
                          <tr
                            className={`${classesSnr.mycanonsnr} ${classesSnr.ui_widget_content} ${classesSnr.ui_panelgrid_even}`}
                            role="row"
                          >
                            <td
                              role="gridcell"
                              className={`${classesSnr.mycanonsnr} ${classesSnr.ui_panelgrid_cell}`}
                              style={{ textAlign: "left" }}
                            >
                              <label style={{ color: "#000000" }} htmlFor="disclaimerCheck">
                                Amount Approved:
                              </label>
                            </td>
                            <td
                              role="gridcell"
                              className={`${classesSnr.mycanonsnr} ${classesSnr.ui_panelgrid_cell} ${classesSnr.text_time_amount}`}
                            >
                              <label
                                style={{ color: "#cc0000" }}
                                htmlFor="disclaimerCheck"
                                ref={amountRef}
                                // className={`${classesSnr.mycanonsnr} ${classesSnr.text_time_amount}`}
                              >
                                {amount}
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className={`${classesSnr.mycanonsnr} ${classesSnr.Row__Row} ${classesSnr.Row__padding_sm}`}>
                    <div
                      className={`${classesSnr.mycanonsnr} ${classesSnr.Col__Col} ${classesSnr.Col__sm_12} ${classesSnr.Col__md_1} ${classesSnr.Col__padding_sm}`}
                    >
                      <div className="">
                        <div className={`${classesSnr.mycanonsnr} ${classesSnr.Label__group}`}>
                          <div className={`${classesSnr.mycanonsnr} ${classesSnr.Disclaim_Footer}`} id="disclaimerCheckGroup">
                            <input
                              id="disclaimerCheck"
                              name="disclaimerCheck"
                              type="checkbox"
                              tabIndex={5}
                              checked={disclaimerCheck}
                              disabled={disclaimerCheckDisabled}
                              ref={disclaimerCheckRef}
                              className={`${classesSnr.mycanonsnr} ${classesSnr.disclaimerCheck} ${classesSnr.body}`}
                              onChange={(event) => onChangeHandler("disclaimerCheck", event)}
                              onBlur={(event) => onBlurHandler("disclaimerCheck", event)}
                              onFocus={(event) => onFocusHandler("disclaimerCheck", event)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`${classesSnr.mycanonsnr} ${classesSnr.Col__Col} ${classesSnr.Col__sm_12} ${classesSnr.Col__md_10} ${classesSnr.Col__padding_sm}`}
                    >
                      <div className="">
                        <div className={`${classesSnr.mycanonsnr} ${classesSnr.Label__group}`}>
                          <div className={`${classesSnr.mycanonsnr} ${classesSnr.Disclaim_Footer}`}>
                            <label
                              id="Disclaim_Footer_First"
                              htmlFor="disclaimerCheck"
                              tabIndex={-1}
                              className={`${classesSnr.mycanonsnr} ${disclaimerClass}`}
                            >
                              {disclaimerFirstRef.current}
                            </label>
                          </div>
                        </div>
                        <div className={`${classesSnr.mycanonsnr} ${classesSnr.Label__group}`}>
                          <div className={`${classesSnr.mycanonsnr} ${classesSnr.Disclaim_Footer_Second} ${classesSnr.justify_second}`}>
                            <label
                              id="Disclaim_Footer_Second"
                              htmlFor="disclaimerCheck"
                              tabIndex={-1}
                              className={`${classesSnr.mycanonsnr} ${disclaimerClass}`}
                            >
                              {disclaimerSecondRef.current}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="" style={margin20Style}>
                    <div className={`${classesSnr.mycanonsnr} ${classesSnr.Label__group}`}>
                      <div className={`${classesSnr.mycanonsnr} ${classesSnr.Form__Footer}`} id="submitBtnId">
                        <table
                          className={`${classesSnr.mycanonsnr} ${classesSnr.ui_panelgrid} ${classesSnr.ui_widget}`}
                          role="grid"
                          // hidden={!submitVisible}
                        >
                          <tbody>
                            <tr
                              className={`${classesSnr.mycanonsnr} ${classesSnr.ui_widget_content} ${classesSnr.ui_panelgrid_even}`}
                              role="row"
                            >
                              <td
                                role="gridcell"
                                className={`${classesSnr.mycanonsnr} ${classesSnr.ui_panelgrid_cell}`}
                                style={{ justifyContent: "center" }}
                              >
                                <div
                                  className={`${classesSnr.mycanonsnr} ${classesSnr.top_level_submit}`}
                                  // style={{ width: "132px", height: "44px", justifyContent: "flex-right" }}
                                  onClick={(event) => clickHandler(event)}
                                >
                                  <div
                                    className={`${classesSnr.mycanonsnr} ${classesSnr.Button__Button} ${classesSnr.Button__alt}`}
                                    onClick={(event) => clickHandler(event)}
                                  >
                                    <button
                                      id="submitBtn"
                                      name="submitBtn"
                                      type="submit"
                                      className={`${classesSnr.mycanonsnr} ${classesSnr.Button__text} ${classesSnr.ui_button_primeface} ${classesSnr.body}`}
                                      tabIndex={6}
                                      ref={submitButtonRef}
                                      onClick={(event) => clickHandler(event)}
                                      disabled={!submitButtonEnable}
                                      aria-disabled={!submitButtonEnable}
                                    >
                                      <i className={`${submitBackground}`}></i>
                                      {submitButtonEnable ? "SUBMIT" : "SUBMIT"}
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
