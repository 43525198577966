export const getYearsFromToday = (numberOfYears: number, selectField: HTMLSelectElement, addYYYYY: boolean) => {
  var years = [];

  // Get todays calendar object
  const currentDate = new Date();
  const currentYear = currentDate.toLocaleString("en-US", { year: "numeric" });

  if (addYYYYY) {
    years.push("YYYY");
  }

  // Keep adding 1 year to the calendar until we reach the limiter on the number of years parameter
  for (let idx = 0; idx < numberOfYears; idx++) {
    years.push((parseInt(currentYear) + idx).toString());
  }

  var newOptions = years;
  var newValues = years;
  selectField.options.length = 0;
  for (let idx = 0; idx < newOptions.length; idx++) {
    selectField.options[selectField.length] = new Option(newOptions[idx], newValues[idx]);
  }
};

export const getCurrentYearMonth = () => {
  let sysdate = new Date();
  let sysMonth = sysdate.getMonth() + 1;
  let sysMonthStr = sysMonth.toString();
  if (sysMonth < 10) {
    sysMonthStr = "0" + sysMonth;
  }
  return sysdate.getFullYear() + sysMonthStr;
};

export interface decryptParamType {
  [key: string]: any;
}

export const isDevUrl = (inurl: string) => {
  return inurl.indexOf("http://localhost") >= 0 || inurl.indexOf("https://cpgmui-be-") >= 0;
};

export const getCnaUsStates = (selectField: HTMLSelectElement, isCna: boolean) => {
  var states = [
    ["AB", "AB - Alberta"],
    ["AK", "AK - Alaska"],
    ["AL", "AL - Alabama"],
    ["AR", "AR - Arkansas"],
    ["AS", "AS - American Samoa"],
    ["AZ", "AZ - Arizona"],
    ["BA", "BA - Bahamas"],
    ["BC", "BC - British Columbia"],
    ["BR", "BR - Bermuda"],
    ["CA", "CA - California"],
    ["CO", "CO - Colorado"],
    ["CT", "CT - Connecticut"],
    ["CZ", "CZ - Canal Zone"],
    ["DC", "DC - District of Columbia"],
    ["DE", "DE - Delaware"],
    ["FL", "FL - Florida"],
    ["GA", "GA - Georgia"],
    ["GU", "GU - Guam"],
    ["HI", "HI - Hawaii"],
    ["IA", "IA - Iowa"],
    ["ID", "ID - Idaho"],
    ["IL", "IL - Illinois"],
    ["IN", "IN - Indiana"],
    ["KS", "KS - Kansas"],
    ["KY", "KY - Kentucky"],
    ["LA", "LA - Louisiana"],
    ["MA", "MA - Massachusetts"],
    ["MD", "MD - Maryland"],
    ["ME", "ME - Maine"],
    ["MI", "MI - Michigan"],
    ["MN", "MN - Minnesota"],
    ["MO", "MO - Missouri"],
    ["MP", "MP - N. Marianas"],
    ["MS", "MS - Mississippi"],
    ["MT", "MT - Montana"],
    ["NB", "NB - New Brunswick"],
    ["NC", "NC - North Carolina"],
    ["ND", "ND - North Dakota"],
    ["NE", "NE - Nebraska"],
    ["NH", "NH - New Hampshire"],
    ["NJ", "NJ - New Jersey"],
    ["NM", "NM - New Mexico"],
    ["NV", "NV - Nevada"],
    ["NY", "NY - New York "],
    ["OH", "OH - Ohio"],
    ["OK", "OK - Oklahoma"],
    ["ON", "ON - Ontario"],
    ["OR", "OR - Oregon"],
    ["PA", "PA - Pennsylvania"],
    ["PR", "PR - Puerto Rico"],
    ["QB", "QB - Quebec"],
    ["RI", "RI - Rhode Island"],
    ["SA", "SA - Saskatchewan"],
    ["SC", "SC - South Carolina"],
    ["SD", "SD - South Dakota"],
    ["SP", "SP - Saipan"],
    ["TN", "TN - Tennessee"],
    ["TX", "TX - Texas"],
    ["UT", "UT - Utah"],
    ["VA", "VA - Virginia"],
    ["VI", "VI - Virgin Islands"],
    ["VT", "VT - Vermont"],
    ["WA", "WA - Washington"],
    ["WI", "WI - Wisconsin"],
    ["WV", "WV - West Virginia"],
    ["WY", "WY - Wyoming"],
    ["XX", "XX - Miscellaneous State Code"],
  ];

  selectField.options.length = 0;
  for (let idx = 0; idx < states.length; idx++) {
    if (isCna) {
      selectField.options[selectField.length] = new Option(states[idx][1], states[idx][0]);
    } else {
      if (idx === 0) {
        selectField.options[selectField.length] = new Option(" ", " ");
      }
      if (states[idx][0] !== "XX") {
        selectField.options[selectField.length] = new Option(states[idx][0], states[idx][0]);
      }
    }
  }
};

export const isCybersourceTimedout = (startTime: any) => {
  if (startTime) {
    var currTime: any = new Date();
    var differenceValue = (startTime.getTime() - currTime.getTime()) / 1000;
    differenceValue /= 60;
    let differenceMin = Math.abs(Math.round(differenceValue));
    // console.log("difference in min:" + differenceMin);
    if (differenceMin > 12) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getTimedoutDummyToken = () => {
  return {
    token:
      "eyJraWQiOiIwN0dSWkprb2ZaSzcyVzJ0S2toSkhacDJLU0tnZWhUTSIsImFsZyI6IlJTMjU2In0.eyJpc3MiOiJGbGV4LzA3IiwiZXhwIjoxNzAyNDIxNjQwLCJ0eXBlIjoibWYtMi4wLjAiLCJpYXQiOjE3MDI0MjA3NDAsImp0aSI6IjFDMUZSNzZDVDEwNUQ3U0NPS0dBSUwxUDFOUzBYM0Q1TEFMWVRMQ01XMDVaRzdYMEhXUlY2NTc4RTQ4ODJBODQiLCJjb250ZW50Ijp7InBheW1lbnRJbmZvcm1hdGlvbiI6eyJjYXJkIjp7ImV4cGlyYXRpb25ZZWFyIjp7InZhbHVlIjoiMjAzMCJ9LCJudW1iZXIiOnsibWFza2VkVmFsdWUiOiJYWFhYWFhYWFhYWFgwMDA0IiwiYmluIjoiNjAxMTAwIn0sInNlY3VyaXR5Q29kZSI6e30sImV4cGlyYXRpb25Nb250aCI6eyJ2YWx1ZSI6IjEwIn0sInR5cGUiOnsidmFsdWUiOiIwMDQifX19fX0.ADrV4Ddnn6T7QEd725CHtmnkmhlQQ9GcMgIHcWT3f8IDbmeiDf9M006C4Qmwm1PExRTcLc4-it_iWnMGl4xjRreNrDRc2vliN2CLCFwVL6xAc0VPWKKE_UhUtF6Nst_2sshAELd9BVPwRg2iW49fSQbV5yKG0VdrIQ2KB-nJxvcfV0Z5Msnp9FcnLzXDprNmD5Mwd_JFdwS8H3OsgwYpP5NiE7Yy6504nsQXoxunkupM93l3VVd1GmW4Ji_NIXPAJDryPoYVp7t7Oe3KB2o6m5OVRYLCsydGS2hfASkTyDhFYnOgZ8bZiKAKdzEcjW7PfkTSuWeE0L0IqVMOfKH_Hg", // dummytoken
    lastFourDigits: "0004", //dummy number
    jti: "1C1FR76CT105D7SCOKGAIL1P1NS0X3D5LALYTLCMW05ZG7X0HWRV6578E4882A84", //dummy token
  };
};

const DATESEPARATOR = "/";
export const keyUpDateEvent = (event: any) => {
  if (event.isComposing || event.keyCode === 229) {
    return;
  }
  var textSoFar = event.target.value;
  /* != / Slash */
  if (event.keyCode !== 191) {
    //- != Backspace
    if (event.keyCode !== 8) {
      if (textSoFar.length === 1 && textSoFar >= "2" && isNumber(textSoFar)) {
        event.target.value = "0" + textSoFar + DATESEPARATOR + "20";
      } else if (
        textSoFar.length === 2 &&
        event.target.selectionStart >= 2 &&
        event.target.selectionStart === event.target.selectionEnd &&
        isDateNumber(textSoFar)
      ) {
        event.target.value = textSoFar + DATESEPARATOR + "20";
      } else if (event.keyCode === 86 && textSoFar.length === 6) {
        //- === KeyV
        //- to handle copy & paste of 8 digit
        event.target.value = textSoFar.substr(0, 2) + DATESEPARATOR + textSoFar.substr(2, 4);
      }
      //- == Backspace
    } else {
      //- backspace would skip the slashes and just remove the numbers
      if (textSoFar.length === 5 && !(event.target.selectionStart <= 1)) {
        event.target.value = textSoFar.substring(0, 2);
      }
    }
  } else {
    //- == /
    //remove slashes to avoid 12//01/2014
    if (textSoFar.length !== 3) {
      event.target.value = textSoFar.substring(0, textSoFar.length - 1);
    } else if (isDateNumber(textSoFar)) {
      event.target.value = textSoFar + "20";
    }
  }
};

export const isDateNumber = (inputStr: string) => {
  return ((inputStr.substr(0, 1) <= "1" && inputStr.substr(1, 1) <= "2") || inputStr.substr(0, 1) === "0") &&
    isNumber(inputStr.substr(0, 1)) &&
    isNumber(inputStr.substr(1, 1))
    ? true
    : false;
};
export const isNumber = (inputStr: string) => {
  return inputStr >= "0" && inputStr <= "9" ? true : false;
};
