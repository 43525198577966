export const TceppsTestData = {
  ToBeAppTestData: {
    S21ROSS: {
      "to_be_app.creditCardNumber": "4055011111111111",
      "to_be_app.creditCardType": "VI",
      "to_be_app.billingName": "TATSUYA KAMEDA",
      "to_be_app.expirationMonthYear": "02/2034",
      "to_be_app.addressline1": "1 IPSWICH AVE.",
      "to_be_app.addressline2": "APT 113",
      "to_be_app.city": "GREAT NECK",
      "to_be_app.state": "NY",
      "to_be_app.postCode": "11021",
      "to_be_app.division": "GMD",
    },
    S21SR: {
      "to_be_app.creditCardType": "VI",
      "to_be_app.expirationMonthYear": "08/2030",
      "to_be_app.creditCardNumber": "4055011111111111",
    },
    S21CSA: {
      "tceppsRequest.ppa.customerName": "Canon HQI",
      "tceppsRequest.ppa.customerAddress1": "1 Canon Park",
      "tceppsRequest.ppa.customerAddress2": "",
      "tceppsRequest.ppa.customerCity": "Melville",
      "tceppsRequest.ppa.customerState": "NY",
      "tceppsRequest.ppa.customerZIP": "11747",
      "tceppsRequest.ppa.customerCountryCode": "US",
      "tceppsRequest.ppa.expirationMonthYear": "08/2030",
    },
    S21CSA_ASCC: {
      "ppa.test.expirationMonthYear": "10/2033",
    },
    S21CSA_MYCSA: {
      "ppa.test.expirationMonthYear": "06/2032",
    },
    CNA: {
      "to_be_app.expirationYear": "2032",
      "to_be_app.expirationMonth": "07",
    },
    CNA_ACH: {
      "to_be_app.bankAccountNumber": "011301073",
      "to_be_app.routingNumber": "011400039",
    },
    CPNET_ACH: {
      "to_be_app.bankAccountNumber": "1234",
      "to_be_app.routingNumber": "112200439",
    },
    MyCanon: {
      // "ppa.test.ccAccountNum": "4055011111111111",
      "ppa.test.expirationMonth": "10",
      "ppa.test.expirationYear": "2030",
      "ppa.test.firstName": "Canon",
      "ppa.test.lastName": "Inc",
      "ppa.test.address1": "1 Canon Park",
      "ppa.test.address2": "",
      "ppa.test.city": "Melville",
      "ppa.test.state": "NY",
      "ppa.test.zip": "11747",
    },
    MyCanonSnr: {
      "ppa.test.expirationMonth": "10",
      "ppa.test.expirationYear": "2032",
      "ppa.test.amount": "50.00",
    },
    MyCanoncpsap: {
      "ppa.test.expirationMonth": "09",
      "ppa.test.expirationYear": "2032",
    },
    MyCanoncpscc: {
      "ppa.test.expirationMonth": "08",
      "ppa.test.expirationYear": "2032",
    },
    CUSASM: {
      "ppa.test.expirationMonth": "11",
      "ppa.test.expirationYear": "2032",
    },
    ROSSPOC: {
      "to_be_app.billingName": "TATSUYA KAMEDA",
      "to_be_app.expirationMonthYear": "02/2034",
      "to_be_app.addressline1": "1 IPSWICH AVE.",
      "to_be_app.addressline2": "APT 113",
      "to_be_app.city": "GREAT NECK",
      "to_be_app.state": "NY",
      "to_be_app.postCode": "11021",
      "to_be_app.division": "GMD",
    },
    LATMPOC: {
      "ppa.test.expirationMonth": "08",
      "ppa.test.expirationYear": "2032",
    },
    LATM3POC: {
      "ppa.test.expirationMonth": "10",
      "ppa.test.expirationYear": "2032",
    },
    INSTLPAYPOC: {
      "ppa.test.expirationMonth": "10",
      "ppa.test.expirationYear": "2031",
    },
    CUSAC360B2BCAREPAK: {
      "ppa.test.expirationMonthYear": "10/2033",
    },
    CUSAC360B2BSNR: {
      "ppa.test.expirationMonthYear": "10/2033",
    },
  },
};

function checkForTestingApp(testingAppParameter: string) {
  // console.log("testingAppParameter:" + testingAppParameter);
  if (testingAppParameter && testingAppParameter === "YES") {
    return true;
  } else {
    return false;
  }
}

export const isTestingApp = (testingAppParameter: string) => {
  if (checkForTestingApp(testingAppParameter)) {
    return true;
  } else {
    return false;
  }
};
