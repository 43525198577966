import { BrowserRouter as Router } from "react-router-dom";
// import {useDispatch, useSelector} from "react-redux";
// import { useEffect } from "react";
// import logo from "./logo.svg";
import "./App.css";
// import { loadConfigAction } from "./redux-store/reducer/configSlice";
//import {config} from "./config/uiconfig";
import Content from "./component/content/Content";

function App() {
  // const configState = useSelector((state: any) => state.config);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(loadConfigAction());
  // }, [dispatch]);

  return (
    <div>
      <Router>
        <Content />
      </Router>
    </div>
  );
}

export default App;
