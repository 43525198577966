import React, { ChangeEvent, FocusEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isFirefox } from "react-device-detect";
import axios, { AxiosError } from "axios";

import {
  decryptParamType,
  getCurrentYearMonth,
  isCybersourceTimedout,
  getTimedoutDummyToken,
  keyUpDateEvent,
} from "../../utils/calendarUtil";
import { formatAmount } from "../../utils/numberUtil";
import { TceppsTestData, isTestingApp } from "../../utils/TestLoadData";

import { tceppsActions } from "../../redux-store/reducer/tcepps_slice";
import { tceppsProxyActions } from "../../redux-store/reducer/tceppsProxy_slice";
import { contextLoadedAction, microformInitializedAction, tokenCreatedAction, unmountAction } from "../../redux-store/reducer/paymentSlice";
import { LogService, LOGERRORLEVEL } from "../../service/logService";
import PaymentService from "../../service/paymentService";

import classedCsaAscc from "./S21CsaAsccComponent.module.css";
import { extractClientLibrary, parseToken, extractDomain } from "../../utils/utils";
import { componentVarObject, ComponentVarProps } from "../../utils/ComponentConstant";

import {
  // cancel,
  newOrder,
  profileAdd,
  // validationError
} from "../../resources/creditCardFormPage/js/tcepps";

var Buffer = require("buffer/").Buffer; // note: the trailing slash is important!

let developmentEnv = false;
var creditCardMicroNumber: any;

// const FORMINITIZING = "Browser initializing. Please wait and retry.";
const INVALIDAXIOSMESSAGE = "AxiosError. ";
const INVALIDCARDMESSAGE = "Please enter a valid Credit Card Number";
const INVALIDDATEMESSAGE = "Please enter a valid Expiry Date";
const CARDEXPIREDMESSAGE = "The card is expired";

const DATEPATTERN = "^((0[1-9])|(1[0-2]))/([2-9][0-9][0-9][0-9])$";

const styletd = {
  align: "left",
  margin: 0,
  padding: 0,
  numberOfLines: 1,
} as React.CSSProperties;
const s21srDivTopStyle = {
  height: "100%",
  width: "100%",
  // height: "93px",
  // width: "360px",
  margin: "0px",
  position: "relative",
  overflow: "auto",
  // display: "flex !important",
  // justifyContent: "flex-start",
} as React.CSSProperties;
const inputStyle = {
  fontSize: "13px",
  margin: "0px",
  padding: "0px",
  fontFamily: '"OpticSansBook", sans-serif',
} as React.CSSProperties;
const inputStyleCard = {
  fontSize: "13px",
  margin: "0px",
  padding: "0px",
  fontFamily: '"OpticSansBook", sans-serif',
  color: "grey",
} as React.CSSProperties;
const tableStyle = {
  border: "0",
  cellspacing: "1",
  fontSize: "13px",
  margin: "0px",
  padding: "0px",
  fontFamily: '"OpticSansBook", sans-serif',
  align: "left",
  display: "flex",
  tableLayout: "fixed",
  borderTopWidth: "0px",
  borderBottomWidth: "0px",
  borderLeftWidth: "0px",
  borderRightWidth: "0px",
  borderHorizontalSpacing: "1px",
  borderVerticalSpacing: "1px",
} as React.CSSProperties;
const creditCardStyle = {
  fontSize: "13px",
  fontFamily: '"OpticSansBook", sans-serif',
  height: "17px",
  width: "150px",
  margin: "0",
  padding: "0",
  color: "fieldtext",
  letterSpacing: "normal",
  wordSpacing: "normal",
  lineHeight: "normal",
  textIndent: "0px",
  textShadow: "none",
  display: "inline-block",
  cursor: "text",
  backgroundColor: "field",
  background: "#ffff00",
  border: "1px solid #858585",
  borderImage: "initial",
} as React.CSSProperties;
const flexStyles = {
  input: {
    "font-size": "13px",
    "font-family": '"OpticSansBook", sans-serif',
    cursor: "text",
  },
  "::placeholder": { color: "lightslategrey" },
  ":focus": { color: "black" },
  ":disabled": { cursor: "not-allowed" },
  valid: { color: "black" },
  invalid: { color: "#a94442" },
  // ":hover": {
  //   "font-style": "italic",
  // },
} as React.CSSProperties;
const tableMainStyle = {
  border: "0",
  borderCollapse: "collapse",
  borderSpacing: "0",
  padding: "0",
  cellspacing: "0",
  cellpadding: "0",
  width: "100%",
} as React.CSSProperties;

const toDay = new Date();

export function S21CsaAsccComponent(this: any) {
  const dispatch = useDispatch();
  const tceppsState = useSelector((state: any) => state.tcepps);
  const paymentInfo = useSelector((state: any) => state.paymentInfo);

  const [creditCardType, setCreditCardType] = useState("");
  const [expirationMonthYear, setExpirationMonthYear] = useState("");
  const [serviceOperation, setServiceOperation] = useState("");
  const [validationMessages, setValidationMessages] = useState("");
  const [textAreaDisplay, setTextAreaDisplay] = useState("none");
  const [infoVisble, setInfoVisble] = useState({ creditCardInfoHidden: false, orderInfoHidden: false });

  const [creditCardTypeDisabled, setCreditCardTypeDisabled] = useState(false);
  const [expirationMonthYearDisabled, setExpirationMonthYearDisabled] = useState(false);

  const creditCardTypeRef = useRef<HTMLSelectElement>(null);
  const expirationMonthYearRef = useRef<HTMLInputElement>(null);
  const topDivRef = useRef<HTMLDivElement>(null);
  const validationMessagesRef = useRef<HTMLTextAreaElement>(null);
  const customerRefNumRef = useRef<string>("");
  const orderIdRef = useRef<string>("");
  const amountRef = useRef<string>("");
  const origAmountRef = useRef<string>("");
  const tceppsProxyAvsNameRef = useRef<string>("");
  const tceppsProxyAddress1Ref = useRef<string>("");
  const tceppsProxyAddress2Ref = useRef<string>("");
  const tceppsProxyCityRef = useRef<string>("");
  const tceppsProxyStateRef = useRef<string>("");
  const tceppsProxyZipCodeRef = useRef<string>("");
  const tceppsProxyFoundRef = useRef<boolean>(false);
  const logErrorRef = useRef<string>("S21CSA_ASCC Not initialized");

  const componentObjRef = useRef<ComponentVarProps>(componentVarObject);
  const captureContextRef = useRef<string>("");
  const microformTokenRef = useRef<any>(null);

  const [creditcardValid, setCreditcardValid] = useState(false);
  const [initializationComplete, setInitializationComplete] = useState(false);
  const [formReady, setFormReady] = useState(false);

  const [decryptParams, setDecryptParams] = useState([] as decryptParamType);
  const [proxyUrl, setProxyUrl] = useState("");

  const { appKey } = useParams();

  // useEffect if screen/page input parameters change
  useEffect(() => {
    if (tceppsState.app !== undefined && tceppsState.app.length > 0 && !componentObjRef.current.tceppsStateChanged) {
      try {
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.border = "0";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.margin = "0";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.padding = "0";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.position = "relative";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.overflow = "auto";
        (document.querySelector("#root") as HTMLDivElement).style.border = "0";
        (document.querySelector("#body") as HTMLDivElement).style.border = "0";
        (document.querySelector("#root") as HTMLDivElement).style.margin = "0";
        (document.querySelector("#body") as HTMLDivElement).style.margin = "0";
        (document.querySelector("#root") as HTMLDivElement).style.padding = "0";
        (document.querySelector("#body") as HTMLDivElement).style.padding = "0";
      } catch (exception) {}

      componentObjRef.current.tceppsStateChanged = true;
    }
  }, [tceppsState.app, tceppsState.enableNewCrCardFlag]);

  const checkCreditCard = useCallback(() => {
    let errorMessage = "";
    if (decryptParams && Object.keys(decryptParams).length > 0 && formReady) {
      let setFocusSet = false;
      if (componentObjRef.current.cardMessage !== "" || !creditcardValid) {
        errorMessage = INVALIDCARDMESSAGE;
        (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1px solid red";
        (document.querySelector("#creditCardNumber") as HTMLDivElement).style.backgroundColor = "#ff6060";
        if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
          creditCardMicroNumber.focus();
          setFocusSet = true;
        }
      } else {
        (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1px solid #cccccc";
        (document.querySelector("#creditCardNumber") as HTMLDivElement).style.backgroundColor = "yellow";
        (document.querySelector("#creditCardType") as HTMLDivElement).style.backgroundColor = "yellow";
      }
      if (
        expirationMonthYearRef.current?.value === "" ||
        expirationMonthYearRef.current?.value.length !== 7 ||
        !expirationMonthYearRef.current?.value.match(DATEPATTERN)
      ) {
        if (errorMessage.length > 0) {
          errorMessage += "\n";
        }
        errorMessage += INVALIDDATEMESSAGE;
        (document.querySelector("#expirationMonthYear") as HTMLInputElement).style.border = "1px solid red";
        (document.querySelector("#expirationMonthYear") as HTMLInputElement).style.backgroundColor = "#ff6060";
        if (!setFocusSet) {
          expirationMonthYearRef.current?.focus();
          setFocusSet = true;
        }
      } else {
        let cardYearMonth = expirationMonthYearRef.current?.value.substring(3, 8) + expirationMonthYearRef.current?.value.substring(0, 2);
        if (cardYearMonth < getCurrentYearMonth()) {
          if (errorMessage.length > 0) {
            errorMessage += "\n";
          }
          errorMessage += CARDEXPIREDMESSAGE;
          (document.querySelector("#expirationMonthYear") as HTMLInputElement).style.border = "1px solid red";
          (document.querySelector("#expirationMonthYear") as HTMLInputElement).style.backgroundColor = "#ff6060";
          if (!setFocusSet) {
            expirationMonthYearRef.current?.focus();
            setFocusSet = true;
          }
        } else {
          (document.querySelector("#expirationMonthYear") as HTMLInputElement).style.border = "1px solid #cccccc";
          (document.querySelector("#expirationMonthYear") as HTMLInputElement).style.backgroundColor = "yellow";
        }
      }
      // setBrowserNotReadyMessage(false);
    } else {
      errorMessage = INVALIDCARDMESSAGE; //FORMINITIZING;
      // setBrowserNotReadyMessage(true);
    }

    setValidationMessages(errorMessage);
    // setShowValidationMessages(true);
    if (errorMessage === "") {
      setTextAreaDisplay("none");
      return true;
    } else {
      setTextAreaDisplay("block");
      return false;
    }
  }, [creditcardValid, formReady, decryptParams]);

  const toggleFields = useCallback((addProfile: string) => {
    const disabled = addProfile === "true" ? false : true;

    setCreditCardTypeDisabled(disabled);
    setExpirationMonthYearDisabled(disabled);

    (document.getElementById("creditCardType") as HTMLInputElement).disabled = !disabled;
    (document.getElementById("expirationMonthYear") as HTMLInputElement).disabled = !disabled;
  }, []);

  const getLastFourCcAccountNum = (micoFormtoken: string) => {
    const tokenArray = micoFormtoken.split(".");
    let bufferObj = Buffer.from(tokenArray[1], "base64");

    //Encode the Buffer as a utf8 string
    let decodedString = bufferObj.toString("utf8");
    let jsonObject = JSON.parse(decodedString);
    let jti = jsonObject.jti;
    let lastFourDigits = jsonObject.content.paymentInformation.card.number.maskedValue.replaceAll("X", "");
    return [jti, lastFourDigits];
  };

  const invokeProfileAdd = useCallback(
    async (ppa: any, micoFormtoken: string, tcepps: any) => {
      componentObjRef.current.processingForm = true;
      let expYearMonth = "";
      if (expirationMonthYearRef.current?.value) {
        expYearMonth = expirationMonthYearRef.current?.value.substring(3, 8) + expirationMonthYearRef.current?.value.substring(0, 2);
      }

      const [jti, lastFourDigits] = getLastFourCcAccountNum(micoFormtoken);
      let customerName = decryptParams["ppa.customerName"];
      let address1 = decryptParams["ppa.customerAddress1"];
      let address2 = decryptParams["ppa.customerAddress2"];
      let city = decryptParams["ppa.customerCity"];
      let state = decryptParams["ppa.customerState"];
      let zip = decryptParams["ppa.customerZIP"];
      if (tceppsProxyFoundRef.current) {
        customerName = tceppsProxyAvsNameRef.current;
        address1 = tceppsProxyAddress1Ref.current;
        address2 = tceppsProxyAddress2Ref.current;
        city = tceppsProxyCityRef.current;
        state = tceppsProxyStateRef.current;
        zip = tceppsProxyZipCodeRef.current;
      }
      let email = decryptParams["ppa.customerEmail"];
      const customerPhone = decryptParams["ppa.customerPhone"];

      if (!(email && email.length > 0)) {
        email = "null@cybersource.com";
      } else {
        email = email.trim();
      }
      let countryCd = decryptParams["ppa.customerCountryCode"];
      if (!(countryCd && countryCd.length > 0)) {
        countryCd = "US";
      }
      if (!address1) {
        address1 = address2;
        address2 = "";
      }

      const proxyUrl = decryptParams["tcepps.proxyUrl"];
      const successUrl = decryptParams["tcepps.successUrl"];
      const cancelUrl = decryptParams["tcepps.cancelUrl"];

      ppa.cardBrand.value = creditCardTypeRef.current?.value;
      ppa.ccAccountNum.value = "TT=" + jti;
      ppa.ccExp.value = expYearMonth;
      ppa.customerName.value = customerName ? customerName.toUpperCase().trim() : "";
      ppa.customerAddress1.value = address1 ? address1.toUpperCase().trim() : "";
      ppa.customerAddress2.value = address2 ? address2.toUpperCase().trim() : "";
      ppa.customerCity.value = city ? city.toUpperCase().trim() : "";
      ppa.customerState.value = state ? state.toUpperCase().trim() : "";
      ppa.customerZIP.value = zip ? zip.toUpperCase() : "";
      ppa.customerCountryCode.value = countryCd ? countryCd.toUpperCase().trim() : countryCd;
      ppa.customerEmail.value = email;
      ppa.customerPhone.value = customerPhone;
      // ppa.amount.value = amount;

      let orbitalConnectionUsername = decryptParams["ppa.orbitalConnectionUsername"];
      let orbitalConnectionPassword = decryptParams["ppa.orbitalConnectionPassword"];
      let orderDefaultDescription = decryptParams["ppa.orderDefaultDescription"];
      let orderDefaultAmount = decryptParams["ppa.orderDefaultAmount"];
      if (!orbitalConnectionUsername) {
        orbitalConnectionUsername = decryptParams["orbitalConnectionUsername"];
      }
      if (!orbitalConnectionPassword) {
        orbitalConnectionPassword = decryptParams["orbitalConnectionPassword"];
      }
      if (!orderDefaultDescription) {
        orderDefaultDescription = decryptParams["orderDefaultDescription"];
      }
      if (!orderDefaultAmount) {
        orderDefaultAmount = decryptParams["orderDefaultAmount"];
      }
      ppa.orbitalConnectionUsername.value = orbitalConnectionUsername ? orbitalConnectionUsername : "";
      ppa.orbitalConnectionPassword.value = orbitalConnectionPassword ? orbitalConnectionPassword : "";
      ppa.orderDefaultDescription.value = orderDefaultDescription ? orderDefaultDescription : "";
      ppa.orderDefaultAmount.value = orderDefaultAmount ? orderDefaultAmount : "";
      ppa.captureContext.value = captureContextRef.current;
      ppa.microformToken.value = microformTokenRef.current;

      if (developmentEnv) {
        let amount = "0";
        if (!address2) {
          address2 = "";
        }
        if (address1 || address2) {
          if ((address1 + address2).toLowerCase().indexOf("testdonothonorfailure") >= 0) {
            amount = "40100";
          }
          if ((address1 + address2).toLowerCase().indexOf("testaddressfailure") >= 0) {
            amount = "283600";
          }
          if ((address1 + address2).toLowerCase().indexOf("testcvvfailure") >= 0) {
            amount = "700500";
            // cvv = "111";
          }
          // if (address.toLowerCase().indexOf("testapifailure") >= 0) {
          //   email = "abcd@";
          // }
          if ((address1 + address2).toLowerCase().indexOf("testinsufficientfund") >= 0) {
            amount = "250900"; //2509 2521
          }
          if ((address1 + address2).toLowerCase().indexOf("testinsufficientfund2") >= 0) {
            amount = "252100"; //2509 2521
          }

          if (amount !== "0") {
            ppa.amount.value = amount;
          }
        }
      }
      tcepps.lastFourDigits.value = lastFourDigits;
      tcepps.serviceOperation.value = "profileAdd";
      tcepps.proxyUrl.value = proxyUrl;
      tcepps.successUrl.value = successUrl;
      tcepps.cancelUrl.value = cancelUrl;
      toggleFields("false");
      try {
        profileAdd(document.getElementById("tcepps"), ppa);
      } catch (err: any) {
        // console.log(err);
        let errorMessage = "Call to submit form <ProfileAdd> failed. " + err.message + ".";
        setValidationMessages(errorMessage);
        setTextAreaDisplay("block");
        componentObjRef.current.processingForm = false;
      }
    },
    [toggleFields, decryptParams]
  );

  const invokeNewOrder = useCallback(
    async (pno: any, tcepps: any) => {
      componentObjRef.current.processingForm = true;
      pno.customerRefNum.value = customerRefNumRef.current;
      pno.amount.value = origAmountRef.current;
      pno.orderID.value = orderIdRef.current;
      pno.addProfileFromOrder.value = "A";
      pno.profileOrderOverideInd.value = "NO";
      pno.cardBrand.value = "";
      pno.ccAccountNum.value = "";
      pno.ccExp.value = "";

      const proxyUrl = decryptParams["tcepps.proxyUrl"];
      const successUrl = decryptParams["tcepps.successUrl"];
      const cancelUrl = decryptParams["tcepps.cancelUrl"];
      let customerName = decryptParams["pno.customerName"];
      let address1 = decryptParams["pno.customerAddress1"];
      let address2 = decryptParams["pno.customerAddress2"];
      let city = decryptParams["pno.customerCity"];
      let state = decryptParams["pno.customerState"];
      let zip = decryptParams["pno.customerZIP"];
      let email = decryptParams["pno.customerEmail"];
      if (tceppsProxyFoundRef.current) {
        customerName = tceppsProxyAvsNameRef.current;
        address1 = tceppsProxyAddress1Ref.current;
        address2 = tceppsProxyAddress2Ref.current;
        city = tceppsProxyCityRef.current;
        state = tceppsProxyStateRef.current;
        zip = tceppsProxyZipCodeRef.current;
      }

      if (!(email && email.length > 0)) {
        // email = DEFUSEREMAIL;
        email = "null@cybersource.com";
      } else {
        email = email.trim();
      }
      let countryCd = decryptParams["pno.customerCountryCode"];
      if (!(countryCd && countryCd.length > 0)) {
        countryCd = "US";
      }
      if (!address1) {
        address1 = address2;
        address2 = "";
      }

      let orbitalConnectionUsername = decryptParams["pno.orbitalConnectionUsername"];
      let orbitalConnectionPassword = decryptParams["pno.orbitalConnectionPassword"];
      let switchSoloIssueNum = decryptParams["pno.switchSoloIssueNum"];
      let switchSoloCardStartDate = decryptParams["pno.switchSoloCardStartDate"];
      let authenticationECIInd = decryptParams["pno.authenticationECIInd"];
      if (!orbitalConnectionUsername) {
        orbitalConnectionUsername = decryptParams["orbitalConnectionUsername"];
      }
      if (!orbitalConnectionPassword) {
        orbitalConnectionPassword = decryptParams["orbitalConnectionPassword"];
      }
      if (!switchSoloIssueNum) {
        switchSoloIssueNum = decryptParams["switchSoloIssueNum"];
      }
      if (!switchSoloCardStartDate) {
        switchSoloCardStartDate = decryptParams["switchSoloCardStartDate"];
      }
      if (!authenticationECIInd) {
        authenticationECIInd = decryptParams["authenticationECIInd"];
      }
      pno.captureContext.value = captureContextRef.current;
      pno.microformToken.value = microformTokenRef.current;

      if (developmentEnv) {
        let amount = "0";
        if (!address2) {
          address2 = "";
        }
        if (address1 || address2) {
          if ((address1 + address2).toLowerCase().indexOf("testdonothonorfailure") >= 0) {
            amount = "40100";
          }
          if ((address1 + address2).toLowerCase().indexOf("testaddressfailure") >= 0) {
            amount = "283600";
          }
          if ((address1 + address2).toLowerCase().indexOf("testcvvfailure") >= 0) {
            amount = "700500";
            // cvv = "111";
          }
          if ((address1 + address2).toLowerCase().indexOf("testinsufficientfund") >= 0) {
            amount = "250900"; //2509 2521
          }
          if ((address1 + address2).toLowerCase().indexOf("testinsufficientfund2") >= 0) {
            amount = "252100"; //2509 2521
          }

          if (amount !== "0") {
            pno.amount.value = amount;
          }
        }
      }
      pno.customerName.value = customerName ? customerName.toUpperCase().trim() : "";
      pno.customerAddress1.value = address1 ? address1.toUpperCase().trim() : "";
      pno.customerAddress2.value = address2 ? address2.toUpperCase().trim() : "";
      pno.customerCity.value = city ? city.toUpperCase().trim() : "";
      pno.customerState.value = state ? state.toUpperCase().trim() : "";
      pno.customerZIP.value = zip ? zip.toUpperCase() : "";
      pno.customerEmail.value = email;
      pno.customerCountryCode.value = countryCd ? countryCd.toUpperCase().trim() : countryCd;

      pno.orbitalConnectionUsername.value = orbitalConnectionUsername ? orbitalConnectionUsername : "";
      pno.orbitalConnectionPassword.value = orbitalConnectionPassword ? orbitalConnectionPassword : "";
      pno.switchSoloIssueNum.value = switchSoloIssueNum ? switchSoloIssueNum : "";
      pno.switchSoloCardStartDate.value = switchSoloCardStartDate ? switchSoloCardStartDate : "";
      pno.authenticationECIInd.value = authenticationECIInd ? authenticationECIInd : "";

      tcepps.serviceOperation.value = "newOrder";
      if (proxyUrl) {
        tcepps.proxyUrl.value = proxyUrl;
      }
      if (successUrl) {
        tcepps.successUrl.value = successUrl;
      }
      if (cancelUrl) {
        tcepps.cancelUrl.value = cancelUrl;
      }
      try {
        newOrder(tcepps, pno);
      } catch (err: any) {
        let errorMessage = "Call to submit form <newOrder> failed. " + err.message + ".";
        setValidationMessages(errorMessage);
        setTextAreaDisplay("block");
        componentObjRef.current.processingForm = false;
        LogService.logMessage(
          logErrorRef.current,
          LOGERRORLEVEL,
          "Call to submit form NewOrder failed:" + (err ? JSON.stringify(err.message) : "null")
        );
      }
    },
    [decryptParams]
  );

  const createCybersourceToken = useCallback(
    async (event: any) => {
      if (isCybersourceTimedout(toDay)) {
        microformTokenRef.current = getTimedoutDummyToken();
        const { token, jti, lastFourDigits } = getTimedoutDummyToken();
        dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
      } else {
        componentObjRef.current.cardMessage = "";

        const options = {
          expirationMonth: expirationMonthYearRef.current?.value.substring(0, 2),
          expirationYear: expirationMonthYearRef.current?.value.substring(3, 8),
        };

        // async function createMicroformToken(options: any) {
        await paymentInfo.microform.createToken(options, (err: Error, token: any) => {
          if (err) {
            let newErrMsg = "";
            // handle error
            // @ts-ignore
            switch (err.reason) {
              case "CREATE_TOKEN_NO_FIELDS_LOADED":
                newErrMsg = "991: Token creation error, no fields have been loaded." + err.message;
                break;
              case "CREATE_TOKEN_TIMEOUT":
                newErrMsg = "991: createToken call was unable to proceed." + err.message;
                break;
              case "CREATE_TOKEN_XHR_ERROR":
                newErrMsg = "991: Network error when attempting to create a token." + err.message;
                break;
              case "CREATE_TOKEN_NO_FIELDS":
                newErrMsg = "991: Data fields are unavailable for collection." + err.message;
                break;
              case "CREATE_TOKEN_VALIDATION_PARAMS":
                newErrMsg = "991: Issue with parameters supplied to createToken." + err.message;
                break;
              case "CREATE_TOKEN_VALIDATION_FIELDS":
                // @ts-ignore
                if (err.details && err.details.length > 0) {
                  // @ts-ignore
                  if (err.details.length > 1) {
                    // varCreditCardMessage = "Please enter a credit card number.";
                    // varCvvMessage = "Please check that your Credit card verification code is correct or contact your card issuer.";
                    newErrMsg =
                      "Please enter a credit card number. Please check that your Credit card verification code is correct or contact your card issuer.";
                    // @ts-ignore
                  } else if (err.details[0].location === "number") {
                    newErrMsg = "Please enter a credit card number.";
                    // varCreditCardMessage = newErrMsg;
                    // @ts-ignore
                  } else if (err.details[0].location === "securityCode") {
                    newErrMsg = "Please check that your Credit card verification code is correct or contact your card issuer.";
                    // varCvvMessage = newErrMsg;
                  } else {
                    newErrMsg = err.message;
                  }
                } else {
                  newErrMsg = err.message;
                }
                break;
              case "CREATE_TOKEN_VALIDATION_SERVERSIDE":
                newErrMsg = "991: Server-side validation rejects the createToken request." + err.message;
                break;
              case "CREATE_TOKEN_UNABLE_TO_START":
                newErrMsg = "991: Loaded field was able to handle the createToken request." + err.message;
                break;
              default:
                newErrMsg = "991: Unknown error:" + err.message;
                break;
            }
            componentObjRef.current.cardMessage = newErrMsg;
          } else {
            microformTokenRef.current = token;
            const { jti, lastFourDigits } = parseToken(token);
            dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
            //   dispatch(tokenCreatedAction(token));
          }
          checkCreditCard();
        });
      }
    },
    [paymentInfo.microform, dispatch, checkCreditCard]
  );

  // useEffect when new token is generated submit form
  useEffect(() => {
    if (!componentObjRef.current.processingForm && paymentInfo.token) {
      if ((document.getElementById("serviceOperation") as HTMLInputElement).value === "profileAdd" || serviceOperation === "profileAdd") {
        if (checkCreditCard()) {
          invokeProfileAdd(document.getElementById("ppa"), paymentInfo.token, document.getElementById("tcepps"));
        }
      }
    }
  }, [paymentInfo.token, checkCreditCard, invokeProfileAdd, serviceOperation]);

  const invokeTceppsServiceOperation = useCallback(
    async (serviceOperation: string, event: MessageEvent) => {
      // console.log("invokeTceppsServiceOperation....called " + serviceOperation);
      if (serviceOperation !== "") {
        // console.log("serviceOperation<" + serviceOperation + ">, addProfile<" + addProfile + ">");
        switch (serviceOperation) {
          case "profileAdd":
            setServiceOperation(serviceOperation);
            if (
              event.data &&
              (event.data.avsName ||
                event.data.address1 ||
                event.data.address2 ||
                event.data.city ||
                event.data.state ||
                event.data.zipCode)
            ) {
              tceppsProxyAvsNameRef.current = event.data.avsName ? event.data.avsName : "";
              tceppsProxyAddress1Ref.current = event.data.address1 ? event.data.address1 : "";
              tceppsProxyAddress2Ref.current = event.data.address2 ? event.data.address2 : "";
              tceppsProxyCityRef.current = event.data.city ? event.data.city : "";
              tceppsProxyStateRef.current = event.data.state ? event.data.state : "";
              tceppsProxyZipCodeRef.current = event.data.zipCode ? event.data.zipCode : "";
              tceppsProxyFoundRef.current = true;
            }
            // console.log("set serviceOperation<" + serviceOperation + ">");
            if (checkCreditCard()) {
              createCybersourceToken(event);
            }
            break;
          case "newOrder":
            if (
              event.data &&
              (event.data.avsName ||
                event.data.address1 ||
                event.data.address2 ||
                event.data.city ||
                event.data.state ||
                event.data.zipCode)
            ) {
              tceppsProxyAvsNameRef.current = event.data.avsName ? event.data.avsName : "";
              tceppsProxyAddress1Ref.current = event.data.address1 ? event.data.address1 : "";
              tceppsProxyAddress2Ref.current = event.data.address2 ? event.data.address2 : "";
              tceppsProxyCityRef.current = event.data.city ? event.data.city : "";
              tceppsProxyStateRef.current = event.data.state ? event.data.state : "";
              tceppsProxyZipCodeRef.current = event.data.zipCode ? event.data.zipCode : "";
              tceppsProxyFoundRef.current = true;
            }
            if (!componentObjRef.current.processingForm) {
              invokeNewOrder(document.getElementById("pno"), document.getElementById("tcepps"));
            }
            break;
        }
        // console.log("invokeTceppsServiceOperation....called " + serviceOperation);
      }
    },
    [createCybersourceToken, checkCreditCard, invokeNewOrder]
  );

  const windowMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (
        formReady &&
        event.data &&
        event.data.type &&
        event.data.type === "processTransaction" &&
        event.data.payload &&
        event.data.payload.length > 0
      ) {
        let windowsUrl = window.location.href;
        windowsUrl = windowsUrl.substring(0, windowsUrl.indexOf("/", 8));
        if (windowsUrl.indexOf(":", 8) > 1) {
          windowsUrl = windowsUrl.substring(0, windowsUrl.indexOf(":", 8));
        }
        if (windowsUrl.indexOf("//") > 1) {
          windowsUrl = windowsUrl.substring(windowsUrl.indexOf("//") + 2);
        }
        // console.log(event.data);
        if (event.origin.startsWith("https://") && event.origin.indexOf(windowsUrl) > 0 && event.data.type === "processTransaction") {
          invokeTceppsServiceOperation(event.data.payload, event);
        } else if (event.origin.startsWith("http://") && event.origin.indexOf(windowsUrl) > 0 && event.data.type === "processTransaction") {
          invokeTceppsServiceOperation(event.data.payload, event);
        } else {
          return;
        }
      } else if (
        event.data &&
        (event.data as any).data &&
        ((event.data as any).data as any).focusShift &&
        (event.data as any).event &&
        (event.data as any).event === "blur" &&
        event.currentTarget &&
        (event.currentTarget as any).name &&
        (event.currentTarget as any).name === "creditCardFormIFrame"
      ) {
        if (((event.data as any).data as any).focusShift === "next") {
          expirationMonthYearRef.current?.focus();
        } else if (((event.data as any).data as any).focusShift === "previous") {
          expirationMonthYearRef.current?.focus();
        }
      } else if (componentObjRef.current && event.data && (event.data as string).indexOf("/*cybs-telgram*/") >= 0) {
        const jsonObject = JSON.parse(event.data.replace("/*cybs-telgram*/", ""));
        if (
          jsonObject.data &&
          jsonObject.data.focusShift &&
          jsonObject.event &&
          jsonObject.event === "event_blur" &&
          event.currentTarget &&
          (event.currentTarget as any).name &&
          ((event.currentTarget as any).name === "Mainframe" || (event.currentTarget as any).name === "creditCardFormIFrame")
        ) {
          expirationMonthYearRef.current?.focus();
        }
      }
    },
    [invokeTceppsServiceOperation, formReady]
  );

  // useEffect for windows message
  useEffect(() => {
    // console.log("paymentInfo.isSuccessful:" + paymentInfo.isSuccessful);
    if (paymentInfo.isSuccessful) {
      window.addEventListener("message", windowMessageHandler, false);
    }

    // console.log("added addEventListener");

    return () => {
      // console.log("REMOVING window message listener");
      window.removeEventListener("message", windowMessageHandler);
    };
  }, [windowMessageHandler, paymentInfo.isSuccessful]);

  useEffect(() => {
    if (tceppsState.app && initializationComplete) {
      setFormReady(true);
      // setShowValidationMessages(false);
      setValidationMessages("");
      setTextAreaDisplay("none");
    }
  }, [tceppsState.app, initializationComplete]);

  // decrypt the input parameters
  const decryptInputParameterAsync = async (app: string, encryptedRequestKey: string, encryptedRequest: string) => {
    let decryptParamsResponse: any;

    try {
      const response = await PaymentService.getDecryptRequest(app, encryptedRequestKey, encryptedRequest);
      decryptParamsResponse = response.data;
    } catch (err: any) {
      // console.log(err);
      if (axios.isAxiosError(err)) {
        // Access to config, request, and response
        let errorMessage = INVALIDAXIOSMESSAGE + "call to getDecryptRequest failed. " + err.message + ".";
        setValidationMessages(errorMessage);
        setTextAreaDisplay("block");
      } else {
        // Just a stock error
        let errorMessage = "Error in getDecryptRequest. " + err.message + ".";
        setValidationMessages(errorMessage);
        setTextAreaDisplay("block");
      }
      LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "PaymentService.getDecryptRequest call failed. " + err.message);
      return false;
    }

    if (decryptParamsResponse && Object.keys(decryptParamsResponse).length > 0) {
      // check for proxy
      let iFrameUrl = document.referrer;
      if (iFrameUrl === undefined || iFrameUrl.trim().length === 0) {
        let proxyUrlParam = decryptParamsResponse["tcepps.proxyUrl"];
        let successUrlParam = decryptParamsResponse["tcepps.successUrl"];
        if (proxyUrlParam && proxyUrlParam.trim().length > 0) {
          setProxyUrl(proxyUrlParam);
        } else if (successUrlParam && successUrlParam.trim().length > 0) {
          setProxyUrl(successUrlParam);
        }
      }

      setDecryptParams(decryptParamsResponse);
      let inputExpirationDate = decryptParamsResponse["expirationDate"];
      if (inputExpirationDate && inputExpirationDate.length === 7) {
        setExpirationMonthYear(inputExpirationDate);
      }
      let customerRefNum = decryptParamsResponse["pno.customerRefNum"];
      if (customerRefNum && customerRefNum.length > 0) {
        customerRefNumRef.current = customerRefNum;
        setInfoVisble({ creditCardInfoHidden: true, orderInfoHidden: false });
        (document.querySelector("#creditCard") as HTMLFormElement).style.display = "none";
        (document.querySelector("#orderInfoVisible") as HTMLDivElement).style.display = "block";
      } else {
        setInfoVisble({ creditCardInfoHidden: false, orderInfoHidden: true });
        (document.querySelector("#creditCard") as HTMLFormElement).style.display = "block";
        (document.querySelector("#orderInfoVisible") as HTMLDivElement).style.display = "none";
        if (isTestingApp(decryptParamsResponse["app.TESTINGAPP"])) {
          developmentEnv = true;
          setExpirationMonthYear(TceppsTestData.ToBeAppTestData.S21CSA_ASCC["ppa.test.expirationMonthYear"]);
        }
      }
      orderIdRef.current = decryptParamsResponse["pno.orderID"];

      let amount = decryptParamsResponse["pno.amount"];
      if (amount) {
        origAmountRef.current = amount;
        amountRef.current = formatAmount(amount);
      }
      setValidationMessages("");
      setTextAreaDisplay("none");
      if (decryptParamsResponse["pno.orderID"]) {
        logErrorRef.current = "S21CSA_ASCC Order# " + decryptParamsResponse["pno.orderID"];
      } else {
        logErrorRef.current = "S21CSA_ASCC Name# " + decryptParamsResponse["ppa.customerName"];
      }
    } else {
      let errorMessage = "Customer details not available. Please close and retry.";
      setValidationMessages(errorMessage);
      setTextAreaDisplay("block");
      LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "Customer details not available. Please close and retry.");
    }
  };

  // useEffect if screen/page input parameters change. Dispatch state change
  useEffect(() => {
    const appKey0 = Buffer.from(appKey ? appKey : "", "base64");
    const appKey1 = appKey0.toString("utf8");
    const mydata = JSON.parse(appKey1);

    let iFrameUrl = document.referrer;
    if (iFrameUrl && iFrameUrl.trim().length > 0) {
      setProxyUrl(iFrameUrl);
    }

    let requestParameter = {
      app: "",
      token: "",
      sign: "",
      serviceOperation: "",
      encryptedRequest: "",
      encryptedRequestKey: "",
      enableNewCrCardFlag: "",
      addProfile: "",
      cpgmuiApiUrl: "",
      cpgmuiEnv: "",
      backgroundColor: "",
    };

    let proxyAction = false;

    // console.log("params:" + Object.keys(mydata));

    const getPortNumber = (inputUrl: string) => {
      const windowsUrl = window.location.href;
      const searchParams = windowsUrl.indexOf("//") + 2;
      const firstSlashIndex = windowsUrl.indexOf("/", searchParams);
      const colonIndex = windowsUrl.indexOf(":", searchParams);
      if (firstSlashIndex > 0 && colonIndex > 0) {
        return windowsUrl.substring(colonIndex + 1, firstSlashIndex);
      } else {
        return "";
      }
    };
    //find port if it exits
    const urlPortNumber = getPortNumber(window.location.href);
    // console.log("urlPortNumber<" + urlPortNumber + ">");

    Object.keys(mydata).forEach((key) => {
      // console.log("key<" + key + "> data:" + mydata[key]);
      switch (key) {
        case "tceppsProxy.app":
          proxyAction = true;
          requestParameter.app = mydata[key];
          break;
        case "tcepps.app":
          requestParameter.app = mydata[key];
          break;
        case "tcepps.token":
          requestParameter.token = mydata[key];
          break;
        case "tcepps.sign":
          requestParameter.sign = mydata[key];
          break;
        case "tceppsProxy.serviceOperation":
        case "tcepps.serviceOperation":
          requestParameter.serviceOperation = mydata[key];
          break;
        case "tcepps.encryptedRequest":
          requestParameter.encryptedRequest = mydata[key];
          break;
        case "tcepps.encryptedRequestKey":
          requestParameter.encryptedRequestKey = mydata[key];
          break;
        case "tcepps.enableNewCrCardFlag":
          requestParameter.enableNewCrCardFlag = mydata[key];
          break;
        case "tcepps.background-color":
          requestParameter.backgroundColor = mydata[key];
          if (topDivRef.current !== null) {
            topDivRef.current.style.backgroundColor = mydata[key];
            topDivRef.current.style.margin = "0";
          }
          break;
        case "tceppsProxy.addProfile":
        case "tcepps.addProfile":
          requestParameter.addProfile = mydata[key];
          break;
        case "cpgmuiApiUrl":
          requestParameter.cpgmuiApiUrl = mydata[key] + "/TCEPPS/jsp/tceppsHtmlFormInterface.jsp?tceppsApp=";
          if (urlPortNumber !== "") {
            let backendPortNumber = getPortNumber(requestParameter.cpgmuiApiUrl);
            // console.log("backendPortNumber<" + backendPortNumber + ">");
            if (backendPortNumber !== urlPortNumber) {
              requestParameter.cpgmuiApiUrl = requestParameter.cpgmuiApiUrl.replace(backendPortNumber, urlPortNumber);
            }
          }
          break;
        case "cpgmuiEnv":
          requestParameter.cpgmuiEnv = mydata[key];
          if (requestParameter.cpgmuiEnv.toLowerCase() === "development" || requestParameter.cpgmuiEnv.toLowerCase() === "qa") {
            developmentEnv = true;
          }
          break;
      }
    });
    requestParameter.cpgmuiApiUrl += requestParameter.app;
    setValidationMessages("");
    setTextAreaDisplay("none");

    if (!proxyAction) {
      // console.log("initializeTceppsAction:" + requestParameter.app);
      dispatch(
        tceppsActions.initializeTceppsAction({
          app: requestParameter.app,
          token: requestParameter.token,
          sign: requestParameter.sign,
          serviceOperation: requestParameter.serviceOperation,
          encryptedRequest: requestParameter.encryptedRequest,
          encryptedRequestKey: requestParameter.encryptedRequestKey,
          enableNewCrCardFlag: requestParameter.enableNewCrCardFlag,
          cpgmuiApiUrl: requestParameter.cpgmuiApiUrl,
          cpgmuiEnv: requestParameter.cpgmuiEnv,
          backgroundColor: requestParameter.backgroundColor,
        })
      );
    } else {
      // console.log("proxyAction:" + requestParameter.app);
      dispatch(
        tceppsProxyActions.initializeTceppsAction({
          app: requestParameter.app,
          serviceOperation: requestParameter.serviceOperation,
          addProfile: requestParameter.addProfile,
        })
      );
    }

    // if (developmentEnv && isDevUrl(window.location.href)) {
    //   setExpirationMonthYear(TceppsTestData.ToBeAppTestData.S21CSA_ASCC["ppa.test.expirationMonthYear"]);
    // }

    // decrypt the input parameters
    decryptInputParameterAsync(
      requestParameter.app.toUpperCase().trim(),
      requestParameter.encryptedRequestKey,
      requestParameter.encryptedRequest
    );
  }, [appKey, dispatch]);

  const resizeTextArea = () => {
    validationMessagesRef.current!.style.height = "auto";
    validationMessagesRef.current!.style.height = validationMessagesRef.current!.scrollHeight + "px";
  };

  useEffect(() => {
    resizeTextArea();
  }, [validationMessages]);

  // useEffect when form get loaded. setup microform
  useEffect(() => {
    const loadCybersourceJS = (captureContext: any, clientLibrary: string, clientLibraryIntegrity: string, callback: (cc: any) => any) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      // script.src = config.cybersource.microformJs;
      script.src = clientLibrary;
      script.integrity = clientLibraryIntegrity;
      script.crossOrigin = "anonymous";
      document.body.appendChild(script);
      script.onload = () => {
        callback(captureContext);
      };
    };

    const selectGivenCardType = (cardName: string, cardValid: boolean, cardEmpty: boolean) => {
      //		alert ('cardName: ' + cardName + ' cardValid: ' + cardValid + ' cardEmpty: ' + cardEmpty);

      let varCreditCardType = "";
      let detectedCard = false;
      if (cardName.length > 0) {
        switch (cardName) {
          case "VI":
          case "visa":
            varCreditCardType = "VI";
            detectedCard = true;
            break;
          case "MC":
          case "mastercard":
            varCreditCardType = "MC";
            detectedCard = true;
            break;
          case "AX":
          case "amex":
            varCreditCardType = "AX";
            detectedCard = true;
            break;
          case "DI":
          case "discover":
            // (document.querySelector("#creditCardType") as HTMLInputElement).value = "DI";
            varCreditCardType = "DI";
            detectedCard = true;
            break;
        }
      }
      setCreditCardType(varCreditCardType);

      let errMessage = "Credit Card# needs to be entered.";
      if (!detectedCard) {
        errMessage = "Please enter a visa or a mastercard or amex or discover card#.";
      } else if (cardEmpty) {
        errMessage = "Credit Card# needs to be entered.";
      } else if (!cardValid) {
        errMessage = "Please enter a valid Credit card#.";
      } else if (cardValid) {
        errMessage = "";
      }
      componentObjRef.current.cardMessage = errMessage.length === 0 ? "" : INVALIDCARDMESSAGE;
      setCreditcardValid(cardValid && detectedCard);
    };

    const initializeMicroform = async (captureContext: any) => {
      captureContextRef.current = captureContext;
      // @ts-ignore
      const flex = await new window.Flex(captureContext);
      const microform = await flex.microform({ styles: flexStyles });
      creditCardMicroNumber = await microform.createField("number", { placeholder: "Card Number *" });
      await creditCardMicroNumber.load("#creditCardNumber");
      dispatch(microformInitializedAction(microform));

      if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
        //add listener
        creditCardMicroNumber.on("change", function (data: any) {
          if (data.card && data.card.length > 0) {
            selectGivenCardType(data.card[0].name, data.valid, data.empty);
          } else {
            selectGivenCardType("", data.valid, data.empty);
          }
        });
        creditCardMicroNumber.on("load", function (data: any) {
          setInitializationComplete(true);
          creditCardMicroNumber.focus();
        });
        creditCardMicroNumber.on("focus", function (data: any) {
          (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "5px auto -webkit-focus-ring-color";
          if (isFirefox) {
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "1px solid deepskyblue";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outlineOffset = "2px";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1.5px solid blue";
          }
        });
        creditCardMicroNumber.on("blur", function (data: any) {
          (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "none";
          if (isFirefox) {
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outlineOffset = "0px";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1px solid #858585";
          }
        });
        creditCardMicroNumber.on("inputSubmitRequest", function (data: any) {
          expirationMonthYearRef.current?.focus();
        });
      }
    };

    // console.log("infoVisble.creditCardInfoVisible:" + infoVisble.creditCardInfoHidden);
    if (!infoVisble.creditCardInfoHidden && proxyUrl && proxyUrl.length > 0) {
      // console.log("iFrameUrl:" + iFrameUrl);
      PaymentService.getCaptureContext(extractDomain(proxyUrl))
        .then((resp) => {
          // const captureContext = resp.data.data;
          const captureContext = resp.data.captureContext;
          captureContextRef.current = captureContext;
          const { clientLibrary, clientLibraryIntegrity } = extractClientLibrary(resp.data.json);
          dispatch(contextLoadedAction(captureContext));
          // @ts-ignore
          if (!window.Flex) {
            loadCybersourceJS(captureContext, clientLibrary, clientLibraryIntegrity, initializeMicroform);
          } else {
            initializeMicroform(captureContext);
          }
          return () => dispatch(unmountAction);
        })
        .catch((err: Error | AxiosError) => {
          // console.log(err);
          let errorMessage = "";
          if (axios.isAxiosError(err)) {
            // Access to config, request, and response
            errorMessage = INVALIDAXIOSMESSAGE + "call to initialize microForm failed. " + err.message + ".";
          } else {
            // Just a stock error
            errorMessage = "Initialize microForm failed. " + err.message + ".";
          }
          setValidationMessages(errorMessage);
          setTextAreaDisplay("block");
          LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "Initialize microForm failed. " + err.message);
        });
    }
  }, [dispatch, infoVisble.creditCardInfoHidden, proxyUrl]);

  const onChangeHandler = (identifier: string, event: ChangeEvent & { target: HTMLInputElement | HTMLSelectElement }) => {
    switch (identifier) {
      case "expirationMonthYear":
        setExpirationMonthYear(event.target.value);
        break;
      case "creditCardType":
        setCreditCardType(event.target.value);
        break;
    }
  };

  const onBlurHandler = (identifier: string, event: FocusEvent & { target: HTMLInputElement | HTMLSelectElement }) => {
    switch (identifier) {
      case "expirationMonthYear":
        setExpirationMonthYear(event.target.value);
        break;
    }
  };
  const onKeyDownHandler = (identifier: string, event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Tab") {
      if (identifier === "expirationMonthYear" && event.shiftKey) {
        if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
          creditCardMicroNumber.focus();
        }
      } else if (identifier === "submit" && !event.shiftKey) {
        if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
          creditCardMicroNumber.focus();
        }
      }
    }
  };

  return (
    <>
      <div ref={topDivRef}>
        <form id="tcepps" name="tcepps" action={tceppsState.cpgmuiApiUrl} target="_self">
          <input id="app" name="app" type="hidden" value={tceppsState.app} />
          <input id="token" name="token" type="hidden" value={tceppsState.token} />
          <input id="sign" name="sign" type="hidden" value={tceppsState.sign} />
          <input id="serviceOperation" name="serviceOperation" type="hidden" value="" />
          <input id="encryptedRequest" name="encryptedRequest" type="hidden" value={tceppsState.encryptedRequest} />
          <input id="encryptedRequestKey" name="encryptedRequestKey" type="hidden" value={tceppsState.encryptedRequestKey} />
          <input id="lastFourDigits" name="lastFourDigits" type="hidden" value="" />
          <input id="proxyUrl" name="proxyUrl" type="hidden" value="" />
          <input id="successUrl" name="successUrl" type="hidden" value="" />
          <input id="cancelUrl" name="cancelUrl" type="hidden" value="" />
          <input id="errorMessage" name="errorMessage" type="hidden" value="" />
        </form>
        <form id="ppa" name="ppa">
          <input id="cardBrand" name="cardBrand" type="hidden" value="" />
          <input id="ccAccountNum" name="ccAccountNum" type="hidden" value="" />
          <input id="ccExp" name="ccExp" type="hidden" value="" />
          <input id="amount" name="amount" type="hidden" value="" />
          <input id="customerName" name="customerName" type="hidden" value="" />
          <input id="customerAddress1" name="customerAddress1" type="hidden" value="" />
          <input id="customerAddress2" name="customerAddress2" type="hidden" value="" />
          <input id="customerCity" name="customerCity" type="hidden" value="" />
          <input id="customerState" name="customerState" type="hidden" value="" />
          <input id="customerZIP" name="customerZIP" type="hidden" value="" />
          <input id="customerCountryCode" name="customerCountryCode" type="hidden" value="" />
          <input id="customerEmail" name="customerEmail" type="hidden" value="" />
          <input id="customerPhone" name="customerPhone" type="hidden" value="" />
          <input id="merchantID" name="merchantID" type="hidden" value="" />
          <input id="orbitalConnectionUsername" name="orbitalConnectionUsername" type="hidden" value="" />
          <input id="orbitalConnectionPassword" name="orbitalConnectionPassword" type="hidden" value="" />
          <input id="orderDefaultDescription" name="orderDefaultDescription" type="hidden" value="" />
          <input id="orderDefaultAmount" name="orderDefaultAmount" type="hidden" value="" />
          <input id="captureContext" name="captureContext" type="hidden" value="" />
          <input id="microformToken" name="microformToken" type="hidden" value="" />
        </form>
        <form id="pno" name="pno">
          <input id="merchantID" name="merchantID" type="hidden" value="" />
          <input id="customerRefNum" name="customerRefNum" type="hidden" value={customerRefNumRef.current} />
          <input id="amount" name="amount" type="hidden" value="" />
          <input id="orderID" name="orderID" type="hidden" value="" />
          <input id="addProfileFromOrder" name="addProfileFromOrder" type="hidden" value="A" />
          <input id="profileOrderOverideInd" name="profileOrderOverideInd" type="hidden" value="NO" />
          <input id="customerName" name="customerName" type="hidden" value="" />
          <input id="customerAddress1" name="customerAddress1" type="hidden" value="" />
          <input id="customerAddress2" name="customerAddress2" type="hidden" value="" />
          <input id="customerCity" name="customerCity" type="hidden" value="" />
          <input id="customerState" name="customerState" type="hidden" value="" />
          <input id="customerZIP" name="customerZIP" type="hidden" value="" />
          <input id="customerCountryCode" name="customerCountryCode" type="hidden" value="" />
          <input id="customerEmail" name="customerEmail" type="hidden" value="" />
          <input id="customerPhone" name="customerPhone" type="hidden" value="" />
          <input id="cardBrand" name="cardBrand" type="hidden" value="" />
          <input id="ccAccountNum" name="ccAccountNum" type="hidden" value="" />
          <input id="ccExp" name="ccExp" type="hidden" value="" />
          <input id="orbitalConnectionUsername" name="orbitalConnectionUsername" type="hidden" value="" />
          <input id="orbitalConnectionPassword" name="orbitalConnectionPassword" type="hidden" value="" />
          <input id="switchSoloIssueNum" name="switchSoloIssueNum" type="hidden" value="" />
          <input id="switchSoloCardStartDate" name="switchSoloCardStartDate" type="hidden" value="" />
          <input id="authenticationECIInd" name="authenticationECIInd" type="hidden" value="" />
          <input id="captureContext" name="captureContext" type="hidden" value="" />
          <input id="microformToken" name="microformToken" type="hidden" value="" />
        </form>
        <div className={`${classedCsaAscc.s21csaascc} ${classedCsaAscc.errorMessage}`} style={s21srDivTopStyle}>
          <div style={tableMainStyle}>
            <textarea
              id="errorMessage"
              tabIndex={-1}
              className={`${classedCsaAscc.s21csaascc} ${classedCsaAscc.errorMessage}`}
              style={{
                color: "red",
                border: "0",
                width: "95%",
                resize: "none",
                overflowY: "hidden",
                background: "white",
                display: `${textAreaDisplay}`,
              }}
              value={validationMessages}
              ref={validationMessagesRef}
              rows={1}
              cols={100}
              disabled={true}
              readOnly
            />
          </div>
          {infoVisble.creditCardInfoHidden}
          <form id="creditCard" name="creditCard" hidden={infoVisble.creditCardInfoHidden} style={{ display: "none" }}>
            <table style={tableStyle}>
              <colgroup>
                <col style={{ width: "50%" }} />
                <col style={{ width: "50%" }} />
              </colgroup>
              <tbody>
                <tr key={1}>
                  <td
                    width="220px"
                    align="left"
                    style={styletd}
                    className={`${classedCsaAscc.s21csaascc} ${classedCsaAscc.td} ${classedCsaAscc.outputText}`}
                  >
                    Credit Card Account Number
                  </td>
                  <td className={`${classedCsaAscc.s21csaascc} ${classedCsaAscc.td} ${classedCsaAscc.lpad}`}>
                    <div
                      id="creditCardNumber"
                      style={creditCardStyle}
                      tabIndex={-1}
                      autoFocus={true}
                      className={`${classedCsaAscc.s21csaascc} ${classedCsaAscc.creditcard} ${classedCsaAscc.inputFieldValid} ${classedCsaAscc.input}`}
                    ></div>
                  </td>
                </tr>

                <tr key={2}>
                  <td
                    align="left"
                    style={styletd}
                    className={`${classedCsaAscc.s21csaascc} ${classedCsaAscc.td} ${classedCsaAscc.outputText}`}
                  >
                    <label style={{ display: "block", textAlign: "left" }} htmlFor="creditCardType">
                      Credit Card Type
                    </label>
                  </td>
                  <td
                    style={{ width: "55%" }}
                    className={`${classedCsaAscc.s21csaascc} ${classedCsaAscc.td} ${classedCsaAscc.inputFieldValid}  ${classedCsaAscc.lpad}`}
                  >
                    <select
                      id="creditCardType"
                      name="creditCardType"
                      tabIndex={-1}
                      value={creditCardType}
                      disabled={creditCardTypeDisabled}
                      ref={creditCardTypeRef}
                      onChange={(event) => onChangeHandler("creditCardType", event)}
                      style={inputStyleCard}
                      className={`${classedCsaAscc.s21csaascc} ${classedCsaAscc.select}`}
                    >
                      <option value="" disabled={true}></option>
                      <option value="AX" disabled={true}>
                        AMEX
                      </option>
                      <option value="DI" disabled={true}>
                        DISCOVER
                      </option>
                      <option value="MC" disabled={true}>
                        MASTER
                      </option>
                      <option value="VI" disabled={true}>
                        VISA
                      </option>
                    </select>
                  </td>
                </tr>

                <tr key={3}>
                  <td align="left" className={`${classedCsaAscc.s21csaascc} ${classedCsaAscc.td} ${classedCsaAscc.outputText}`}>
                    <label style={{ display: "block", textAlign: "left" }} htmlFor="expirationMonthYear">
                      Card Expiration Date
                    </label>
                  </td>
                  <td
                    className={`${classedCsaAscc.s21csaascc} ${classedCsaAscc.td} ${classedCsaAscc.expiration} ${classedCsaAscc.inputFieldValid} ${classedCsaAscc.lpad}`}
                  >
                    <input
                      id="expirationMonthYear"
                      name="expirationMonthYear"
                      type="text"
                      size={7}
                      maxLength={7}
                      tabIndex={2}
                      value={expirationMonthYear}
                      disabled={expirationMonthYearDisabled}
                      ref={expirationMonthYearRef}
                      onChange={(event) => onChangeHandler("expirationMonthYear", event)}
                      onBlur={(event) => onBlurHandler("expirationMonthYear", event)}
                      onKeyUp={keyUpDateEvent}
                      onKeyDown={(event) => onKeyDownHandler("expirationMonthYear", event)}
                      style={inputStyle}
                      className={`${classedCsaAscc.s21csaascc} ${classedCsaAscc.input}`}
                    />
                    {" (MM/YYYY)"}
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <div hidden={infoVisble.orderInfoHidden} id="orderInfoVisible" style={{ display: "none" }}>
            <table id="orderInfo" hidden={infoVisble.orderInfoHidden} style={tableStyle}>
              <tbody>
                <tr>
                  <td align="left" className={`${classedCsaAscc.s21csaascc} ${classedCsaAscc.td} ${classedCsaAscc.outputText}`}>
                    Order ID:
                  </td>
                  <td align="left" className={`${classedCsaAscc.s21csaascc} ${classedCsaAscc.td} ${classedCsaAscc.outputText}`}>
                    {orderIdRef.current}
                  </td>
                </tr>
                <tr>
                  <td align="left" className={`${classedCsaAscc.s21csaascc} ${classedCsaAscc.td} ${classedCsaAscc.outputText}`}>
                    Amount:
                  </td>
                  <td align="left" className={`${classedCsaAscc.s21csaascc} ${classedCsaAscc.td} ${classedCsaAscc.outputText}`}>
                    {amountRef.current}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
