import type { Draft } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { InstlpayPocInterface, InstallPayCardInterface } from "../../interface/redux-state/InstlpayPocInterface";

const initializeUserCard: InstallPayCardInterface = {
  cardBrand: "",
  ccAccountNum: "",
  lastFourDigits: "",
  bin: "",
  ccExp: "",
  customerName: "",
  customerFatherName: "",
  customerMotherName: "",
  installments: "",
};

const initialState: InstlpayPocInterface = {
  loading: true,
  cardBrand: "",
  cardValid: false,
  cvvValid: false,
  cvvEmpty: false,
  ccExpMonth: "",
  ccExpYear: "",
  customerName: "",
  customerFatherName: "",
  customerMotherName: "",
  userCard: initializeUserCard,
  processing: false,
  installmentSubmitted: false,
  installment: "",
  cardChanged: false,
  binInstallments: " ",
  jwtTokenVerified: true,
  error: {
    ccExpExpired: false,
    customerNameMissing: false,
    customerNameMinLength: false,
    customerFatherNameMissing: false,
    customerFatherMinLength: false,
    creditCardMissing: false,
    creditCardInvalid: false,
    cvvMissing: false,
    cvvInvalid: false,
    installmentMissing: false,
  },
};

export const instlpayPocSlice = createSlice({
  name: "instlpaypoc",
  initialState,
  reducers: {
    resetStateAndError: (state: Draft<InstlpayPocInterface>) => {
      state.loading = initialState.loading;
      state.cardBrand = initialState.cardBrand;
      state.cardValid = initialState.cardValid;
      state.cvvValid = initialState.cvvValid;
      state.cvvEmpty = initialState.cvvEmpty;
      state.ccExpMonth = initialState.ccExpMonth;
      state.ccExpYear = initialState.ccExpYear;
      state.customerName = initialState.customerName;
      state.customerFatherName = initialState.customerFatherName;
      state.customerMotherName = initialState.customerMotherName;
      state.error = initialState.error;
      state.userCard = initialState.userCard;
      state.processing = initialState.processing;
      state.installmentSubmitted = initialState.installmentSubmitted;
      state.installment = initialState.installment;
      state.binInstallments = initialState.binInstallments;
      state.cardChanged = initialState.cardChanged;
    },
    changeStateError: (state: Draft<InstlpayPocInterface>, action) => {
      state.loading = action.payload.loading;
      state.cardBrand = action.payload.cardBrand;
      state.cardValid = action.payload.cardValid;
      state.cvvValid = action.payload.cvvValid;
      state.cvvEmpty = action.payload.cvvEmpty;
      state.ccExpMonth = action.payload.ccExpMonth;
      state.ccExpYear = action.payload.ccExpYear;
      state.customerName = action.payload.customerName;
      state.customerFatherName = action.payload.customerFatherName;
      state.customerMotherName = action.payload.customerMotherName;
      state.error = action.payload.error;
      state.userCard = action.payload.userCard;
      state.processing = action.payload.processing;
      state.installmentSubmitted = action.payload.installmentSubmitted;
      state.installment = action.payload.installment;
      state.binInstallments = action.payload.binInstallments;
      state.cardChanged = action.payload.cardChanged;
    },
    setCvv: (state: Draft<InstlpayPocInterface>, action) => {
      state.cvvValid = action.payload.cvvValid;
      state.cvvEmpty = action.payload.cvvEmpty;
      state.error.cvvInvalid = !action.payload.cvvValid;
      state.error.cvvMissing = !action.payload.cvvEmpty;
    },
    setCardType: (state: Draft<InstlpayPocInterface>, action) => {
      state.cardValid = action.payload.cardValid;
      state.cardBrand = action.payload.cardBrand;
      state.error.creditCardInvalid = action.payload.creditCardInvalid;
      state.error.creditCardMissing = action.payload.creditCardMissing;
      state.cardChanged = action.payload.cardChanged;
      state.installmentSubmitted = action.payload.installmentSubmitted;
      state.binInstallments = action.payload.binInstallments;
    },
    loading: (state: Draft<InstlpayPocInterface>, action) => {
      state.loading = action.payload;
    },
    updateError: (state: Draft<InstlpayPocInterface>, action) => {
      state.error = action.payload;
    },
    setProcessing: (state: Draft<InstlpayPocInterface>, action) => {
      state.processing = action.payload.processing;
    },
    resetForNewCard: (state: Draft<InstlpayPocInterface>, action) => {
      state.loading = initialState.loading;
      state.cardBrand = initialState.cardBrand;
      state.cardValid = initialState.cardValid;
      state.cvvValid = initialState.cvvValid;
      state.cvvEmpty = initialState.cvvEmpty;
      state.customerName = initialState.customerName;
      state.customerFatherName = initialState.customerFatherName;
      state.customerMotherName = initialState.customerMotherName;
      state.error = initialState.error;
      state.processing = initialState.processing;
      state.userCard = action.payload.userCard;
      state.ccExpMonth = action.payload.ccExpMonth;
      state.ccExpYear = action.payload.ccExpYear;
      state.installmentSubmitted = initialState.installmentSubmitted;
      state.installment = initialState.installment;
      state.binInstallments = initialState.binInstallments;
      state.cardChanged = initialState.cardChanged;
    },
    setInstallmentSubmitted: (state: Draft<InstlpayPocInterface>, action) => {
      state.installmentSubmitted = action.payload.installmentSubmitted;
    },
    setBinInstallments: (state: Draft<InstlpayPocInterface>, action) => {
      state.binInstallments = action.payload.binInstallments;
    },
    isJwtTokenVerified: (state: Draft<InstlpayPocInterface>, action) => {
      state.jwtTokenVerified = action.payload.jwtTokenVerified;
    },
  },
});

export const {
  resetStateAndError,
  changeStateError,
  setCvv,
  setCardType,
  loading,
  updateError,
  setProcessing,
  resetForNewCard,
  setInstallmentSubmitted,
  setBinInstallments,
  isJwtTokenVerified,
} = instlpayPocSlice.actions;

export default instlpayPocSlice;
