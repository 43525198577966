import "./S21CsaMyCsaStyles.css";
import React, { useState } from "react";
import { useCallback, useEffect, useRef } from "react";
import PaymentService from "../../service/paymentService";
import {
  changeAction,
  errorAction,
  creditCardFocusAction,
  cardTypeAction,
  loadingAction,
} from "../../redux-store/reducer/s21CsaMyCsaSlice";
import { tokenCreatedAction, microformInitializedAction, contextLoadedAction } from "../../redux-store/reducer/paymentSlice";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosError } from "axios";
import {
  extractClientLibrary,
  getCardType,
  loadCybersourceJS,
  classNames,
  extractRequestParameters,
  parseToken,
  getCreateTokenError,
  extractDomain,
} from "../../utils/utils";
import { S21CsaMyCsaInterface } from "../../interface/redux-state/S21CsaMyCsaInterface";
import { useParams } from "react-router-dom";
import { tceppsActions } from "../../redux-store/reducer/tcepps_slice";
import { LogService, LOGERRORLEVEL } from "../../service/logService";
// import { profileAdd } from "../../utils/cpgmuiUtil";
import dayjs from "dayjs";
import { TceppsTestData, isTestingApp } from "../../utils/TestLoadData";
import { isCybersourceTimedout, getTimedoutDummyToken, keyUpDateEvent } from "../../utils/calendarUtil";
import CpgmuiService from "../../service/CpgmuiService";

const flexStyles = {
  input: {
    cursor: "text",
    color: "#171719",
  },
  "::placeholder": { color: "lightslategrey" },
  ":focus": { color: "black" },
  ":disabled": { cursor: "not-allowed" },
  valid: { color: "#171719" },
  invalid: { color: "#a94442" },
  // ":hover": {
  //   "font-style": "italic",
  // },
} as React.CSSProperties;

const SUBMIT_MESSAGE_TYPE = "processTransaction";

const toDay = new Date();

export function S21CsaMyCsaComponent() {
  const s21CsaMyCsaState = useSelector((state: any) => state.s21csamycsa) as S21CsaMyCsaInterface;
  const paymentInfo = useSelector((state: any) => state.paymentInfo);
  const tceppsState = useSelector((state: any) => state.tcepps);
  const creditCardMicroNumberRef = useRef(null);
  const tceppsProxyAvsNameRef = useRef<string>("");
  const tceppsProxyAddress1Ref = useRef<string>("");
  const tceppsProxyAddress2Ref = useRef<string>("");
  const tceppsProxyCityRef = useRef<string>("");
  const tceppsProxyStateRef = useRef<string>("");
  const tceppsProxyZipCodeRef = useRef<string>("");
  const tceppsProxyCountryCodeRef = useRef<string>("US");
  const tceppsProxyFoundRef = useRef<boolean>(false);
  const logErrorRef = useRef<string>("S21CSAMYCSA Not initialized");
  const captureContextRef = useRef<string>("");
  const microformTokenRef = useRef<any>(null);
  const dispatch = useDispatch();
  const [loadedTestData, setLoadedTestData] = useState(false);
  const [proxyUrl, setProxyUrl] = useState("");
  const [corsUrls, setCorsUrls] = useState("");

  const { appKey } = useParams();

  // load request parameters
  useEffect(() => {
    const processRequest = async (req: any) => {
      const resp = await PaymentService.getDecryptRequest(req.app, req.encryptedRequestKey, req.encryptedRequest);
      req.unencryptedRequest = resp.data;
      dispatch(tceppsActions.initializeTceppsAction(req));

      return () => {
        dispatch(tceppsActions.resetTceppsAction());
      };
    };

    const requestParameters = extractRequestParameters(appKey || "");
    processRequest(requestParameters).then(() => {
      // console.log( 'requestParameters = ' + JSON.stringify(requestParameters));
    });
  }, [dispatch, appKey]);

  type Microform = {
    microform: (options: any) => any;
  };

  const captureTokenInformation = useCallback(() => {
    const validateCardType = () => {
      let isGood = true;
      if (!s21CsaMyCsaState.creditCardType) {
        isGood = false;
        dispatch(errorAction("Please enter a valid Credit Card Number."));
      } else if (!s21CsaMyCsaState.cardValid) {
        isGood = false;
        dispatch(errorAction("Please enter a Visa or a Mastercard or amex or Discover card number."));
      } else {
        dispatch(errorAction(""));
      }
      return isGood;
    };
    const validateDate = (cardExpirationDate: string) => {
      let isGood = true;
      if (cardExpirationDate) {
        if (cardExpirationDate.length === 7) {
          const expirationMonth = Number(cardExpirationDate.substring(0, 2));
          const expirationYear = Number(cardExpirationDate.substring(3, 8));
          const currentMonth = dayjs().month() + 1;
          const currentYear = dayjs().year();
          if (currentMonth > 0 && currentMonth < 13) {
            if (
              !expirationYear ||
              !expirationMonth ||
              currentYear > expirationYear ||
              (currentYear === expirationYear && currentMonth > expirationMonth)
            ) {
              isGood = false;
              dispatch(errorAction("The credit card date is expired."));
            }
          } else {
            isGood = false;
            dispatch(errorAction("Expiration month is invalid"));
          }
        } else {
          isGood = false;
          dispatch(errorAction("Please enter a valid credit card month and date"));
        }
      } else {
        isGood = false;
        dispatch(errorAction("Please enter a valid Card Expiration Date."));
      }
      return isGood;
    };
    const { cardExpirationDate } = s21CsaMyCsaState;
    if (validateDate(cardExpirationDate) && validateCardType()) {
      if (isCybersourceTimedout(toDay)) {
        microformTokenRef.current = getTimedoutDummyToken();
        const { token, jti, lastFourDigits } = getTimedoutDummyToken();
        dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
      } else {
        const options = {
          expirationMonth: cardExpirationDate.substring(0, 2),
          expirationYear: cardExpirationDate.substring(3, 8),
        };
        paymentInfo.microform.createToken(options, (err: Error, token: any) => {
          if (err) {
            dispatch(errorAction(getCreateTokenError(err)));
          } else {
            microformTokenRef.current = token;
            // this will trigger the "submit" useEffect
            const { jti, lastFourDigits } = parseToken(token);
            dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
          }
        });
      }
    }
  }, [dispatch, paymentInfo.microform, s21CsaMyCsaState]);

  const buildProfileAddRequest = useCallback(
    (tcepps: any, ppa: any) => {
      return {
        "tcepps.app": "S21CSA_MYCSA",
        "tcepps.token": tcepps.token.value,
        "tcepps.sign": tcepps.sign.value,
        "tcepps.serviceOperation": "profileAdd",
        "tcepps.proxyUrl": proxyUrl,
        "tcepps.encryptedRequest": tcepps.encryptedRequest.value,
        "tcepps.encryptedRequestKey": tcepps.encryptedRequestKey.value,
        "tcepps.lastFourDigits": tcepps.lastFourDigits.value,
        "ppa.cardBrand": ppa.cardBrand.value,
        "ppa.ccAccountNum": ppa.ccAccountNum.value,
        "ppa.ccExp": ppa.ccExp.value,
        "ppa.customerName": tceppsProxyFoundRef.current
          ? tceppsProxyAvsNameRef.current
            ? tceppsProxyAvsNameRef.current
            : ""
          : ppa.customerName.value,
        "ppa.customerAddress1": tceppsProxyFoundRef.current
          ? tceppsProxyAddress1Ref.current
            ? tceppsProxyAddress1Ref.current
            : ""
          : ppa.customerAddress1.value,
        "ppa.customerAddress2": tceppsProxyFoundRef.current
          ? tceppsProxyAddress2Ref.current
            ? tceppsProxyAddress2Ref.current
            : ""
          : ppa.customerAddress2.value,
        "ppa.customerCity": tceppsProxyFoundRef.current
          ? tceppsProxyCityRef.current
            ? tceppsProxyCityRef.current
            : ""
          : ppa.customerCity.value,
        "ppa.customerState": tceppsProxyFoundRef.current
          ? tceppsProxyStateRef.current
            ? tceppsProxyStateRef.current
            : ""
          : ppa.customerState.value,
        "ppa.customerZIP": tceppsProxyFoundRef.current
          ? tceppsProxyZipCodeRef.current
            ? tceppsProxyZipCodeRef.current
            : ""
          : ppa.customerZIP.value,
        "ppa.customerCountryCode": tceppsProxyFoundRef.current
          ? tceppsProxyCountryCodeRef.current
            ? tceppsProxyCountryCodeRef.current
            : ""
          : ppa.customerCountryCode.value,
        "ppa.customerEmail": "null@cybersource.com",
        "ppa.captureContext": captureContextRef.current,
        "ppa.microformToken": microformTokenRef.current,
      };
    },
    [proxyUrl]
  );

  const submitProfileAdd = useCallback(() => {
    const tcepps = document.getElementsByName("tcepps")[0];
    const ppa: any = document.getElementsByName("ppa")[0];
    if (tceppsProxyFoundRef.current) {
      ppa.customerName.value = tceppsProxyAvsNameRef.current ? tceppsProxyAvsNameRef.current : "";
      ppa.customerAddress1.value = tceppsProxyAddress1Ref.current ? tceppsProxyAddress1Ref.current : "";
      ppa.customerAddress2.value = tceppsProxyAddress2Ref.current ? tceppsProxyAddress2Ref.current : "";
      ppa.customerCity.value = tceppsProxyCityRef.current ? tceppsProxyCityRef.current : "";
      ppa.customerState.value = tceppsProxyStateRef.current ? tceppsProxyStateRef.current : "";
      ppa.customerZIP.value = tceppsProxyZipCodeRef.current ? tceppsProxyZipCodeRef.current : "";
      ppa.customerCountryCode.value = tceppsProxyCountryCodeRef.current ? tceppsProxyCountryCodeRef.current : "";
      ppa.customerEmail.value = "null@cybersource.com";
    }
    const profileAddRequest = buildProfileAddRequest(tcepps, ppa);
    CpgmuiService.submitServiceOperationRest(profileAddRequest).then((resp: any) => {
      // console.log(resp.data);
      window.parent.postMessage({ type: "transactionResponse", payload: resp.data }, "*");
    });
  }, [buildProfileAddRequest]);

  //submit
  useEffect(() => {
    if (paymentInfo.token) {
      submitProfileAdd();
    }
  });

  const windowMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (event.data.type === SUBMIT_MESSAGE_TYPE) {
        if (
          (event.origin.startsWith("https://") &&
            (event.origin.indexOf(".usa.canon.com") > 0 ||
              event.origin.indexOf(".cusa.canon.com") > 0 ||
              event.origin.indexOf(".csa.canon.com") > 0)) ||
          (event.origin.startsWith("http://") && event.origin.indexOf("localhost") > 0)
        ) {
          if (
            event.data.payload &&
            (event.data.payload.avsName ||
              event.data.payload.address1 ||
              event.data.payload.address2 ||
              event.data.payload.city ||
              event.data.payload.state ||
              event.data.payload.zipCode)
          ) {
            tceppsProxyAvsNameRef.current = event.data.payload.avsName ? event.data.payload.avsName : "";
            tceppsProxyAddress1Ref.current = event.data.payload.address1 ? event.data.payload.address1 : "";
            tceppsProxyAddress2Ref.current = event.data.payload.address2 ? event.data.payload.address2 : "";
            tceppsProxyCityRef.current = event.data.payload.city ? event.data.payload.city : "";
            tceppsProxyStateRef.current = event.data.payload.state ? event.data.payload.state : "";
            tceppsProxyZipCodeRef.current = event.data.payload.zipCode ? event.data.payload.zipCode : "";
            tceppsProxyCountryCodeRef.current = event.data.payload.countryCode ? event.data.payload.countryCode : "";
            tceppsProxyFoundRef.current = true;
          }
          captureTokenInformation();
        }
      }
    },
    [captureTokenInformation]
  );

  // useEffect if screen/page input parameters change
  useEffect(() => {
    const decryptInputParameterAsync = async (app: string, encryptedRequestKey: string, encryptedRequest: string) => {
      let decryptParamsResponse: any;

      try {
        const response = await PaymentService.getDecryptRequest(app, encryptedRequestKey, encryptedRequest);
        decryptParamsResponse = response.data;
        logErrorRef.current = "S21CSAMYCSA Name# " + decryptParamsResponse["ppa.customerName"];
        setProxyUrl(decryptParamsResponse["tcepps.proxyUrl"]);
        setCorsUrls(decryptParamsResponse["tcepps.corsUrls"]);
      } catch (err: any) {
        LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "Customer details not available.");
        return false;
      }

      if (decryptParamsResponse && Object.keys(decryptParamsResponse).length > 0) {
        //don't save decrypted parameters
        // setDecryptParams(decryptParamsResponse);
        if (isTestingApp(decryptParamsResponse["app.TESTINGAPP"])) {
          let newState = {
            ...s21CsaMyCsaState,
            cardExpirationDate: TceppsTestData.ToBeAppTestData.S21CSA_MYCSA["ppa.test.expirationMonthYear"],
          };
          dispatch(changeAction(newState));
        }
      }
    };

    if (tceppsState.app !== undefined && tceppsState.app.length > 0 && !loadedTestData) {
      try {
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.border = "0";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.margin = "0";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.padding = "0";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.position = "relative";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.overflow = "auto";
        (document.querySelector("#root") as HTMLDivElement).style.border = "0";
        (document.querySelector("#body") as HTMLDivElement).style.border = "0";
        (document.querySelector("#root") as HTMLDivElement).style.margin = "0";
        (document.querySelector("#body") as HTMLDivElement).style.margin = "0";
        (document.querySelector("#root") as HTMLDivElement).style.padding = "0";
        (document.querySelector("#body") as HTMLDivElement).style.padding = "0";
      } catch (exception) {}

      decryptInputParameterAsync(tceppsState.app, tceppsState.encryptedRequestKey, tceppsState.encryptedRequest);
      setLoadedTestData(true);
    }
  }, [tceppsState.app, tceppsState.encryptedRequestKey, tceppsState.encryptedRequest, dispatch, s21CsaMyCsaState, loadedTestData]);

  useEffect(() => {
    // setCollapsed(true);
    window.addEventListener("message", windowMessageHandler, false);

    return () => {
      window.removeEventListener("message", windowMessageHandler);
    };
  }, [windowMessageHandler]);

  //* setup microform...
  useEffect(() => {
    const updateCardType = (cardName: string, isValid: boolean, isEmpty: boolean) => {
      const cardType = getCardType(cardName);
      if (!isEmpty) {
        if (!cardType) {
          dispatch(errorAction("Please enter a visa or a mastercard or amex or discover card number."));
        } else {
          dispatch(errorAction(""));
        }
      }
      dispatch(cardTypeAction({ creditCardType: cardType || "", cardValid: isValid ? "Y" : "N" }));
    };

    const initializeMicroform = async (captureContext: any) => {
      captureContextRef.current = captureContext;
      const flex = (await new (window as any).Flex(captureContext)) as Microform;
      const microform = await flex.microform({ styles: flexStyles });
      const creditCardMicroNumber = await microform.createField("number", { placeholder: "" });
      creditCardMicroNumberRef.current = creditCardMicroNumber;
      await creditCardMicroNumber.load("#creditCardNumber");
      dispatch(microformInitializedAction(microform));

      if (creditCardMicroNumber) {
        creditCardMicroNumber.on("change", (data: any) => {
          if (data.card && data.card.length > 0) {
            updateCardType(data.card[0].name, data.valid, data.empty);
          } else {
            updateCardType("", data.valid, data.empty);
          }
        });
        creditCardMicroNumber.on("focus", function (data: any) {
          dispatch(creditCardFocusAction(true));
        });
        creditCardMicroNumber.on("blur", function (data: any) {
          dispatch(creditCardFocusAction(false));
        });
        creditCardMicroNumber.on("load", function (data: any) {
          dispatch(loadingAction(false));
          creditCardMicroNumber.focus();
        });
      }
    };

    const getCaptureContext = () => {
      // const iframeUrl = getIframeUrl();
      const iframeUrl = extractDomain(proxyUrl);
      let iframeCorsUrls;
      if (corsUrls && corsUrls.indexOf(",") < 0) {
        iframeCorsUrls = extractDomain(corsUrls);
      } else {
        iframeCorsUrls = corsUrls;
      }
      PaymentService.getCaptureContextWithCorsUrl(iframeUrl, iframeCorsUrls)
        .then((resp) => {
          // const captureContext = resp.data.data;
          const captureContext = resp.data.captureContext;
          captureContextRef.current = captureContext;
          const { clientLibrary, clientLibraryIntegrity } = extractClientLibrary(resp.data.json);
          dispatch(contextLoadedAction(captureContext));
          if (!(window as any).Flex) {
            loadCybersourceJS(captureContext, clientLibrary, clientLibraryIntegrity, initializeMicroform);
          } else {
            initializeMicroform(captureContext);
          }
        })
        .catch((err: Error | AxiosError) => {
          if (axios.isAxiosError(err)) {
            dispatch(errorAction("AxiosError: call to initialize microForm failed. " + err.message + ". Please check console."));
          } else {
            dispatch(errorAction("Initialize microForm failed. " + err.message + ". Please check console."));
          }
          LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "Initialize microForm failed. " + err.message);
        });
    };

    if (proxyUrl && proxyUrl.length > 0) {
      getCaptureContext();
    }
  }, [dispatch, proxyUrl, corsUrls]);

  const handleChange = (event: any) => {
    const field = event.target.name as string;
    const changedState: { [index: string]: string | boolean } = { ...s21CsaMyCsaState };
    changedState[field] = event.target.value;
    dispatch(changeAction(changedState));

    if (field === "cardExpirationDate") {
      dispatch(errorAction(""));
    }
  };

  const getPpaValue = (obj: any, key: string) => {
    if (obj && obj[key]) {
      return obj[key];
    }
    return "";
  };

  const creditCardStyle = {
    fontSize: "16px",
    fontFamily: "Proxima Nova",
    paddingLeft: "2px",
    // paddingRight: "-20px",
    height: "20px",
    color: "fieldtext",
    letterSpacing: "normal",
    wordSpacing: "normal",
    lineHeight: "normal",
    textIndent: "0px",
    textShadow: "none",
    display: "inline-block",
    cursor: "text",
    backgroundColor: "#ffff00",
    width: "163px",
    margin: "0em",
    borderWidth: "1px",
    borderStyle: "inset",
    borderColor: "rgb(133, 133, 133)",
    borderImage: "initial",
  } as React.CSSProperties;

  return (
    <div className="s21csamyca">
      {s21CsaMyCsaState.error && <div className="error">{s21CsaMyCsaState.error}</div>}
      <form id="tcepps" name="tcepps" action={tceppsState.cpgmuiApiUrl} target="_self">
        <input id="app" name="app" type="hidden" value={tceppsState.app} />
        <input id="token" name="token" type="hidden" value={tceppsState.token} />
        <input id="sign" name="sign" type="hidden" value={tceppsState.sign} />
        <input id="serviceOperation" name="serviceOperation" type="hidden" value="profileAdd" />
        <input id="encryptedRequest" name="encryptedRequest" type="hidden" value={tceppsState.encryptedRequest} />
        <input id="encryptedRequestKey" name="encryptedRequestKey" type="hidden" value={tceppsState.encryptedRequestKey} />
        <input id="lastFourDigits" name="lastFourDigits" type="hidden" value={paymentInfo.lastFourDigits || ""} />
      </form>
      <form id="ppa" name="ppa">
        <input id="cardBrand" name="cardBrand" type="hidden" value={s21CsaMyCsaState.creditCardType || ""} />
        <input id="ccAccountNum" name="ccAccountNum" type="hidden" value={"TT=" + (paymentInfo.jti || "")} />
        <input id="captureContext" name="captureContext" type="hidden" value="" />
        <input id="microformToken" name="microformToken" type="hidden" value="" />
        <input
          id="ccExp"
          name="ccExp"
          type="hidden"
          value={
            s21CsaMyCsaState.cardExpirationDate
              ? s21CsaMyCsaState.cardExpirationDate.substring(3, 8) + s21CsaMyCsaState.cardExpirationDate.substring(0, 2)
              : ""
          }
        />
        <input
          id="customerName"
          name="customerName"
          type="hidden"
          value={getPpaValue(tceppsState.unencryptedRequest, "ppa.customerName")}
        />
        <input
          id="customerAddress1"
          name="customerAddress1"
          type="hidden"
          value={getPpaValue(tceppsState.unencryptedRequest, "ppa.customerAddress1")}
        />
        <input
          id="customerAddress2"
          name="customerAddress2"
          type="hidden"
          value={getPpaValue(tceppsState.unencryptedRequest, "ppa.customerAddress2")}
        />
        <input
          id="customerCity"
          name="customerCity"
          type="hidden"
          value={getPpaValue(tceppsState.unencryptedRequest, "ppa.customerCity")}
        />
        <input
          id="customerState"
          name="customerState"
          type="hidden"
          value={getPpaValue(tceppsState.unencryptedRequest, "ppa.customerState")}
        />
        <input id="customerZIP" name="customerZIP" type="hidden" value={getPpaValue(tceppsState.unencryptedRequest, "ppa.customerZIP")} />
        <input
          id="customerCountryCode"
          name="customerCountryCode"
          type="hidden"
          value={getPpaValue(tceppsState.unencryptedRequest, "ppa.customerCountryCode")}
        />
        <input id="customerEmail" name="customerEmail" type="hidden" value={"null@cybersource.com"} />
      </form>
      <table>
        <tbody>
          <tr>
            <td>Credit Card Number</td>
            <td>
              <div
                className={classNames({
                  inputFocused: s21CsaMyCsaState.creditCardHasFocus,
                  inputNotFocused: !s21CsaMyCsaState.creditCardHasFocus,
                })}
              >
                <div
                  id="creditCardNumber"
                  style={creditCardStyle}
                  tabIndex={1}
                  // aria-disabled={creditCardNumberDisabled}
                ></div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Credit Card Type</td>
            <td>
              <select
                id="creditCardType"
                name="creditCardType"
                tabIndex={-1}
                onChange={handleChange}
                disabled={true}
                value={s21CsaMyCsaState.creditCardType}
              >
                <option disabled={s21CsaMyCsaState.creditCardType !== ""} value=""></option>
                <option disabled={s21CsaMyCsaState.creditCardType !== "amex"} value="AX">
                  American Express
                </option>
                <option disabled={s21CsaMyCsaState.creditCardType !== "discover"} value="DI">
                  Discover
                </option>
                <option disabled={s21CsaMyCsaState.creditCardType !== "mastercard"} value="MC">
                  Mastercard
                </option>
                <option disabled={s21CsaMyCsaState.creditCardType !== "visa"} value="VI">
                  Visa
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Card Expiration Date</td>
            <td>
              <input
                className="expDate"
                id="cardExpirationDate"
                name="cardExpirationDate"
                type="text"
                tabIndex={2}
                size={7}
                maxLength={7}
                value={s21CsaMyCsaState.cardExpirationDate}
                onChange={handleChange}
                onKeyUp={keyUpDateEvent}
              />{" "}
              (MM/YYYY)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
