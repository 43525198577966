import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isFirefox } from "react-device-detect";
import axios, { AxiosError } from "axios";

import {
  decryptParamType,
  getCurrentYearMonth,
  isCybersourceTimedout,
  getTimedoutDummyToken,
  keyUpDateEvent,
} from "../../utils/calendarUtil";
import { TceppsTestData, isTestingApp } from "../../utils/TestLoadData";

import { tceppsActions } from "../../redux-store/reducer/tcepps_slice";
import { tceppsProxyActions } from "../../redux-store/reducer/tceppsProxy_slice";
import { contextLoadedAction, microformInitializedAction, tokenCreatedAction, unmountAction } from "../../redux-store/reducer/paymentSlice";
import PaymentService from "../../service/paymentService";

import classesSR from "./S21SrComponent.module.css";
import { extractClientLibrary, extractDomain, parseToken } from "../../utils/utils";
import { componentVarObject, ComponentVarProps } from "../../utils/ComponentConstant";

import { profileAdd, validationError } from "../../resources/creditCardFormPage/js/tcepps";

var Buffer = require("buffer/").Buffer; // note: the trailing slash is important!

let developmentEnv = false;
var creditCardMicroNumber: any;

const INVALIDAXIOSMESSAGE = "AxiosError. ";
const INVALIDCARDMESSAGE = "[Invalid Credit Card Number.]";
const INVALIDDATEMESSAGE = "[Invalid Expiry Date.]";

const DATEPATTERN = "^((0[1-9])|(1[0-2]))/([2-9][0-9][0-9][0-9])$";

const styletd = {
  align: "left",
  margin: 0,
  padding: 0,
  numberOfLines: 1,
} as React.CSSProperties;
const s21srDivTopStyle = {
  // height: "100%",
  width: "100%",
  // height: "93px",
  // width: "360px",
  margin: "0px",
  position: "relative",
  overflow: "auto",
  // display: "flex !important",
  // justifyContent: "flex-start",
} as React.CSSProperties;
const inputStyle = {
  fontSize: "8pt",
  margin: "0px",
  padding: "0px",
  fontFamily: "'Arial',sans-serif",
} as React.CSSProperties;
const inputStyleCard = {
  fontSize: "8pt",
  margin: "0px",
  padding: "0px",
  fontFamily: "'Arial',sans-serif",
  color: "grey",
} as React.CSSProperties;
const tableStyle = {
  border: "0",
  cellspacing: "1",
  fontSize: "8pt",
  margin: "0px",
  padding: "0px",
  fontFamily: "'Arial',sans-serif",
  align: "left",
  display: "flex",
  tableLayout: "fixed",
  borderTopWidth: "0px",
  borderBottomWidth: "0px",
  borderLeftWidth: "0px",
  borderRightWidth: "0px",
  borderHorizontalSpacing: "1px",
  borderVerticalSpacing: "1px",
} as React.CSSProperties;
const creditCardStyle = {
  fontSize: "8pt",
  fontFamily: "'Arial',sans-serif",
  height: "17px",
  width: "120px",
  margin: "0",
  padding: "0",
  color: "fieldtext",
  letterSpacing: "normal",
  wordSpacing: "normal",
  lineHeight: "normal",
  textIndent: "0px",
  textShadow: "none",
  display: "inline-block",
  cursor: "text",
  backgroundColor: "field",
  border: "1px solid #858585",
  borderImage: "initial",
} as React.CSSProperties;
const flexStyles = {
  input: {
    "font-size": "8pt",
    "font-family": "'Arial',sans-serif",
    cursor: "text",
  },
  "::placeholder": { color: "lightslategrey" },
  ":focus": { color: "black" },
  ":disabled": { cursor: "not-allowed" },
  valid: { color: "black" },
  invalid: { color: "#a94442" },
  // ":hover": {
  //   "font-style": "italic",
  // },
} as React.CSSProperties;

const toDay = new Date();

export function S21SrComponent(this: any) {
  const dispatch = useDispatch();
  const tceppsState = useSelector((state: any) => state.tcepps);
  const paymentInfo = useSelector((state: any) => state.paymentInfo);

  const [creditCardType, setCreditCardType] = useState("");
  const [expirationMonthYear, setExpirationMonthYear] = useState("");
  const [serviceOperation, setServiceOperation] = useState("");

  const [creditCardTypeDisabled, setCreditCardTypeDisabled] = useState(false);
  const [expirationMonthYearDisabled, setExpirationMonthYearDisabled] = useState(false);

  const creditCardTypeRef = useRef<HTMLSelectElement>(null);
  const expirationMonthYearRef = useRef<HTMLInputElement>(null);
  const topDivRef = useRef<HTMLDivElement>(null);
  const captureContextRef = useRef<string>("");
  const microformTokenRef = useRef<any>(null);

  const componentObjRef = useRef<ComponentVarProps>(componentVarObject);

  const [expirationMonthYearClassName, setExpirationMonthYearClassName] = useState("");

  const [creditcardValid, setCreditcardValid] = useState(false);
  const [initializationComplete, setInitializationComplete] = useState(false);
  const [formReady, setFormReady] = useState(false);

  const [decryptParams, setDecryptParams] = useState([] as decryptParamType);
  const [proxyUrl, setProxyUrl] = useState("");

  const { appKey } = useParams();

  // useEffect if screen/page input parameters change
  useEffect(() => {
    if (tceppsState.app !== undefined && tceppsState.app.length > 0 && !componentObjRef.current.tceppsStateChanged) {
      try {
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.border = "0";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.margin = "0";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.padding = "0";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.position = "relative";
        ((document.querySelector("#root") as HTMLDivElement).parentElement as HTMLDivElement).style.overflow = "auto";
        (document.querySelector("#root") as HTMLDivElement).style.border = "0";
        (document.querySelector("#body") as HTMLDivElement).style.border = "0";
        (document.querySelector("#root") as HTMLDivElement).style.margin = "0";
        (document.querySelector("#body") as HTMLDivElement).style.margin = "0";
        (document.querySelector("#root") as HTMLDivElement).style.padding = "0";
        (document.querySelector("#body") as HTMLDivElement).style.padding = "0";
      } catch (exception) {}

      componentObjRef.current.tceppsStateChanged = true;
    }
  }, [tceppsState.app, tceppsState.enableNewCrCardFlag, dispatch]);

  const checkCreditCard = useCallback(() => {
    let errorMessage = "";
    if (decryptParams && Object.keys(decryptParams).length > 0 && formReady) {
      let setFocusSet = false;
      if (componentObjRef.current.cardMessage !== "" || !creditcardValid) {
        errorMessage = INVALIDCARDMESSAGE;
        (document.querySelector("#creditCardNumber") as HTMLDivElement).style.backgroundColor = "#ff6060";
        if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
          creditCardMicroNumber.focus();
          setFocusSet = true;
        }
      } else {
        (document.querySelector("#creditCardNumber") as HTMLDivElement).style.backgroundColor = "white";
      }
      if (
        expirationMonthYearRef.current?.value === "" ||
        expirationMonthYearRef.current?.value.length !== 7 ||
        !expirationMonthYearRef.current?.value.match(DATEPATTERN)
      ) {
        errorMessage += INVALIDDATEMESSAGE;
        setExpirationMonthYearClassName(classesSR.pErr);
        (document.querySelector("#expirationMonthYear") as HTMLInputElement).style.backgroundColor = "#ff6060";
        if (!setFocusSet) {
          expirationMonthYearRef.current?.focus();
          setFocusSet = true;
        }
      } else {
        let cardYearMonth = expirationMonthYearRef.current?.value.substring(3, 8) + expirationMonthYearRef.current?.value.substring(0, 2);
        if (cardYearMonth < getCurrentYearMonth()) {
          errorMessage += INVALIDDATEMESSAGE;
          setExpirationMonthYearClassName(classesSR.pErr);
          (document.querySelector("#expirationMonthYear") as HTMLInputElement).style.backgroundColor = "#ff6060";
          if (!setFocusSet) {
            expirationMonthYearRef.current?.focus();
            setFocusSet = true;
          }
        } else {
          setExpirationMonthYearClassName("");
          (document.querySelector("#expirationMonthYear") as HTMLInputElement).style.backgroundColor = "white";
        }
      }
    } else {
      errorMessage = INVALIDCARDMESSAGE; //FORMINITIZING;
    }

    if (errorMessage === "") {
      return true;
    } else {
      let tcepps: any = document.getElementById("tcepps");
      tcepps["errorMessage"].value = errorMessage;
      validationError(tcepps, errorMessage);
      return false;
    }
  }, [creditcardValid, formReady, decryptParams]);

  const toggleFields = useCallback((addProfile: string) => {
    const disabled = addProfile === "true" ? false : true;

    setCreditCardTypeDisabled(disabled);
    setExpirationMonthYearDisabled(disabled);

    (document.getElementById("creditCardType") as HTMLInputElement).disabled = !disabled;
    (document.getElementById("expirationMonthYear") as HTMLInputElement).disabled = !disabled;
  }, []);

  const getLastFourCcAccountNum = (micoFormtoken: string) => {
    const tokenArray = micoFormtoken.split(".");
    let bufferObj = Buffer.from(tokenArray[1], "base64");

    //Encode the Buffer as a utf8 string
    let decodedString = bufferObj.toString("utf8");
    let jsonObject = JSON.parse(decodedString);
    let jti = jsonObject.jti;
    let lastFourDigits = jsonObject.content.paymentInformation.card.number.maskedValue.replaceAll("X", "");
    return [jti, lastFourDigits];
  };

  const invokeProfileAdd = useCallback(
    async (ppa: any, micoFormtoken: string, tcepps: any) => {
      componentObjRef.current.processingForm = true;
      let expYearMonth = "";
      if (expirationMonthYearRef.current?.value) {
        expYearMonth = expirationMonthYearRef.current?.value.substring(3, 8) + expirationMonthYearRef.current?.value.substring(0, 2);
      }

      const [jti, lastFourDigits] = getLastFourCcAccountNum(micoFormtoken);
      const customerName = decryptParams["ppa.customerName"];
      let address1 = decryptParams["ppa.customerAddress1"];
      let address2 = decryptParams["ppa.customerAddress2"];
      const city = decryptParams["ppa.customerCity"];
      const state = decryptParams["ppa.customerState"];
      const zip = decryptParams["ppa.customerZIP"];
      let amount = decryptParams["amount"];
      let email = decryptParams["ppa.customerEmail"];
      const customerPhone = decryptParams["ppa.customerPhone"];
      const merchantID = decryptParams["ppa.merchantID"];
      const customerAccountType = decryptParams["ppa.customerAccountType"];
      const customerProfileOrderOverideInd = decryptParams["ppa.customerProfileOrderOverideInd"];
      const customerProfileFromOrderInd = decryptParams["ppa.customerProfileFromOrderInd"];

      if (email && email.length > 0) {
        email = email.trim();
      }
      let countryCd = decryptParams["ppa.customerCountryCode"];
      if (!(countryCd && countryCd.length > 0)) {
        countryCd = "US";
      }
      if (!address1) {
        address1 = address2;
        address2 = "";
      }
      if (developmentEnv) {
        let address = address1;
        if (address2) {
          address = address + " " + address2;
        }
        if (address) {
          if (address.toLowerCase().indexOf("testdonothonorfailure") >= 0) {
            amount = "40100";
          }
          if (address.toLowerCase().indexOf("testaddressfailure") >= 0) {
            amount = "283600";
          }
          if (address.toLowerCase().indexOf("testcvvfailure") >= 0) {
            amount = "700500";
          }
          if (address.toLowerCase().indexOf("testapifailure") >= 0) {
            email = "abcd@";
          }
          if (address.toLowerCase().indexOf("testinsufficientfund") >= 0) {
            amount = "250900"; //2509 2521
          }
          if (address.toLowerCase().indexOf("testinsufficientfund2") >= 0) {
            amount = "2521.00"; //2509 2521
          }
        }
      }

      const proxyUrl = decryptParams["tcepps.proxyUrl"];
      const successUrl = decryptParams["tcepps.successUrl"];
      const cancelUrl = decryptParams["tcepps.cancelUrl"];

      ppa.cardBrand.value = creditCardTypeRef.current?.value;
      ppa.ccAccountNum.value = "TT=" + jti;
      ppa.ccExp.value = expYearMonth;
      ppa.customerName.value = customerName ? customerName.toUpperCase().trim() : "";
      ppa.customerAddress1.value = address1 ? address1.toUpperCase().trim() : "";
      ppa.customerAddress2.value = address2 ? address2.toUpperCase().trim() : "";
      ppa.customerCity.value = city ? city.toUpperCase().trim() : "";
      ppa.customerState.value = state ? state.toUpperCase().trim() : "";
      ppa.customerZIP.value = zip ? zip.toUpperCase() : "";
      ppa.customerCountryCode.value = countryCd ? countryCd.toUpperCase().trim() : countryCd;
      ppa.merchantID.value = merchantID;
      ppa.customerAccountType = customerAccountType;
      ppa.customerProfileOrderOverideInd = customerProfileOrderOverideInd;
      ppa.customerProfileFromOrderInd = customerProfileFromOrderInd;
      ppa.customerPhone.value = customerPhone;
      ppa.amount.value = amount;
      ppa.customerEmail.value = email;
      ppa.captureContext.value = captureContextRef.current;
      ppa.microformToken.value = microformTokenRef.current;

      let orbitalConnectionUsername = decryptParams["pno.orbitalConnectionUsername"];
      let orbitalConnectionPassword = decryptParams["pno.orbitalConnectionPassword"];
      let orderDefaultDescription = decryptParams["pno.orderDefaultDescription"];
      let orderDefaultAmount = decryptParams["pno.orderDefaultAmount"];
      if (!orbitalConnectionUsername) {
        orbitalConnectionUsername = decryptParams["orbitalConnectionUsername"];
      }
      if (!orbitalConnectionPassword) {
        orbitalConnectionPassword = decryptParams["orbitalConnectionPassword"];
      }
      if (!orderDefaultDescription) {
        orderDefaultDescription = decryptParams["orderDefaultDescription"];
      }
      if (!orderDefaultAmount) {
        orderDefaultAmount = decryptParams["orderDefaultAmount"];
      }
      ppa.orbitalConnectionUsername.value = orbitalConnectionUsername ? orbitalConnectionUsername : "";
      ppa.orbitalConnectionPassword.value = orbitalConnectionPassword ? orbitalConnectionPassword : "";
      ppa.orderDefaultDescription.value = orderDefaultDescription ? orderDefaultDescription : "";
      ppa.orderDefaultAmount.value = orderDefaultAmount ? orderDefaultAmount : "";

      tcepps.lastFourDigits.value = lastFourDigits;
      tcepps.serviceOperation.value = "profileAdd";
      tcepps.proxyUrl.value = proxyUrl;
      tcepps.successUrl.value = successUrl;
      tcepps.cancelUrl.value = cancelUrl;
      // console.log("window.location.origin<" + window.location.origin + ">");
      toggleFields("false");
      try {
        profileAdd(document.getElementById("tcepps"), ppa);
      } catch (err: any) {
        let errorMessage = "Call to submit form <ProfileAdd> failed. " + err.message + ".";
        validationError(document.getElementById("tcepps"), errorMessage);
      }
    },
    [toggleFields, decryptParams]
  );

  const createCybersourceToken = useCallback(
    async (event: any) => {
      if (isCybersourceTimedout(toDay)) {
        microformTokenRef.current = getTimedoutDummyToken();
        const { token, jti, lastFourDigits } = getTimedoutDummyToken();
        dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
      } else {
        componentObjRef.current.cardMessage = "";

        const options = {
          expirationMonth: expirationMonthYearRef.current?.value.substring(0, 2),
          expirationYear: expirationMonthYearRef.current?.value.substring(3, 8),
        };

        // async function createMicroformToken(options: any) {
        await paymentInfo.microform.createToken(options, (err: Error, token: any) => {
          if (err) {
            let newErrMsg = "";
            // @ts-ignore
            switch (err.reason) {
              case "CREATE_TOKEN_NO_FIELDS_LOADED":
                newErrMsg = "991: Token creation error, no fields have been loaded." + err.message;
                break;
              case "CREATE_TOKEN_TIMEOUT":
                newErrMsg = "991: createToken call was unable to proceed." + err.message;
                break;
              case "CREATE_TOKEN_XHR_ERROR":
                newErrMsg = "991: Network error when attempting to create a token." + err.message;
                break;
              case "CREATE_TOKEN_NO_FIELDS":
                newErrMsg = "991: Data fields are unavailable for collection." + err.message;
                break;
              case "CREATE_TOKEN_VALIDATION_PARAMS":
                newErrMsg = "991: Issue with parameters supplied to createToken." + err.message;
                break;
              case "CREATE_TOKEN_VALIDATION_FIELDS":
                // @ts-ignore
                if (err.details && err.details.length > 0) {
                  // @ts-ignore
                  if (err.details.length > 1) {
                    newErrMsg =
                      "Please enter a credit card number. Please check that your Credit card verification code is correct or contact your card issuer.";
                    // @ts-ignore
                  } else if (err.details[0].location === "number") {
                    newErrMsg = "Please enter a credit card number.";
                    // @ts-ignore
                  } else if (err.details[0].location === "securityCode") {
                    newErrMsg = "Please check that your Credit card verification code is correct or contact your card issuer.";
                  } else {
                    newErrMsg = err.message;
                  }
                } else {
                  newErrMsg = err.message;
                }
                break;
              case "CREATE_TOKEN_VALIDATION_SERVERSIDE":
                newErrMsg = "991: Server-side validation rejects the createToken request." + err.message;
                break;
              case "CREATE_TOKEN_UNABLE_TO_START":
                newErrMsg = "991: Loaded field was able to handle the createToken request." + err.message;
                break;
              default:
                newErrMsg = "991: Unknown error:" + err.message;
                break;
            }
            componentObjRef.current.cardMessage = newErrMsg;
          } else {
            microformTokenRef.current = token;
            const { jti, lastFourDigits } = parseToken(token);
            dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
          }
          checkCreditCard();
        });
      }
    },
    [paymentInfo.microform, dispatch, checkCreditCard]
  );

  // useEffect when new token is generated submit form
  useEffect(() => {
    if (!componentObjRef.current.processingForm && paymentInfo.token) {
      if (checkCreditCard()) {
        if ((document.getElementById("serviceOperation") as HTMLInputElement).value === "profileAdd" || serviceOperation === "profileAdd") {
          invokeProfileAdd(document.getElementById("ppa"), paymentInfo.token, document.getElementById("tcepps"));
        }
      }
    }
  }, [paymentInfo.token, checkCreditCard, invokeProfileAdd, serviceOperation]);

  const invokeTceppsServiceOperation = useCallback(
    async (serviceOperation: string, addProfile: string, event: MessageEvent) => {
      if (serviceOperation !== "") {
        // console.log("serviceOperation<" + serviceOperation + ">, addProfile<" + addProfile + ">,<");
        switch (serviceOperation) {
          case "profileAdd":
            setServiceOperation(serviceOperation);
            createCybersourceToken(event);
        }
      }
    },
    [createCybersourceToken]
  );

  const windowMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (
        formReady &&
        event.data &&
        event.data.type &&
        event.data.type === "processTransaction" &&
        event.data.payload &&
        event.data.payload.length > 0
      ) {
        let windowsUrl = window.location.href;
        windowsUrl = windowsUrl.substring(0, windowsUrl.indexOf("/", 8));
        if (windowsUrl.indexOf(":", 8) > 1) {
          windowsUrl = windowsUrl.substring(0, windowsUrl.indexOf(":", 8));
        }
        if (windowsUrl.indexOf("//") > 1) {
          windowsUrl = windowsUrl.substring(windowsUrl.indexOf("//") + 2);
        }
        if (checkCreditCard()) {
          if (event.origin.startsWith("https://") && event.origin.indexOf(windowsUrl) > 0 && event.data.type === "processTransaction") {
            invokeTceppsServiceOperation(event.data.payload, event.data.addProfile, event);
          } else if (
            event.origin.startsWith("http://") &&
            event.origin.indexOf(windowsUrl) > 0 &&
            event.data.type === "processTransaction"
          ) {
            invokeTceppsServiceOperation(event.data.payload, event.data.addProfile, event);
          } else {
            return;
          }
        }
      } else if (
        event.data &&
        (event.data as any).data &&
        ((event.data as any).data as any).focusShift &&
        (event.data as any).event === "blur" &&
        event.currentTarget &&
        (event.currentTarget as any).name &&
        (event.currentTarget as any).name === "creditCardFormIFrame"
      ) {
        if (((event.data as any).data as any).focusShift === "next") {
          expirationMonthYearRef.current?.focus();
        } else if (((event.data as any).data as any).focusShift === "previous") {
          expirationMonthYearRef.current?.focus();
        }
      } else if (componentObjRef.current && event.data && (event.data as string).indexOf("/*cybs-telgram*/") >= 0) {
        const jsonObject = JSON.parse(event.data.replace("/*cybs-telgram*/", ""));
        if (
          jsonObject.data &&
          jsonObject.data.focusShift &&
          jsonObject.event &&
          jsonObject.event === "event_blur" &&
          event.currentTarget &&
          (event.currentTarget as any).name &&
          ((event.currentTarget as any).name === "Mainframe" || (event.currentTarget as any).name === "creditCardFormIFrame")
        ) {
          if (jsonObject.data.focusShift === "next") {
            expirationMonthYearRef.current?.focus();
          } else if (jsonObject.data.focusShift === "previous") {
            expirationMonthYearRef.current?.focus();
          }
        }
      }
    },
    [invokeTceppsServiceOperation, checkCreditCard, formReady]
  );

  // useEffect for windows message
  useEffect(() => {
    // setCollapsed(true);
    if (paymentInfo.isSuccessful) {
      window.addEventListener("message", windowMessageHandler, false);
    }

    return () => {
      window.removeEventListener("message", windowMessageHandler);
    };
  }, [windowMessageHandler, paymentInfo.isSuccessful]);

  useEffect(() => {
    if (tceppsState.app && initializationComplete) {
      setFormReady(true);
    }
  }, [tceppsState.app, initializationComplete]);

  // decrypt the input parameters
  const decryptInputParameterAsync = async (app: string, encryptedRequestKey: string, encryptedRequest: string) => {
    let decryptParamsResponse: any;

    try {
      const response = await PaymentService.getDecryptRequest(app, encryptedRequestKey, encryptedRequest);
      decryptParamsResponse = response.data;
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        // Access to config, request, and response
        let errorMessage = INVALIDAXIOSMESSAGE + "call to getDecryptRequest failed. " + err.message + ".";
        validationError(document.getElementById("tcepps"), errorMessage);
      } else {
        // Just a stock error
        let errorMessage = "Error in getDecryptRequest. " + err.message + ".";
        validationError(document.getElementById("tcepps"), errorMessage);
      }
      return false;
    }

    if (decryptParamsResponse && Object.keys(decryptParamsResponse).length > 0) {
      // check for proxy
      let iFrameUrl = document.referrer;
      if (iFrameUrl === undefined || iFrameUrl.trim().length === 0) {
        let proxyUrlParam = decryptParamsResponse["tcepps.proxyUrl"];
        let successUrlParam = decryptParamsResponse["tcepps.successUrl"];
        if (proxyUrlParam && proxyUrlParam.trim().length > 0) {
          setProxyUrl(proxyUrlParam);
        } else if (successUrlParam && successUrlParam.trim().length > 0) {
          setProxyUrl(successUrlParam);
        }
      }

      setDecryptParams(decryptParamsResponse);
      let inputExpirationDate = decryptParamsResponse["expirationDate"];
      if (inputExpirationDate && inputExpirationDate.length === 7) {
        setExpirationMonthYear(inputExpirationDate);
      }
      let cardType = decryptParamsResponse["cardType"];
      switch (cardType) {
        case "VI":
          (document.getElementById("visa") as HTMLInputElement).checked = true;
          setCreditCardType(cardType);
          break;
        case "MC":
          (document.getElementById("mastercard") as HTMLInputElement).checked = true;
          setCreditCardType(cardType);
          break;
        case "AX":
          (document.getElementById("amex") as HTMLInputElement).checked = true;
          setCreditCardType(cardType);
          break;
        case "DS":
          (document.getElementById("discover") as HTMLInputElement).checked = true;
          setCreditCardType("DI");
          break;
        case "DI":
          (document.getElementById("discover") as HTMLInputElement).checked = true;
          setCreditCardType(cardType);
          break;
      }

      if (isTestingApp(decryptParamsResponse["app.TESTINGAPP"])) {
        developmentEnv = true;
        setExpirationMonthYear(TceppsTestData.ToBeAppTestData.S21SR["to_be_app.expirationMonthYear"]);
      }
    }
  };

  // useEffect if screen/page input parameters change. Dispatch state change
  useEffect(() => {
    const appKey0 = Buffer.from(appKey ? appKey : "", "base64");
    const appKey1 = appKey0.toString("utf8");
    const mydata = JSON.parse(appKey1);

    let iFrameUrl = document.referrer;
    if (iFrameUrl && iFrameUrl.trim().length > 0) {
      setProxyUrl(iFrameUrl);
    }

    let requestParameter = {
      app: "",
      token: "",
      sign: "",
      serviceOperation: "",
      encryptedRequest: "",
      encryptedRequestKey: "",
      enableNewCrCardFlag: "",
      addProfile: "",
      cpgmuiApiUrl: "",
      cpgmuiEnv: "",
      backgroundColor: "",
    };

    let proxyAction = false;

    const getPortNumber = (inputUrl: string) => {
      const windowsUrl = window.location.href;
      const searchParams = windowsUrl.indexOf("//") + 2;
      const firstSlashIndex = windowsUrl.indexOf("/", searchParams);
      const colonIndex = windowsUrl.indexOf(":", searchParams);
      if (firstSlashIndex > 0 && colonIndex > 0) {
        return windowsUrl.substring(colonIndex + 1, firstSlashIndex);
      } else {
        return "";
      }
    };
    //find port if it exits
    const urlPortNumber = getPortNumber(window.location.href);

    Object.keys(mydata).forEach((key) => {
      switch (key) {
        case "tceppsProxy.app":
          proxyAction = true;
          requestParameter.app = mydata[key];
          break;
        case "tcepps.app":
          requestParameter.app = mydata[key];
          break;
        case "tcepps.token":
          requestParameter.token = mydata[key];
          break;
        case "tcepps.sign":
          requestParameter.sign = mydata[key];
          break;
        case "tceppsProxy.serviceOperation":
        case "tcepps.serviceOperation":
          requestParameter.serviceOperation = mydata[key];
          break;
        case "tcepps.encryptedRequest":
          requestParameter.encryptedRequest = mydata[key];
          break;
        case "tcepps.encryptedRequestKey":
          requestParameter.encryptedRequestKey = mydata[key];
          break;
        case "tcepps.enableNewCrCardFlag":
          requestParameter.enableNewCrCardFlag = mydata[key];
          break;
        case "tcepps.background-color":
          requestParameter.backgroundColor = mydata[key];
          if (topDivRef.current !== null) {
            topDivRef.current.style.backgroundColor = mydata[key];
            topDivRef.current.style.margin = "0";
          }
          break;
        case "tceppsProxy.addProfile":
        case "tcepps.addProfile":
          requestParameter.addProfile = mydata[key];
          break;
        case "cpgmuiApiUrl":
          requestParameter.cpgmuiApiUrl = mydata[key] + "/TCEPPS/jsp/tceppsHtmlFormInterface.jsp?tceppsApp=";
          if (urlPortNumber !== "") {
            let backendPortNumber = getPortNumber(requestParameter.cpgmuiApiUrl);
            // console.log("backendPortNumber<" + backendPortNumber + ">");
            if (backendPortNumber !== urlPortNumber) {
              requestParameter.cpgmuiApiUrl = requestParameter.cpgmuiApiUrl.replace(backendPortNumber, urlPortNumber);
            }
          }
          break;
        case "cpgmuiEnv":
          requestParameter.cpgmuiEnv = mydata[key];
          if (requestParameter.cpgmuiEnv.toLowerCase() === "development" || requestParameter.cpgmuiEnv.toLowerCase() === "qa") {
            developmentEnv = true;
          }
          break;
      }
    });
    requestParameter.cpgmuiApiUrl += requestParameter.app;

    if (!proxyAction) {
      dispatch(
        tceppsActions.initializeTceppsAction({
          app: requestParameter.app,
          token: requestParameter.token,
          sign: requestParameter.sign,
          serviceOperation: requestParameter.serviceOperation,
          encryptedRequest: requestParameter.encryptedRequest,
          encryptedRequestKey: requestParameter.encryptedRequestKey,
          enableNewCrCardFlag: requestParameter.enableNewCrCardFlag,
          cpgmuiApiUrl: requestParameter.cpgmuiApiUrl,
          cpgmuiEnv: requestParameter.cpgmuiEnv,
          backgroundColor: requestParameter.backgroundColor,
        })
      );
    } else {
      dispatch(
        tceppsProxyActions.initializeTceppsAction({
          app: requestParameter.app,
          serviceOperation: requestParameter.serviceOperation,
          addProfile: requestParameter.addProfile,
        })
      );
    }

    // decrypt the input parameters
    decryptInputParameterAsync(
      requestParameter.app.toUpperCase().trim(),
      requestParameter.encryptedRequestKey,
      requestParameter.encryptedRequest
    );
  }, [appKey, dispatch]);

  // useEffect when form get loaded. setup microform
  useEffect(() => {
    const loadCybersourceJS = (captureContext: any, clientLibrary: string, clientLibraryIntegrity: string, callback: (cc: any) => any) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      // script.src = config.cybersource.microformJs;
      script.src = clientLibrary;
      script.integrity = clientLibraryIntegrity;
      script.crossOrigin = "anonymous";
      document.body.appendChild(script);
      script.onload = () => {
        callback(captureContext);
      };
    };

    const selectGivenCardType = (cardName: string, cardValid: boolean, cardEmpty: boolean) => {
      let varCreditCardType = "";
      let detectedCard = false;
      if (cardName.length > 0) {
        switch (cardName) {
          case "VI":
          case "visa":
            varCreditCardType = "VI";
            detectedCard = true;
            break;
          case "MC":
          case "mastercard":
            varCreditCardType = "MC";
            detectedCard = true;
            break;
          case "AX":
          case "amex":
            varCreditCardType = "AX";
            detectedCard = true;
            break;
          case "DI":
          case "discover":
            varCreditCardType = "DI";
            detectedCard = true;
            break;
        }
      }
      setCreditCardType(varCreditCardType);

      let errMessage = "Credit Card# needs to be entered.";
      if (!detectedCard) {
        errMessage = "Please enter a visa or a mastercard or amex or discover card#.";
      } else if (cardEmpty) {
        errMessage = "Credit Card# needs to be entered.";
      } else if (!cardValid) {
        errMessage = "Please enter a valid Credit card#.";
      } else if (cardValid) {
        errMessage = "";
      }
      componentObjRef.current.cardMessage = errMessage.length === 0 ? "" : INVALIDCARDMESSAGE;
      setCreditcardValid(cardValid && detectedCard);
    };

    const initializeMicroform = async (captureContext: any) => {
      captureContextRef.current = captureContext;
      // @ts-ignore
      const flex = await new window.Flex(captureContext);
      const microform = await flex.microform({ styles: flexStyles });
      creditCardMicroNumber = await microform.createField("number", { placeholder: "" });
      await creditCardMicroNumber.load("#creditCardNumber");
      dispatch(microformInitializedAction(microform));

      if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
        //add listener
        creditCardMicroNumber.on("change", function (data: any) {
          if (data.card && data.card.length > 0) {
            selectGivenCardType(data.card[0].name, data.valid, data.empty);
          } else {
            selectGivenCardType("", data.valid, data.empty);
          }
        });
        creditCardMicroNumber.on("load", function (data: any) {
          setInitializationComplete(true);
          creditCardMicroNumber.focus();
        });
        creditCardMicroNumber.on("focus", function (data: any) {
          (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "5px auto -webkit-focus-ring-color";
          if (isFirefox) {
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "1px solid deepskyblue";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outlineOffset = "2px";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1.5px solid blue";
          }
        });
        creditCardMicroNumber.on("blur", function (data: any) {
          (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outline = "none";
          if (isFirefox) {
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.outlineOffset = "0px";
            (document.querySelector("#creditCardNumber") as HTMLDivElement).style.border = "1px solid #858585";
          }
        });
        creditCardMicroNumber.on("inputSubmitRequest", function (data: any) {
          expirationMonthYearRef.current?.focus();
        });
      }
    };

    if (proxyUrl && proxyUrl.length > 0) {
      PaymentService.getCaptureContext(extractDomain(proxyUrl))
        .then((resp) => {
          const captureContext = resp.data.captureContext;
          const { clientLibrary, clientLibraryIntegrity } = extractClientLibrary(resp.data.json);
          dispatch(contextLoadedAction(captureContext));
          // @ts-ignore
          if (!window.Flex) {
            loadCybersourceJS(captureContext, clientLibrary, clientLibraryIntegrity, initializeMicroform);
          } else {
            initializeMicroform(captureContext);
          }
          return () => dispatch(unmountAction);
        })
        .catch((err: Error | AxiosError) => {
          let errorMessage = "";
          if (axios.isAxiosError(err)) {
            // Access to config, request, and response
            errorMessage = INVALIDAXIOSMESSAGE + "call to initialize microForm failed. " + err.message + ".";
          } else {
            // Just a stock error
            errorMessage = "Initialize microForm failed. " + err.message + ".";
          }
          validationError(document.getElementById("tcepps"), errorMessage);
        });
    }
  }, [dispatch, proxyUrl]);

  const onChangeHandler = (identifier: string, event: ChangeEvent & { target: HTMLInputElement | HTMLSelectElement }) => {
    switch (identifier) {
      case "expirationMonthYear":
        setExpirationMonthYear(event.target.value);
        break;
      case "creditCardType":
        setCreditCardType(event.target.value);
        break;
    }
  };

  const onBlurHandler = (identifier: string, event: FocusEvent & { target: HTMLInputElement | HTMLSelectElement }) => {
    switch (identifier) {
      case "expirationMonthYear":
        setExpirationMonthYear(event.target.value);
        break;
    }
  };

  return (
    <>
      <div ref={topDivRef}>
        <form id="tcepps" name="tcepps" action={tceppsState.cpgmuiApiUrl} target="_self">
          <input id="app" name="app" type="hidden" value={tceppsState.app} />
          <input id="token" name="token" type="hidden" value={tceppsState.token} />
          <input id="sign" name="sign" type="hidden" value={tceppsState.sign} />
          <input id="serviceOperation" name="serviceOperation" type="hidden" value="" />
          <input id="encryptedRequest" name="encryptedRequest" type="hidden" value={tceppsState.encryptedRequest} />
          <input id="encryptedRequestKey" name="encryptedRequestKey" type="hidden" value={tceppsState.encryptedRequestKey} />
          <input id="lastFourDigits" name="lastFourDigits" type="hidden" value="" />
          <input id="proxyUrl" name="proxyUrl" type="hidden" value="" />
          <input id="successUrl" name="successUrl" type="hidden" value="" />
          <input id="cancelUrl" name="cancelUrl" type="hidden" value="" />
          <input id="errorMessage" name="errorMessage" type="hidden" value="" />
        </form>
        <form id="ppa" name="ppa">
          <input id="cardBrand" name="cardBrand" type="hidden" value="" />
          <input id="division" name="division" type="hidden" value="" />
          <input id="ccAccountNum" name="ccAccountNum" type="hidden" value="" />
          <input id="ccExp" name="ccExp" type="hidden" value="" />
          <input id="customerName" name="customerName" type="hidden" value="" />
          <input id="customerAddress1" name="customerAddress1" type="hidden" value="" />
          <input id="customerAddress2" name="customerAddress2" type="hidden" value="" />
          <input id="customerCity" name="customerCity" type="hidden" value="" />
          <input id="customerState" name="customerState" type="hidden" value="" />
          <input id="customerZIP" name="customerZIP" type="hidden" value="" />
          <input id="customerCountryCode" name="customerCountryCode" type="hidden" value="" />
          <input id="merchantID" name="merchantID" type="hidden" value="" />
          <input id="priorAuthCd" name="priorAuthCd" type="hidden" value="" />
          <input id="orderID" name="orderID" type="hidden" value="" />
          <input id="amount" name="amount" type="hidden" value="" />
          <input id="customerPhone" name="customerPhone" type="hidden" value="" />
          <input id="customerEmail" name="customerEmail" type="hidden" value="" />
          <input id="comments" name="comments" type="hidden" value="" />
          <input id="orbitalConnectionUsername" name="" type="hidden" value="" />
          <input id="orbitalConnectionPassword" name="orbitalConnectionPassword" type="hidden" value="" />
          <input id="orderDefaultDescription" name="orderDefaultDescription" type="hidden" value="" />
          <input id="orderDefaultAmount" name="orderDefaultAmount" type="hidden" value="" />
          <input id="captureContext" name="captureContext" type="hidden" value="" />
          <input id="microformToken" name="microformToken" type="hidden" value="" />
        </form>
        <div style={s21srDivTopStyle}>
          <form id="creditCard" name="creditCard">
            <table className={`${classesSR.s21sr} ${classesSR.table}`} style={tableStyle}>
              <tbody>
                <tr key={1}>
                  <td align="left" style={styletd} className={`${classesSR.s21sr} ${classesSR.td} ${classesSR.stab}`}>
                    <label style={{ display: "block", textAlign: "right" }} htmlFor="creditCardType">
                      Credit Card Type
                    </label>
                  </td>
                  <td style={{ width: "55%" }} className={`${classesSR.s21sr} ${classesSR.td} ${classesSR.stab}`}>
                    <select
                      id="creditCardType"
                      name="creditCardType"
                      tabIndex={-1}
                      value={creditCardType}
                      disabled={creditCardTypeDisabled}
                      ref={creditCardTypeRef}
                      onChange={(event) => onChangeHandler("creditCardType", event)}
                      style={inputStyleCard}
                    >
                      <option value="" disabled={true}></option>
                      <option value="AX" disabled={true}>
                        AMEX
                      </option>
                      <option value="DI" disabled={true}>
                        DISCOVER
                      </option>
                      <option value="MC" disabled={true}>
                        MASTER
                      </option>
                      <option value="VI" disabled={true}>
                        VISA
                      </option>
                    </select>
                  </td>
                </tr>

                <tr key={2}>
                  <td width="110px" align="left" style={styletd} className={`${classesSR.s21sr} ${classesSR.td} ${classesSR.stab}`}>
                    <label style={{ display: "block", textAlign: "right" }} htmlFor="creditCardNumber">
                      Credit Card #
                    </label>
                  </td>
                  <td className={`${classesSR.s21sr} ${classesSR.td} ${classesSR.stab} ${classesSR.pad}`}>
                    <div id="creditCardNumber" style={creditCardStyle} tabIndex={1} autoFocus={true}></div>
                  </td>
                </tr>

                <tr key={3}>
                  <td align="left" className={`${classesSR.s21sr} ${classesSR.td} ${classesSR.stab}`}>
                    <label style={{ display: "block", textAlign: "right" }} htmlFor="expirationMonthYear">
                      Expiry Date
                    </label>
                  </td>
                  <td className={`${classesSR.s21sr} ${classesSR.td} ${classesSR.stab}`}>
                    <input
                      id="expirationMonthYear"
                      name="expirationMonthYear"
                      type="text"
                      size={7}
                      maxLength={7}
                      tabIndex={2}
                      value={expirationMonthYear}
                      disabled={expirationMonthYearDisabled}
                      ref={expirationMonthYearRef}
                      onChange={(event) => onChangeHandler("expirationMonthYear", event)}
                      onBlur={(event) => onBlurHandler("expirationMonthYear", event)}
                      onKeyUp={keyUpDateEvent}
                      style={inputStyle}
                      className={expirationMonthYearClassName}
                    />
                    {" (MM/YYYY)"}
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </>
  );
}
