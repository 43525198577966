import React, { ChangeEvent, FocusEvent, KeyboardEvent, useCallback, useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios, { AxiosError } from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./c360datepicker.css";

import { TceppsTestData, isTestingApp } from "../../utils/TestLoadData";

import { tceppsActions } from "../../redux-store/reducer/tcepps_slice";
import { tokenCreatedAction, microformInitializedAction, unmountAction, contextLoadedAction } from "../../redux-store/reducer/paymentSlice";
import { LogService, LOGERRORLEVEL } from "../../service/logService";
import PaymentService from "../../service/paymentService";
import { decryptParamType, getYearsFromToday, isCybersourceTimedout, getTimedoutDummyToken } from "../../utils/calendarUtil";

import classesC360b2bcarepak from "./CusaC360B2bCarepak.module.css";
import { extractClientLibrary, extractDomain, parseToken } from "../../utils/utils";
import { componentVarObject, ComponentVarProps } from "../../utils/ComponentConstant";
import { isDateNumber, isNumber } from "../../utils/calendarUtil";

var Buffer = require("buffer/").Buffer; // note: the trailing slash is important!

let developmentEnv = false;
var creditCardMicroNumber: any;
var cvvMicroSecurityCode: any;
const customerNameMaxLength = 90;

const margin20Style = {
  margin: "20px 0",
} as React.CSSProperties;
const creditCardStyle = {
  fontSize: "16px",
  fontFamily: "Proxima Nova",
  paddingLeft: "20px",
  marginRight: "20px",
  height: "20px",
  color: "fieldtext",
  letterSpacing: "normal",
  wordSpacing: "normal",
  lineHeight: "normal",
  textIndent: "0px",
  textShadow: "none",
  display: "inline-block",
  cursor: "text",
  backgroundColor: "#f8f8f8",
  width: "100%",
  margin: "0em",
  borderWidth: "0px",
  borderStyle: "inset",
  borderColor: "-internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133))",
  borderImage: "initial",
} as React.CSSProperties;
const flexStyles = {
  input: {
    cursor: "text",
    color: "#171719",
  },
  "::placeholder": { color: "lightslategrey" },
  ":focus": { color: "black" },
  ":disabled": { cursor: "not-allowed" },
  valid: { color: "#171719" },
  invalid: { color: "#a94442" },
  // ":hover": {
  //   "font-style": "italic",
  // },
} as React.CSSProperties;

const toDay = new Date();
const currentMonthYear = toDay.toLocaleString("en-US", { year: "numeric" }) + toDay.toLocaleString("en-US", { month: "2-digit" });

const ADDMESSAGE = "addmessage";
const REMOVEMESSAGE = "removmessage";
const EMPTYCUSTOMERNAMEMESSAGE = "Please enter Card Holder Name.";
const FORMINITIZING = "Browser initializing. Please wait and retry.";
const INVALIDAXIOSMESSAGE = "AxiosError. ";
const INVALIDCARDMESSAGE = "Please enter a valid credit card number.";
const INVALIDCVVMESSAGE = "Please enter a valid CVV Number.";
const INVALIDDATEMESSAGE = "Credit card has expired.";
const PROCESSING = "Processing....  ";

export function CusaC360B2bCarepakComponent(this: any) {
  const dispatch = useDispatch();
  const tceppsState = useSelector((state: any) => state.tcepps);
  const paymentInfo = useSelector((state: any) => state.paymentInfo);

  const [expirationMonthYearDate, setExpirationMonthYearDate] = useState<Date | null>(null);
  const [validationMessages, setValidationMessages] = useState("");
  const [selectedCreditCard, setSelectedCreditCard] = useState("");
  const [customerName, setCustomerName] = useState("");

  const [creditCardNumberDisabled, setCreditCardNumberDisabled] = useState(false);
  const [securityCodeDisabled, setSecurityCodeDisabled] = useState(false);
  const [expirationMonthYearDisabled, setExpirationMonthYearDisabled] = useState(false);
  const [customerNameDisabled, setCustomerNameDisabled] = useState(false);

  const creditCardNumberRef = useRef<HTMLDivElement>(null);
  const securityCodeRef = useRef<HTMLDivElement>(null);
  const datePickerRef = useRef<any>(null);
  const validationMessagesRef = useRef<string>("");
  const updateAmountRef = useRef<string>("-1");
  const updateBillingAddressRef = useRef<boolean>(false);
  const updateAddress1Ref = useRef<string>("");
  const updateAddress2Ref = useRef<string>("");
  const updateCityRef = useRef<string>("");
  const updateStateRef = useRef<string>("");
  const updateZipRef = useRef<string>("");
  const logErrorRef = useRef<string>("CusaC360B2bCarepak Not initialized");
  const dateKeyCode = useRef<number>(-1);
  const dateSelectionStart = useRef<number>(0);
  const dateSelectionEnd = useRef<number>(0);
  const customerNameRef = useRef<HTMLInputElement>(null);

  const componentObjRef = useRef<ComponentVarProps>(componentVarObject);
  const captureContextRef = useRef<string>("");
  const microformTokenRef = useRef<any>(null);

  const [creditCardNumberBorderClass, setCreditCardNumberBorderClass] = useState("");
  const [securityCodeBorderClass, setSecurityCodeBorderClass] = useState("");
  const [datePickerBorderClass, setDatePickerBorderClass] = useState("");
  const [isLargeScreen, setLargeScreen] = useState(false);
  const [windowSize] = useState({
    width: window.innerWidth,
  });
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);

  const [creditcardValid, setCreditcardValid] = useState(false);
  const [cvvValid, setCvvdValid] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);

  const [decryptParams, setDecryptParams] = useState([] as decryptParamType);
  const [proxyUrl, setProxyUrl] = useState("");

  const { appKey } = useParams();

  function setCreditCardType(newValue: string) {
    switch (newValue) {
      case "VI":
      case "visa":
        componentObjRef.current.glblCreditCardType = "VI";
        break;
      case "MC":
      case "mastercard":
        componentObjRef.current.glblCreditCardType = "MC";
        break;
      case "AX":
      case "amex":
        componentObjRef.current.glblCreditCardType = "AX";
        break;
      case "DI":
      case "discover":
        componentObjRef.current.glblCreditCardType = "DI";
        break;
      default:
        componentObjRef.current.glblCreditCardType = "";
        break;
    }
  }

  const convertMMYYYYToDate = (mmYYYYString: string) => {
    const [month, year] = mmYYYYString.split("/");
    return new Date(+year, +month - 1); // Month is 0-indexed
  };

  const addYears = (years: number) => {
    const newDate = new Date(); // Create a new Date instance to avoid mutating the original date
    newDate.setMonth(newDate.getMonth() + years * 12);
    return newDate;
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setExpirationMonthYearDate(date);
      if (date) {
        if (date instanceof Date && !isNaN(date.getTime())) {
          updateValidationMessages(REMOVEMESSAGE, INVALIDDATEMESSAGE);
        } else {
          updateValidationMessages(ADDMESSAGE, INVALIDDATEMESSAGE);
        }
      } else {
        updateValidationMessages(ADDMESSAGE, INVALIDDATEMESSAGE);
      }
    } else {
      // datePickerRef.current.input.value = toDay;
      // setExpirationMonthYearDate(toDay);
    }
  };

  const handleDateSelect = (date: Date | null) => {
    if (date) {
      setExpirationMonthYearDate(date);
    } else {
      // setExpirationMonthYearDate(toDay);
    }
  };
  const keyCodeArray = [191, 8, 86, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];

  const handleChangeRaw = (date: any) => {
    if (date && date.target) {
      let newValue = date.target.value;
      // if not composing
      if (dateKeyCode.current !== 229 && newValue) {
        if (dateKeyCode.current && keyCodeArray.includes(dateKeyCode.current)) {
          if (dateKeyCode.current !== 191) {
            /* != / Slash */
            //- != Backspace
            if (dateKeyCode.current !== 8) {
              if (newValue.length === 1 && newValue >= "2" && isNumber(newValue)) {
                newValue = "0" + newValue + "/20";
              } else if (newValue.length === 2 && newValue >= "01" && newValue <= "12") {
                newValue = newValue + "/20";
              } else if (
                newValue.length === 2 &&
                dateSelectionStart.current >= 2 &&
                dateSelectionStart.current === dateSelectionEnd.current &&
                isDateNumber(newValue)
              ) {
                newValue = newValue + "/20";
              } else if (dateKeyCode.current === 86 && newValue.length === 6) {
                //- === KeyV
                //- to handle copy & paste of 8 digit
                newValue = newValue.substr(0, 2) + "/" + newValue.substr(2, 4);
              }
              //- == Backspace
            } else {
              //- backspace would skip the slashes and just remove the numbers
              if (newValue.length === 5 && !(dateSelectionStart.current <= 1)) {
                newValue = newValue.substring(0, 2);
              }
            }
          } else {
            //- == /
            //remove slashes to avoid 12//01/2014
            if (newValue.length !== 3) {
              newValue = newValue.substring(0, newValue.length - 1);
            } else if (isDateNumber(newValue)) {
              newValue = newValue + "20";
            }
          }
          date.target.value = newValue;
          if (newValue && newValue.length === 7) {
            const newRaw = new Date(+newValue.substring(3), +newValue.substring(0, 2) - 1, 1);
            if (newRaw instanceof Date && !isNaN(newRaw.getTime())) {
              setExpirationMonthYearDate(newRaw);
              updateValidationMessages(REMOVEMESSAGE, INVALIDDATEMESSAGE);
            } else {
              updateValidationMessages(ADDMESSAGE, INVALIDDATEMESSAGE);
            }
          } else {
            updateValidationMessages(ADDMESSAGE, INVALIDDATEMESSAGE);
          }
        }
      }
    } else {
      // setExpirationMonthYearDate(toDay);
      updateValidationMessages(ADDMESSAGE, INVALIDDATEMESSAGE);
    }
  };

  const onBlurHandler = (identifier: string, event: FocusEvent & { target: HTMLElement | HTMLInputElement }) => {
    let fieldChangeMessage = EMPTYCUSTOMERNAMEMESSAGE;
    let messageAction = ADDMESSAGE;
    switch (identifier) {
      case "expirationMonthYear":
        setDatePickerOpen(false);
        break;
      case "customerName":
        const target = event.target as HTMLInputElement;
        setCustomerName(target.value.toUpperCase());
        if (target.value.trim() !== "") {
          messageAction = REMOVEMESSAGE;
        }
        break;
    }
    updateValidationMessages(messageAction, fieldChangeMessage);
  };

  const onKeyDownHandler = (identifier: string, event: KeyboardEvent<HTMLElement>) => {
    if (identifier === "customerName") {
      if (event.key === "Tab") {
        if (!event.shiftKey) {
          if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
            creditCardMicroNumber.focus();
          }
        } else {
          if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
            cvvMicroSecurityCode.focus();
          }
        }
      }
    } else {
      dateKeyCode.current = event.keyCode;
      try {
        // @ts-ignore
        dateSelectionStart.current = event.target.selectionStart;
      } catch (error) {}
      try {
        // @ts-ignore
        dateSelectionEnd.current = event.target.selectionEnd;
      } catch (error) {}

      if (event.key === "Tab") {
        if (identifier === "expirationMonthYear" && event.shiftKey) {
          if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
            creditCardMicroNumber.focus();
          }
        } else if (identifier === "expirationMonthYear" && !event.shiftKey) {
          if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
            cvvMicroSecurityCode.focus();
          }
        }
      }
    }
  };

  const checkCreditCard = useCallback(() => {
    let errorMessage = "";

    if (decryptParams && Object.keys(decryptParams).length > 0 && submitForm) {
      let setFocusSet = false;
      let userMonthYear = datePickerRef.current?.input.value.toLocaleString();

      if (customerNameRef.current?.value === "") {
        errorMessage = EMPTYCUSTOMERNAMEMESSAGE;
        if (!setFocusSet) {
          customerNameRef.current?.focus();
          setFocusSet = true;
        }
      }

      if (componentObjRef.current.cardMessage !== "" || !creditcardValid) {
        errorMessage += (errorMessage.length > 0 ? " " : "") + INVALIDCARDMESSAGE;
        if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
          creditCardMicroNumber.focus();
        }
        setFocusSet = true;
      }
      if (componentObjRef.current.cvvMessage !== "" || !cvvValid) {
        errorMessage += (errorMessage.length > 0 ? " " : "") + INVALIDCVVMESSAGE;
        if (!setFocusSet) {
          if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
            cvvMicroSecurityCode.focus();
          }
        }
        setFocusSet = true;
      }
      if (!userMonthYear || userMonthYear.trim().length !== 7 || !isNumber(userMonthYear)) {
        userMonthYear = "";
      } else {
        userMonthYear = userMonthYear.substring(3) + userMonthYear.substring(0, 2);
      }
      if (userMonthYear < currentMonthYear) {
        errorMessage += (errorMessage.length > 0 ? " " : "") + INVALIDDATEMESSAGE;
        if (!setFocusSet) datePickerRef.current?.input.focus();
        setFocusSet = true;
      }
      if (componentObjRef.current.otherErrMsg !== "") {
        errorMessage += componentObjRef.current.otherErrMsg;
        if (!setFocusSet) {
          if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
            creditCardMicroNumber.focus();
          }
        }
        setFocusSet = true;
      }
    } else {
      errorMessage = FORMINITIZING;
    }

    if (errorMessage === "") {
      setValidationMessages("");
      validationMessagesRef.current = "";
      componentObjRef.current.isBackgroundRed = false;
      return true;
    } else {
      setValidationMessages(errorMessage);
      validationMessagesRef.current = errorMessage;
      componentObjRef.current.isBackgroundRed = true;
      const messageObj = {
        eventSource: "cpgmui",
        eventType: "Form Validation",
        message: errorMessage,
      };
      window.parent.postMessage(JSON.stringify(messageObj), "*");
      return false;
    }
  }, [creditcardValid, cvvValid, decryptParams, submitForm]);

  const toggleFields = useCallback((addProfile: string) => {
    const disabled = addProfile === "true" ? false : true;

    if (disabled) {
      setCreditCardNumberBorderClass(`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.disable_datepicket}`);
      setSecurityCodeBorderClass(`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.disable_datepicket}`);
      setDatePickerBorderClass(`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.disable_datepicket}`);
    } else {
      setCreditCardNumberBorderClass(`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.enable_datepicket}`);
      setSecurityCodeBorderClass(`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.enable_datepicket}`);
      setDatePickerBorderClass(`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.enable_datepicket}`);
    }
    setCreditCardNumberDisabled(disabled);
    setExpirationMonthYearDisabled(disabled);
    setCustomerNameDisabled(disabled);
    setSecurityCodeDisabled(disabled);
  }, []);

  const getLastFourCcAccountNum = (micoFormtoken: string) => {
    const tokenArray = micoFormtoken.split(".");
    let bufferObj = Buffer.from(tokenArray[1], "base64");

    //Encode the Buffer as a utf8 string
    let decodedString = bufferObj.toString("utf8");
    let jsonObject = JSON.parse(decodedString);
    let jti = jsonObject.jti;
    let lastFourDigits = jsonObject.content.paymentInformation.card.number.maskedValue.replaceAll("X", "");
    return [jti, lastFourDigits];
  };

  const invokeProfileAdd = useCallback(
    async (micoFormtoken: string) => {
      if (componentObjRef.current.submitPage) {
        componentObjRef.current.processingForm = true;
        let expYearMonth = "";
        expYearMonth = datePickerRef.current?.input.value.toLocaleString();

        const [jti, lastFourDigits] = getLastFourCcAccountNum(micoFormtoken);

        let address1 = decryptParams["address1"];
        let address2 = decryptParams["address2"];
        let city = decryptParams["city"];
        let state = decryptParams["state"];
        let zip = decryptParams["zip"];
        let proxyUrl = decryptParams["tcepps.proxyUrl"];
        let amount = decryptParams["amount"];
        if (updateAmountRef.current !== "-1") {
          amount = updateAmountRef.current;
        }
        if (updateBillingAddressRef.current) {
          address1 = updateAddress1Ref.current;
          address2 = updateAddress2Ref.current;
          city = updateCityRef.current;
          state = updateStateRef.current;
          zip = updateZipRef.current;
        }
        let email = decryptParams["email"];
        if (!(email && email.length > 0)) {
          // email = DEFUSEREMAIL;
          email = decryptParams["customerEmail"];
        }
        if (email && email.length > 0) {
          email = email.trim();
        }
        let countryCd = decryptParams["country"];
        if (!(countryCd && countryCd.length > 0)) {
          countryCd = "US";
        }
        if (!address1) {
          address1 = address2;
          address2 = "";
        }

        let orbitalConnectionUsername = decryptParams["pno.orbitalConnectionUsername"];
        let orbitalConnectionPassword = decryptParams["pno.orbitalConnectionPassword"];
        let switchSoloIssueNum = decryptParams["pno.switchSoloIssueNum"];
        let switchSoloCardStartDate = decryptParams["pno.switchSoloCardStartDate"];
        let authenticationECIInd = decryptParams["pno.authenticationECIInd"];
        if (!orbitalConnectionUsername) {
          orbitalConnectionUsername = decryptParams["orbitalConnectionUsername"];
        }
        if (!orbitalConnectionPassword) {
          orbitalConnectionPassword = decryptParams["orbitalConnectionPassword"];
        }
        if (!switchSoloIssueNum) {
          switchSoloIssueNum = decryptParams["switchSoloIssueNum"];
        }
        if (!switchSoloCardStartDate) {
          switchSoloCardStartDate = decryptParams["switchSoloCardStartDate"];
        }
        if (!authenticationECIInd) {
          authenticationECIInd = decryptParams["authenticationECIInd"];
        }

        if (developmentEnv) {
          let address = address1;
          if (address2) {
            address = address + " " + address2;
          }
          if (address) {
            if (address.toLowerCase().indexOf("testdonothonorfailure") >= 0) {
              amount = "40100";
            }
            if (address.toLowerCase().indexOf("testaddressfailure") >= 0) {
              amount = "283600";
            }
            if (address.toLowerCase().indexOf("testcvvfailure") >= 0) {
              amount = "700500";
              // cvv = "111";
            }
            if (address.toLowerCase().indexOf("testapifailure") >= 0) {
              email = "abcd@";
            }
            if (address.toLowerCase().indexOf("testinsufficientfund") >= 0) {
              amount = "250900"; //2509 2521
            }
            if (address.toLowerCase().indexOf("testinsufficientfund2") >= 0) {
              amount = "252100"; //2509 2521
            }
          }
        }
        // amount = "2521.00";
        const carepackObj = {
          "pno.ccAccountNum": "TT=" + jti,
          "pno.ccExp": expYearMonth,
          "pno.ccCardVerifyNum": "",
          "pno.avsName": customerNameRef.current?.value.toUpperCase().trim(),
          "pno.avsAddress1": address1 ? address1.toUpperCase().trim() : "",
          "pno.avsAddress2": address2 ? address2.toUpperCase().trim() : "",
          "pno.avsCity": city ? city.toUpperCase().trim() : "",
          "pno.avsState": state ? state.toUpperCase().trim() : "",
          "pno.avsZip": zip ? zip.toUpperCase().trim() : "",
          "pno.amount": !amount ? "100" : amount,
          "pno.orderID": decryptParams["orderNumber"],
          "tcepps.cardBrand": componentObjRef.current.glblCreditCardType,
          "tcepps.lastFourDigits": lastFourDigits,
          "pno.avsCountryCode": countryCd ? countryCd.toUpperCase().trim() : countryCd,
          "pno.customerEmail": email,
          "pno.orbitalConnectionUsername": orbitalConnectionUsername ? orbitalConnectionUsername : "",
          "pno.orbitalConnectionPassword": orbitalConnectionPassword ? orbitalConnectionPassword : "",
          "pno.switchSoloIssueNum": switchSoloIssueNum ? switchSoloIssueNum : "",
          "pno.switchSoloCardStartDate": switchSoloCardStartDate ? switchSoloCardStartDate : "",
          "pno.authenticationECIInd": authenticationECIInd ? authenticationECIInd : "",
          "tcepps.proxyUrl": proxyUrl,
          "tcepps.serviceOperation": "newOrder",
          "tcepps.app": tceppsState.app.toUpperCase().trim(),
          "tcepps.token": tceppsState.token,
          "tcepps.sign": tceppsState.sign,
          "pno.captureContext": captureContextRef.current,
          "pno.microformToken": microformTokenRef.current,
        };
        toggleFields("false");
        PaymentService.submitCusac360b2bcarepak(carepackObj)
          .then((resp) => {
            window.open(resp.data, "_self");
          })
          .catch((err: Error | AxiosError) => {
            console.log(err);
            if (axios.isAxiosError(err)) {
              // Access to config, request, and response
              setValidationMessages(
                INVALIDAXIOSMESSAGE + "call to submitCusac360b2bcarepak failed. " + err.message + ". Please check console."
              );
              validationMessagesRef.current =
                INVALIDAXIOSMESSAGE + "call to submitCusac360b2bcarepak failed. " + err.message + ". Please check console.";
            } else {
              // Just a stock error
              setValidationMessages("Error in submitCusac360b2bcarepak. " + err.message + ". Please check console.");
              validationMessagesRef.current = "Error in submitCusac360b2bcarepak. " + err.message + ". Please check console.";
            }
            componentObjRef.current.isBackgroundRed = true;
            LogService.logMessage(
              logErrorRef.current,
              LOGERRORLEVEL,
              "Call to submit form submitMycanoncpsap failed:" + (err ? JSON.stringify(err.message) : "null")
            );
          });
      }
    },
    [toggleFields, tceppsState.app, tceppsState.token, tceppsState.sign, decryptParams]
  );

  const createCybersourceToken = useCallback(async () => {
    if (isCybersourceTimedout(toDay)) {
      componentObjRef.current.isBackgroundRed = true;
      (document.getElementById("validationMessages") as HTMLInputElement).value = PROCESSING;
      validationMessagesRef.current = PROCESSING;
      microformTokenRef.current = getTimedoutDummyToken();
      const { token, jti, lastFourDigits } = getTimedoutDummyToken();
      dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
    } else {
      componentObjRef.current.cardMessage = "";
      componentObjRef.current.cvvMessage = "";

      let expMonth = "";
      let expYear = "";
      expYear = datePickerRef.current?.input.value.toLocaleString().substring(3);
      expMonth = datePickerRef.current?.input.value.toLocaleString().substring(0, 2);
      const options = {
        expirationMonth: expMonth,
        expirationYear: expYear,
      };

      // async function createMicroformToken(options: any) {
      await paymentInfo.microform.createToken(options, (err: Error, token: any) => {
        if (err) {
          console.log(err);
          componentObjRef.current.otherErrMsg = "";
          componentObjRef.current.cardMessage = "";
          componentObjRef.current.cvvMessage = "";
          let foundCardMessage = true;
          // handle error
          // @ts-ignore
          switch (err.reason) {
            case "CREATE_TOKEN_NO_FIELDS_LOADED":
              componentObjRef.current.otherErrMsg = "Token creation error, no fields have been loaded." + err.message;
              break;
            case "CREATE_TOKEN_TIMEOUT":
              componentObjRef.current.otherErrMsg = "createToken call was unable to proceed." + err.message;
              break;
            case "CREATE_TOKEN_XHR_ERROR":
              componentObjRef.current.otherErrMsg = "Network error when attempting to create a token." + err.message;
              break;
            case "CREATE_TOKEN_NO_FIELDS":
              componentObjRef.current.otherErrMsg = "Data fields are unavailable for collection." + err.message;
              break;
            case "CREATE_TOKEN_VALIDATION_PARAMS":
              componentObjRef.current.otherErrMsg = "Issue with parameters supplied to createToken." + err.message;
              break;
            case "CREATE_TOKEN_VALIDATION_FIELDS":
              // @ts-ignore
              if (err.details && err.details.length > 0) {
                // @ts-ignore
                if (err.details.length > 1) {
                  componentObjRef.current.cardMessage = INVALIDCARDMESSAGE;
                  componentObjRef.current.cvvMessage = INVALIDCVVMESSAGE;
                  // @ts-ignore
                } else if (err.details[0].location === "number") {
                  componentObjRef.current.cardMessage = INVALIDCARDMESSAGE;
                  // @ts-ignore
                } else if (err.details[0].location === "securityCode") {
                  componentObjRef.current.cvvMessage = INVALIDCVVMESSAGE;
                  foundCardMessage = false;
                } else {
                  componentObjRef.current.otherErrMsg = err.message;
                }
              } else {
                componentObjRef.current.otherErrMsg = err.message;
              }
              break;
            case "CREATE_TOKEN_VALIDATION_SERVERSIDE":
              componentObjRef.current.otherErrMsg = "Server-side validation rejects the createToken request." + err.message;
              break;
            case "CREATE_TOKEN_UNABLE_TO_START":
              componentObjRef.current.otherErrMsg = "Loaded field was able to handle the createToken request." + err.message;
              break;
            default:
              componentObjRef.current.otherErrMsg = "Unknown error:" + err.message;
              break;
          }
          if (!foundCardMessage) componentObjRef.current.cardMessage = "";
          checkCreditCard();
        } else {
          componentObjRef.current.isBackgroundRed = true;
          (document.getElementById("validationMessages") as HTMLInputElement).value = PROCESSING;
          validationMessagesRef.current = PROCESSING;
          microformTokenRef.current = token;
          const { jti, lastFourDigits } = parseToken(token);
          dispatch(tokenCreatedAction({ token, jti, lastFourDigits }));
        }
      });
    }
  }, [paymentInfo.microform, dispatch, checkCreditCard]);

  // useEffect when input parameters are decrypted.
  useEffect(() => {
    if (decryptParams && Object.keys(decryptParams).length > 0 && submitForm) {
      setValidationMessages("");
      validationMessagesRef.current = "";
      componentObjRef.current.isBackgroundRed = false;
    }
  }, [decryptParams, submitForm]);

  // useEffect when new token is generated submit form
  useEffect(() => {
    if (!componentObjRef.current.processingForm && paymentInfo.token) {
      if (checkCreditCard()) {
        componentObjRef.current.isBackgroundRed = true;
        setValidationMessages(PROCESSING);
        (document.getElementById("validationMessages") as HTMLInputElement).value = PROCESSING;
        validationMessagesRef.current = PROCESSING;
        const messageObj = {
          eventSource: "cpgmui",
          eventType: "Form Validation",
          message: PROCESSING,
        };
        window.parent.postMessage(JSON.stringify(messageObj), "*");
        invokeProfileAdd(paymentInfo.token);
      }
    }
  }, [paymentInfo.token, checkCreditCard, invokeProfileAdd]);

  const windowMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (!submitForm && event.data !== undefined && event.data === "processTransaction") {
        let errorMessage = FORMINITIZING;
        setDatePickerOpen(false);
        setValidationMessages(errorMessage);
        validationMessagesRef.current = errorMessage;
        componentObjRef.current.isBackgroundRed = true;
        const messageObj = {
          eventSource: "cpgmui",
          eventType: "Form Initialization Error",
          message: errorMessage,
        };
        window.parent.postMessage(JSON.stringify(messageObj), "*");
      } else if (submitForm && event.data !== undefined && event.data === "processTransaction") {
        setDatePickerOpen(false);
        // check the origin of the data!
        if (
          event.origin.startsWith("https://") &&
          (event.origin.indexOf(".usa.canon.com") > 0 ||
            event.origin.indexOf(".cusa.canon.com") > 0 ||
            event.origin.indexOf(".americas.canon.com") > 0 ||
            event.origin.indexOf("https://canonamericas") >= 0)
        ) {
          componentObjRef.current.submitPage = true;
          if (checkCreditCard()) {
            createCybersourceToken();
          }
        } else if (event.origin.startsWith("http://") && event.origin.indexOf("localhost") > 0) {
          componentObjRef.current.submitPage = true;
          if (checkCreditCard()) {
            createCybersourceToken();
          }
        } else {
          return;
        }
      } else if (
        event.data &&
        (event.data as any).data &&
        ((event.data as any).data as any).focusShift &&
        (event.data as any).event === "blur" &&
        event.currentTarget &&
        (event.currentTarget as any).name &&
        (event.currentTarget as any).name === "creditCardFormIFrame"
      ) {
        if (((event.data as any).data as any).focusShift === "next") {
          if (componentObjRef.current.microFromFocusField === "creditCardNumber") {
            componentObjRef.current.microFromFocusField = "";
            if (datePickerRef && datePickerRef.current && datePickerRef.current?.input) {
              datePickerRef.current?.input.focus();
            }
          } else if (componentObjRef.current.microFromFocusField === "securityCode") {
            componentObjRef.current.microFromFocusField = "";
            customerNameRef.current?.focus();
          }
        } else if (((event.data as any).data as any).focusShift === "previous") {
          if (componentObjRef.current.microFromFocusField === "creditCardNumber") {
            componentObjRef.current.microFromFocusField = "";
            customerNameRef.current?.focus();
          } else if (componentObjRef.current.microFromFocusField === "securityCode") {
            componentObjRef.current.microFromFocusField = "";
            if (datePickerRef && datePickerRef.current && datePickerRef.current?.input) {
              datePickerRef.current?.input.focus();
            }
          }
        }
      } else if (componentObjRef.current && event.data && (event.data as string).indexOf("/*cybs-telgram*/") >= 0) {
        const jsonObject = JSON.parse(event.data.replace("/*cybs-telgram*/", ""));
        if (
          jsonObject.data &&
          jsonObject.data.focusShift &&
          jsonObject.event &&
          jsonObject.event === "event_blur" &&
          event.currentTarget &&
          (event.currentTarget as any).name &&
          ((event.currentTarget as any).name === "Mainframe" || (event.currentTarget as any).name === "creditCardFormIFrame")
        ) {
          if (jsonObject.data.focusShift === "next") {
            if (componentObjRef.current.microFromFocusField === "creditCardNumber") {
              componentObjRef.current.microFromFocusField = "";
              if (datePickerRef && datePickerRef.current && datePickerRef.current?.input) {
                datePickerRef.current?.input.focus();
              }
            } else if (componentObjRef.current.microFromFocusField === "securityCode") {
              componentObjRef.current.microFromFocusField = "";
              customerNameRef.current?.focus();
            }
          } else if (jsonObject.data.focusShift === "previous") {
            if (componentObjRef.current.microFromFocusField === "creditCardNumber") {
              componentObjRef.current.microFromFocusField = "";
              customerNameRef.current?.focus();
            } else if (componentObjRef.current.microFromFocusField === "securityCode") {
              componentObjRef.current.microFromFocusField = "";
              if (datePickerRef && datePickerRef.current && datePickerRef.current?.input) {
                datePickerRef.current?.input.focus();
              }
            }
          }
        }
      }
    },
    [createCybersourceToken, checkCreditCard, submitForm]
  );

  // useEffect for windows message
  useEffect(() => {
    if (paymentInfo.isSuccessful) {
      window.addEventListener("message", windowMessageHandler, false);
    }

    return () => {
      window.removeEventListener("message", windowMessageHandler);
    };
  }, [windowMessageHandler, paymentInfo.isSuccessful]);

  // decrypt the input parameters
  const decryptInputParameterAsync = useCallback(
    async (app: string, encryptedRequestKey: string, encryptedRequest: string) => {
      let decryptParamsResponse: any;

      try {
        const response = await PaymentService.getDecryptRequest(app, encryptedRequestKey, encryptedRequest);
        decryptParamsResponse = response.data;
        toggleFields("true");
      } catch (err: any) {
        console.log(err);
        if (axios.isAxiosError(err)) {
          // Access to config, request, and response
          let errorMessage = INVALIDAXIOSMESSAGE + "call to getDecryptRequest failed. " + err.message + ". Please check console..";
          setValidationMessages(errorMessage);
          validationMessagesRef.current = errorMessage;
          const messageObj = {
            eventSource: "cpgmui",
            eventType: "Form Initialization Error",
            message: errorMessage,
          };
          window.parent.postMessage(JSON.stringify(messageObj), "*");
        } else {
          // Just a stock error
          let errorMessage = "Error in getDecryptRequest. " + err.message + ". Please check console..";
          setValidationMessages(errorMessage);
          validationMessagesRef.current = errorMessage;
          const messageObj = {
            eventSource: "cpgmui",
            eventType: "Form Initialization Error",
            message: errorMessage,
          };
          window.parent.postMessage(JSON.stringify(messageObj), "*");
        }
        LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "PaymentService.getDecryptRequest call failed. " + err.message);
      }

      if (decryptParamsResponse && Object.keys(decryptParamsResponse).length > 0) {
        // check for proxy
        let iFrameUrl = document.referrer;
        if (iFrameUrl === undefined || iFrameUrl.trim().length === 0) {
          setProxyUrl(decryptParamsResponse["tcepps.proxyUrl"]);
        }

        setDecryptParams(decryptParamsResponse);
        let inputExpirationDate = decryptParamsResponse["expirationDate"];
        if (inputExpirationDate && inputExpirationDate.length === 7) {
          setExpirationMonthYearDate(convertMMYYYYToDate(inputExpirationDate.substring(0, 2) + "/" + inputExpirationDate.substring(3)));
        }
        let cardType = decryptParamsResponse["cardType"];
        switch (cardType) {
          case "VI":
            setCreditCardType(cardType);
            setSelectedCreditCard(cardType);
            break;
          case "MC":
            setCreditCardType(cardType);
            setSelectedCreditCard(cardType);
            break;
          case "AX":
            setCreditCardType(cardType);
            setSelectedCreditCard(cardType);
            break;
          case "DS":
            setCreditCardType("DI");
            setSelectedCreditCard("DI");
            break;
          case "DI":
            setCreditCardType(cardType);
            setSelectedCreditCard(cardType);
            break;
        }

        // check if its called from testing app
        if (isTestingApp(decryptParamsResponse["app.TESTINGAPP"])) {
          developmentEnv = true;
          // setExpirationMonthYearDate(convertMMYYYYToDate(TceppsTestData.ToBeAppTestData.CUSAC360B2BCAREPAK["ppa.test.expirationMonthYear"]));
        }
        logErrorRef.current = "CUSAC360B2BCAREPAK Order# " + decryptParamsResponse["orderNumber"];
      } else {
        let errorMessage = "Customer details not available. Please close and retry.";
        setValidationMessages(errorMessage);
        validationMessagesRef.current = errorMessage;
        const messageObj = {
          eventSource: "cpgmui",
          eventType: "Form Initialization",
          message: errorMessage,
        };
        window.parent.postMessage(JSON.stringify(messageObj), "*");
        LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "Customer details not available. Please close and retry.");
      }
    },
    [toggleFields]
  );

  // useEffect if screen/page input parameters change. Dispatch state change
  useEffect(() => {
    const appKey0 = Buffer.from(appKey ? appKey : "", "base64");
    const appKey1 = appKey0.toString("utf8");
    const mydata = JSON.parse(appKey1);
    toggleFields("false");

    let iFrameUrl = document.referrer;
    if (iFrameUrl && iFrameUrl.trim().length > 0) {
      setProxyUrl(iFrameUrl);
    }

    let requestParameter = {
      app: "",
      token: "",
      sign: "",
      serviceOperation: "",
      encryptedRequest: "",
      encryptedRequestKey: "",
      enableNewCrCardFlag: "",
      addProfile: "",
      cpgmuiApiUrl: "",
      cpgmuiEnv: "",
    };

    const getPortNumber = () => {
      const windowsUrl = window.location.href;
      const searchParams = windowsUrl.indexOf("//") + 2;
      const firstSlashIndex = windowsUrl.indexOf("/", searchParams);
      const colonIndex = windowsUrl.indexOf(":", searchParams);
      if (firstSlashIndex > 0 && colonIndex > 0) {
        return windowsUrl.substring(colonIndex + 1, firstSlashIndex);
      } else {
        return "";
      }
    };

    //find port if it exits
    const urlPortNumber = getPortNumber();

    Object.keys(mydata).forEach((key) => {
      switch (key) {
        case "tcepps.app":
          requestParameter.app = mydata[key];
          break;
        case "tcepps.token":
          requestParameter.token = mydata[key];
          break;
        case "tcepps.sign":
          requestParameter.sign = mydata[key];
          break;
        case "tcepps.serviceOperation":
          requestParameter.serviceOperation = mydata[key];
          break;
        case "tcepps.encryptedRequest":
          requestParameter.encryptedRequest = mydata[key];
          break;
        case "tcepps.encryptedRequestKey":
          requestParameter.encryptedRequestKey = mydata[key];
          break;
        case "tcepps.enableNewCrCardFlag":
          requestParameter.enableNewCrCardFlag = mydata[key];
          break;
        case "tcepps.addProfile":
          requestParameter.addProfile = mydata[key];
          break;
        case "cpgmuiApiUrl":
          requestParameter.cpgmuiApiUrl = mydata[key] + "/TCEPPS/jsp/tceppsHtmlFormInterface.jsp?tceppsApp=";
          if (urlPortNumber !== "") {
            let backendPortNumber = getPortNumber();
            if (backendPortNumber !== urlPortNumber) {
              requestParameter.cpgmuiApiUrl = requestParameter.cpgmuiApiUrl.replace(backendPortNumber, urlPortNumber);
            }
          }
          break;
        case "cpgmuiEnv":
          requestParameter.cpgmuiEnv = mydata[key];
          if (requestParameter.cpgmuiEnv.toLowerCase() === "development" || requestParameter.cpgmuiEnv.toLowerCase() === "qa") {
            developmentEnv = true;
          }
          break;
      }
    });
    requestParameter.cpgmuiApiUrl += requestParameter.app;

    dispatch(
      tceppsActions.initializeTceppsAction({
        app: requestParameter.app,
        token: requestParameter.token,
        sign: requestParameter.sign,
        serviceOperation: requestParameter.serviceOperation,
        encryptedRequest: requestParameter.encryptedRequest,
        encryptedRequestKey: requestParameter.encryptedRequestKey,
        enableNewCrCardFlag: requestParameter.enableNewCrCardFlag,
        cpgmuiApiUrl: requestParameter.cpgmuiApiUrl,
        cpgmuiEnv: requestParameter.cpgmuiEnv,
      })
    );

    try {
      getYearsFromToday(11, document.getElementById("expirationYear") as HTMLSelectElement, false);
    } catch (error) {}

    // setExpirationMonthYearDate(new Date());
    setCreditCardType("");
    setSelectedCreditCard("");

    setValidationMessages("");
    validationMessagesRef.current = "";
    componentObjRef.current.isBackgroundRed = false;

    decryptInputParameterAsync(
      requestParameter.app.toUpperCase().trim(),
      requestParameter.encryptedRequestKey,
      requestParameter.encryptedRequest
    );
  }, [appKey, dispatch, decryptInputParameterAsync, toggleFields]);

  const updateValidationMessages = (action: string, pdateString: string) => {
    if (componentObjRef.current.submitPage) {
      let errMsg = validationMessagesRef.current;
      if (errMsg === undefined) {
        errMsg = "";
      }
      if (action === ADDMESSAGE) {
        if (errMsg!.indexOf(pdateString) < 0) {
          let updatedMsg = "";
          errMsg = errMsg?.length === 0 ? pdateString : errMsg + " " + pdateString;
          if (errMsg.indexOf(INVALIDCARDMESSAGE) >= 0) {
            updatedMsg = INVALIDCARDMESSAGE;
          }
          if (errMsg.indexOf(INVALIDCVVMESSAGE) >= 0) {
            updatedMsg += (updatedMsg?.length > 0 ? " " : "") + INVALIDCVVMESSAGE;
          }
          if (errMsg.indexOf(INVALIDDATEMESSAGE) >= 0) {
            updatedMsg += (updatedMsg?.length > 0 ? " " : "") + INVALIDDATEMESSAGE;
          }
          setValidationMessages(updatedMsg);
          validationMessagesRef.current = updatedMsg;
          componentObjRef.current.isBackgroundRed = true;
        }
      } else {
        if (errMsg!.indexOf(pdateString) >= 0) {
          errMsg = errMsg?.replace(" " + pdateString, "");
          errMsg = errMsg?.replace(pdateString, "");
          setValidationMessages(errMsg);
          validationMessagesRef.current = errMsg;
          if (errMsg.trim().length === 0) {
            componentObjRef.current.isBackgroundRed = false;
          }
        }
      }
    }
  };

  // useEffect when form get loaded. setup microform
  useEffect(() => {
    function selectGivenCardType(cardName: string, cardValid: boolean, cardEmpty: boolean) {
      let varCreditCardType = "";
      let detectedCard = false;
      if (cardName.length > 0) {
        switch (cardName) {
          case "VI":
          case "visa":
            varCreditCardType = "visa";
            detectedCard = true;
            break;
          case "MC":
          case "mastercard":
            varCreditCardType = "mastercard";
            detectedCard = true;
            break;
          case "AX":
          case "amex":
            varCreditCardType = "amex";
            detectedCard = true;
            break;
          case "DI":
          case "discover":
            varCreditCardType = "discover";
            detectedCard = true;
            break;
        }
      }

      if (detectedCard) {
        setCreditCardType(varCreditCardType);
        setSelectedCreditCard(varCreditCardType);
      } else {
        setCreditCardType("");
        setSelectedCreditCard("");
      }

      let errMessage = "Credit Card# needs to be entered.";
      if (!detectedCard) {
        errMessage = "Please enter a visa or a mastercard or amex or discover card#.";
      } else if (cardEmpty) {
        errMessage = "Credit Card# needs to be entered.";
      } else if (!cardValid) {
        errMessage = "Please enter a valid Credit card#.";
      } else if (cardValid) {
        errMessage = "";
      }
      setCreditcardValid(cardValid && detectedCard);
      componentObjRef.current.cardMessage = errMessage;
      updateValidationMessages(componentObjRef.current.cardMessage.length > 0 ? ADDMESSAGE : REMOVEMESSAGE, INVALIDCARDMESSAGE);
    }

    const loadCybersourceJS = (captureContext: any, clientLibrary: string, clientLibraryIntegrity: string, callback: (cc: any) => any) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      // script.src = config.cybersource.microformJs;
      script.src = clientLibrary;
      script.integrity = clientLibraryIntegrity;
      script.crossOrigin = "anonymous";
      document.body.appendChild(script);
      script.onload = () => {
        callback(captureContext);
      };
    };

    const initializeMicroform = async (captureContext: any) => {
      captureContextRef.current = captureContext;
      // @ts-ignore
      const flex = await new window.Flex(captureContext);
      const microform = await flex.microform({ styles: flexStyles });
      creditCardMicroNumber = await microform.createField("number", { placeholder: "Enter Name on Card" });
      cvvMicroSecurityCode = await microform.createField("securityCode", { placeholder: "Enter CVV" });
      await creditCardMicroNumber.load("#creditCardNumber");
      await cvvMicroSecurityCode.load("#securityCode");
      dispatch(microformInitializedAction(microform));

      if (creditCardMicroNumber !== undefined && creditCardMicroNumber !== null) {
        //add listener
        creditCardMicroNumber.on("change", function (data: any) {
          if (data.card && data.card.length > 0) {
            selectGivenCardType(data.card[0].name, data.valid, data.empty);
          } else {
            selectGivenCardType("", data.valid, data.empty);
          }
        });
        creditCardMicroNumber.on("load", function (data: any) {
          // creditCardMicroNumber.focus();
          customerNameRef.current?.focus();
          setSubmitForm(true);
        });
        creditCardMicroNumber.on("focus", function (data: any) {
          componentObjRef.current.microFromFocusField = "creditCardNumber";
          setDatePickerOpen(false);
          setCreditCardNumberBorderClass(`${classesC360b2bcarepak.Search__SearchInput_focus}`);
        });
        creditCardMicroNumber.on("blur", function (data: any) {
          setCreditCardNumberBorderClass("");
        });
      }
      if (cvvMicroSecurityCode !== undefined && cvvMicroSecurityCode !== null) {
        cvvMicroSecurityCode.on("focus", function (data: any) {
          componentObjRef.current.microFromFocusField = "securityCode";
          setDatePickerOpen(false);
          setSecurityCodeBorderClass(`${classesC360b2bcarepak.Search__SearchInput_focus}`);
        });
        cvvMicroSecurityCode.on("blur", function (data: any) {
          setSecurityCodeBorderClass("");
        });
        cvvMicroSecurityCode.on("change", function (data: any) {
          if (data.empty || !data.valid) {
            updateValidationMessages(ADDMESSAGE, INVALIDCVVMESSAGE);
            setCvvdValid(false);
          } else if (data.valid) {
            updateValidationMessages(REMOVEMESSAGE, INVALIDCVVMESSAGE);
            setCvvdValid(true);
          }
        });
      }
    };

    const getCaptureContext = async () => {
      await PaymentService.getCaptureContext(extractDomain(proxyUrl))
        .then((resp) => {
          const captureContext = resp.data.captureContext;
          const { clientLibrary, clientLibraryIntegrity } = extractClientLibrary(resp.data.json);
          dispatch(contextLoadedAction(captureContext));
          // @ts-ignore
          if (!window.Flex) {
            loadCybersourceJS(captureContext, clientLibrary, clientLibraryIntegrity, initializeMicroform);
          } else {
            initializeMicroform(captureContext);
          }
          return () => dispatch(unmountAction);
        })
        .catch((err: Error | AxiosError) => {
          console.log(err);
          if (axios.isAxiosError(err)) {
            let errMsg = INVALIDAXIOSMESSAGE + "call to initialize microForm failed. " + err.message + ". Please check console.";
            setValidationMessages(errMsg);
            validationMessagesRef.current = errMsg;
            const messageObj = {
              eventSource: "cpgmui",
              eventType: "Form Initialization",
              message: errMsg,
            };
            window.parent.postMessage(JSON.stringify(messageObj), "*");
          } else {
            let errMsg = "Initialize microForm failed. " + err.message + ". Please check console.";
            setValidationMessages(errMsg);
            validationMessagesRef.current = errMsg;
            const messageObj = {
              eventSource: "cpgmui",
              eventType: "Form Initialization",
              message: errMsg,
            };
            window.parent.postMessage(JSON.stringify(messageObj), "*");
          }
          componentObjRef.current.isBackgroundRed = true;
          LogService.logMessage(logErrorRef.current, LOGERRORLEVEL, "Initialize microForm failed. " + err.message);
        });
    };
    if (windowSize.width > 400) {
      setLargeScreen(true);
    } else {
      setLargeScreen(false);
    }
    if (proxyUrl && proxyUrl.length > 0) {
      getCaptureContext();
    }
  }, [dispatch, proxyUrl, windowSize.width]);

  const onChangeHandler = (identifier: string, event: ChangeEvent & { target: HTMLInputElement }) => {
    let fieldChangeMessage = EMPTYCUSTOMERNAMEMESSAGE;
    let messageAction = ADDMESSAGE;
    switch (identifier) {
      case "customerName":
        setCustomerName(event.target.value.toUpperCase());
        if (event.target.value.length !== 0) {
          messageAction = REMOVEMESSAGE;
        }
        break;
    }
    updateValidationMessages(messageAction, fieldChangeMessage);
  };

  return (
    <>
      <form id="tcepps" name="tcepps" action={tceppsState.cpgmuiApiUrl} target="_self">
        <input id="app" name="app" type="hidden" value={tceppsState.app} />
        <input id="token" name="token" type="hidden" value={tceppsState.token} />
        <input id="sign" name="sign" type="hidden" value={tceppsState.sign} />
        <input id="serviceOperation" name="serviceOperation" type="hidden" value="" />
        <input id="encryptedRequest" name="encryptedRequest" type="hidden" value={tceppsState.encryptedRequest} />
        <input id="encryptedRequestKey" name="encryptedRequestKey" type="hidden" value={tceppsState.encryptedRequestKey} />
        <input id="lastFourCcAccountNum" name="lastFourCcAccountNum" type="hidden" value="" />
        <input id="proxyUrl" name="proxyUrl" type="hidden" value="" />
      </form>
      <form id="pno" name="pno">
        <input id="ccAccountNum" name="ccAccountNum" type="hidden" value="" />
        <input id="ccExp" name="ccExp" type="hidden" value="" />
        <input id="ccCardVerifyNum" name="ccCardVerifyNum" type="hidden" value="" />
        <input id="avsName" name="avsName" type="hidden" value="" />
        <input id="avsAddress1" name="avsAddress1" type="hidden" value="" />
        <input id="avsAddress2" name="avsAddress2" type="hidden" value="" />
        <input id="avsCity" name="avsCity" type="hidden" value="" />
        <input id="avsState" name="avsState" type="hidden" value="" />
        <input id="avsZip" name="avsZip" type="hidden" value="" />
        <input id="avsCountryCode" name="avsCountryCode" type="hidden" value="" />
        <input id="amount" name="amount" type="hidden" value="" />
        <input id="orderID" name="orderID" type="hidden" value="" />
        <input id="cardBrand" name="cardBrand" type="hidden" value="" />
        <input id="customerEmail" name="customerEmail" type="hidden" value="" />
        <input id="customerPhone" name="customerPhone" type="hidden" value="" />
      </form>
      <div className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.top_level}`}>
        <div className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.custom_overlay}`}>
          <div className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Popup__card}`}>
            <div className="">
              <form id="creditCard" className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Form__Form}`}>
                <div id="info-field" className="">
                  <div
                    className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Row__Row} ${classesC360b2bcarepak.Row__padding_sm_label}`}
                  >
                    <div
                      className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Col__Col} ${classesC360b2bcarepak.Col__sm_12} ${classesC360b2bcarepak.Col__md_8} ${classesC360b2bcarepak.Col__padding_sm}`}
                    >
                      <label
                        className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Label__field}`}
                        htmlFor="customerName"
                      >
                        * Name on card
                      </label>
                    </div>
                    {isLargeScreen && (
                      <div
                        className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Col__Col} ${classesC360b2bcarepak.Col__sm_12} ${classesC360b2bcarepak.Col__md_4} ${classesC360b2bcarepak.Col__padding_sm}`}
                      ></div>
                    )}
                  </div>

                  <div
                    className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Row__Row__Left} ${classesC360b2bcarepak.Row__padding_sm_label}`}
                  >
                    <div
                      className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Col__Col} ${classesC360b2bcarepak.Col__sm_12} ${classesC360b2bcarepak.Col__md_8} ${classesC360b2bcarepak.Col__padding_sm}`}
                    >
                      <div className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Label__group}`}>
                        <input
                          id="customerName"
                          name="customerName"
                          type="text"
                          placeholder="Enter Name on Card"
                          size={25}
                          maxLength={customerNameMaxLength}
                          tabIndex={1}
                          value={customerName}
                          disabled={customerNameDisabled}
                          ref={customerNameRef}
                          className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Input__Input} ${classesC360b2bcarepak.Sample__container__content} ${classesC360b2bcarepak.react_calendar}`}
                          onChange={(event) => onChangeHandler("customerName", event)}
                          onBlur={(event) => onBlurHandler("customerName", event)}
                          onKeyDown={(event) => onKeyDownHandler("customerName", event)}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={margin20Style}>
                    <div className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Label__group}`}></div>
                  </div>

                  <div id="cnumdiv">
                    <div
                      className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Row__Row} ${classesC360b2bcarepak.Row__padding_sm_label}`}
                    >
                      <div
                        className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Col__Col} ${classesC360b2bcarepak.Col__sm_12} ${classesC360b2bcarepak.Col__md_6v} ${classesC360b2bcarepak.Col__padding_sm}`}
                      >
                        <label
                          className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Label__field}`}
                          htmlFor="creditCardNumber"
                        >
                          * Card Number
                        </label>
                      </div>
                      <div
                        className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Col__Col} ${classesC360b2bcarepak.Col__sm_12} ${classesC360b2bcarepak.Col__md_6v}`}
                      ></div>
                    </div>

                    <div
                      className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Row__Row} ${classesC360b2bcarepak.Row__padding_sm}`}
                    >
                      <div
                        className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Col__Col} ${classesC360b2bcarepak.Col__sm_12} ${classesC360b2bcarepak.Col__md_6v} ${classesC360b2bcarepak.Col__padding_sm}`}
                      >
                        <div>
                          <div className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Label__group}`}>
                            <div
                              className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Input__Input} ${classesC360b2bcarepak.Search__SearchInput} ${creditCardNumberBorderClass}`}
                            >
                              <div
                                id="creditCardNumber"
                                style={creditCardStyle}
                                tabIndex={2}
                                ref={creditCardNumberRef}
                                aria-disabled={creditCardNumberDisabled}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Col__Col} ${classesC360b2bcarepak.Col__sm_12} ${classesC360b2bcarepak.Col__md_6v}`}
                      >
                        <div
                          className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Row__Row} ${classesC360b2bcarepak.Row__padding_smb} ${classesC360b2bcarepak.Row__Row_Left}`}
                        >
                          <div className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.CardButton__Group_sm}`}>
                            <div className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.CardButton__inner_sm}`}>
                              <div
                                className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Link__Link} ${classesC360b2bcarepak.CardButton__CardButton_sm}`}
                              >
                                <input
                                  type="radio"
                                  value="visa"
                                  name="cardtype"
                                  id="visa"
                                  tabIndex={-1}
                                  disabled={true}
                                  checked={selectedCreditCard === "visa" || selectedCreditCard === "VI"}
                                  onClick={() => {
                                    return false;
                                  }}
                                />
                                <label htmlFor="visa"></label>
                                <img src={require("./img/vi.png")} alt="Visa" />
                              </div>
                              <div
                                className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Link__Link} ${classesC360b2bcarepak.CardButton__CardButton_sm}`}
                              >
                                <input
                                  type="radio"
                                  value="mastercard"
                                  name="cardtype"
                                  tabIndex={-1}
                                  disabled={true}
                                  checked={selectedCreditCard === "mastercard" || selectedCreditCard === "MC"}
                                  id="mastercard"
                                  onClick={() => {
                                    return false;
                                  }}
                                />
                                <label htmlFor="mastercard"></label>
                                <img src={require("./img/mc.png")} alt="Mastercard" />
                              </div>
                              <div
                                className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Link__Link} ${classesC360b2bcarepak.CardButton__CardButton_sm}`}
                              >
                                <input
                                  type="radio"
                                  value="amex"
                                  name="cardtype"
                                  id="amex"
                                  tabIndex={-1}
                                  disabled={true}
                                  checked={selectedCreditCard === "amex" || selectedCreditCard === "AX"}
                                  onClick={() => {
                                    return false;
                                  }}
                                />
                                <label htmlFor="amex"></label>
                                <img src={require("./img/ae.png")} alt="American Express" />
                              </div>
                              <div
                                className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Link__Link} ${classesC360b2bcarepak.CardButton__CardButton_sm}`}
                              >
                                <input
                                  type="radio"
                                  value="discover"
                                  name="cardtype"
                                  tabIndex={-1}
                                  disabled={true}
                                  id="discover"
                                  checked={selectedCreditCard === "discover" || selectedCreditCard === "DI"}
                                  onClick={() => {
                                    return false;
                                  }}
                                />
                                <label htmlFor="discover"></label>
                                <img src={require("./img/di.png")} alt="Discover" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Row__Row} ${classesC360b2bcarepak.Row__padding_sm_label}`}
                    >
                      <div
                        className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Col__Col} ${classesC360b2bcarepak.Col__sm_12} ${classesC360b2bcarepak.Col__md_6v} ${classesC360b2bcarepak.Col__padding_sm}`}
                      >
                        <label
                          className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Label__field}`}
                          htmlFor="expirationMonthYear"
                        >
                          * Expiration Date
                        </label>
                      </div>
                      {isLargeScreen && (
                        <div
                          className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Col__Col} ${classesC360b2bcarepak.Col__sm_12} ${classesC360b2bcarepak.Col__md_6v} ${classesC360b2bcarepak.Col__padding_sm_10Left}`}
                        >
                          <label
                            className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Label__field}`}
                            htmlFor="securityCode"
                          >
                            * CVV
                          </label>
                        </div>
                      )}
                    </div>

                    <div
                      className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Row__Row} ${classesC360b2bcarepak.Row__padding_sm}`}
                    >
                      <div
                        className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Col__Col} ${classesC360b2bcarepak.Col__sm_12} ${classesC360b2bcarepak.Col__md_6v} ${classesC360b2bcarepak.Col__padding_sm}`}
                      >
                        <div className="" id="b">
                          <div className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Label__group}`}>
                            <DatePicker
                              key="expirationMonthYear"
                              id="expirationMonthYear"
                              name="expirationMonthYear"
                              tabIndex={3}
                              placeholderText="MM/YYYY"
                              showIcon
                              icon={
                                <span role="img" aria-label="calendar" onClick={() => setDatePickerOpen(!isDatePickerOpen)}>
                                  📅
                                </span>
                              }
                              disabled={expirationMonthYearDisabled}
                              dateFormat="MM/yyyy"
                              ref={datePickerRef}
                              // open={!isDatePickerOpen}
                              // onClickOutside={() => setIsDatePickerOpen(false)}
                              // customInput={
                              //   <button type="button" onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}>
                              //     {/* Icon component or image here */}
                              //     <span role="img" aria-label="calendar">
                              //       📅
                              //     </span>
                              //   </button>
                              // }
                              toggleCalendarOnIconClick
                              // dropdownMode="select"
                              selected={expirationMonthYearDate}
                              open={isDatePickerOpen}
                              onFocus={() => setDatePickerOpen(true)} // Show popover on focus
                              onSelect={handleDateSelect} //when day is clicked
                              onChange={handleDateChange} //only when value has changed
                              onChangeRaw={(e) => handleChangeRaw(e)}
                              onKeyDown={(event) => onKeyDownHandler("expirationMonthYear", event)}
                              onBlur={(event) => onBlurHandler("expirationMonthYear", event)}
                              // onClickOutside={() => setOpen(false)}
                              showMonthYearPicker
                              // renderMonthContent={renderMonthContent}
                              shouldCloseOnSelect
                              minDate={new Date()}
                              maxDate={addYears(15)}
                              popperPlacement="bottom-end"
                              popperProps={{ strategy: "fixed" }}
                              // popperModifiers={{
                              //   flip: { behavior: ["bottom"] },
                              //   preventOverflow: { enabled: false },
                              //   hide: { enabled: false },
                              // }}
                              // popperClassName={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.react_datepicker_popper}`}
                              // fixMainPosition={"center"}
                              className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Input__Input} ${classesC360b2bcarepak.Sample__container__content} ${classesC360b2bcarepak.react_calendar} ${datePickerBorderClass}`}
                              // value={expirationMonthYear}
                            />
                          </div>
                        </div>
                      </div>

                      {!isLargeScreen && (
                        <div
                          className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Col__Col} ${classesC360b2bcarepak.Col__sm_12} ${classesC360b2bcarepak.Col__md_6v} ${classesC360b2bcarepak.Col__padding_sm} ${classesC360b2bcarepak.Col__marginTop}`}
                        >
                          <label
                            className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Label__field}`}
                            htmlFor="securityCode"
                          >
                            * CVV
                          </label>
                        </div>
                      )}

                      <div
                        className={
                          isLargeScreen
                            ? `${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Col__Col} ${classesC360b2bcarepak.Col__sm_12} ${classesC360b2bcarepak.Col__md_6v}  ${classesC360b2bcarepak.Col__padding_sm} ${classesC360b2bcarepak.Col__padding_sm_10Left}`
                            : `${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Col__Col} ${classesC360b2bcarepak.Col__sm_12} ${classesC360b2bcarepak.Col__md_6v}  ${classesC360b2bcarepak.Col__padding_sm}`
                        }
                      >
                        <div className="" id="a">
                          <div className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Label__group}`}>
                            <div className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Search__SearchContainer}`}>
                              <div
                                className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.Input__Input} ${classesC360b2bcarepak.Search__SearchInput} ${securityCodeBorderClass}`}
                              >
                                <div
                                  id="securityCode"
                                  style={creditCardStyle}
                                  tabIndex={4}
                                  ref={securityCodeRef}
                                  aria-disabled={securityCodeDisabled}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "flex", textAlign: "left", verticalAlign: "middle" }}>
                    <h1
                      className={`${classesC360b2bcarepak.c360b2bcarepak} ${classesC360b2bcarepak.h1}`}
                      id="validationMessages"
                      tabIndex={5}
                      style={{
                        color: "#D8000C",
                        display: "inline-flex" /* keep the background color wrapped tight */,
                        backgroundColor: componentObjRef.current.isBackgroundRed ? "#FFBABA" : "white",
                      }}
                    >
                      {validationMessages}
                    </h1>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
