//import {BACKEND_URL} from '../environment/AppEnvironment';
import { config } from "../service/configService";

const apiHost = config && config.common ? config.common.apiUrl : window.location.origin;

export const ApiConstants = {
  // captureContext: apiHost + "/api/cybersource/context",
  captureContext: apiHost + "/api/cybersource/v2/context",
  permToken: apiHost + "/api/cybersource/permtoken",
  decryptRequest: apiHost + "/api/cpgmui/request/decrypt",
  encryptResponse: apiHost + "/api/cpgmui/response/encrypt",
  cusasm: apiHost + "/TCEPPS/jsf/cusasm",
  mycanoncpsap: apiHost + "/TCEPPS/jsf/mycanoncpsap",
  mycanoncpscc: apiHost + "/TCEPPS/jsf/mycanoncpscc",
  mycanon: apiHost + "/TCEPPS/jsf/mycanon",
  mycanonsnr: apiHost + "/TCEPPS/jsf/mycanonsnr",
  execServiceOperation: apiHost + "/api/cpgmui/exec",
  execServiceOperationRest: apiHost + "/TCEPPS/jsp/execServiceOperationRest.jsp",
  logMessage: apiHost + "/api/log/logmessage",
  jwtVerify: apiHost + "/api/cpgmui/jwt/verify",
  cusac360b2bcarepak: apiHost + "/TCEPPS/jsf/cusac360b2bcarepak",
  cusac360b2bsnr: apiHost + "/TCEPPS/jsf/cusac360b2bsnr",
};
